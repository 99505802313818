import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import SanparkListItem from "../../components/companys/SanparkListItem";
import { makeStyles } from "@material-ui/core";
import { fetchSanpark } from "../../redux/company/operation";
import { getSanpark } from "../../redux/company/selectors";
import NotSignInHeader from "../../components/header/NotSignInHeader";
import Footer from "../../components/footer/footer";
import { push } from "connected-react-router";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    paper: {
        position: "absolute",
        top: "50%",
        left: "50%",
        WebkitTransform: "translateY(-50%) translateX(-50%)",   
        padding: 20,
        border: "3px solid #FF9D00",
        borderRadius: 5,
        [theme.breakpoints.down('sm')]: {
            width: "90%"
        }
    },
    subject: {
        fontWeight: "bold",
        fontSize: 30,
        [theme.breakpoints.down('sm')]: {
            fontSize: 20
        }
    },
    caution: {
        lineHeight: 2,
        textAlign: "left",
        [theme.breakpoints.down('sm')]: {
            fontSize: 14
        }
    },
    button: {
        padding: "5px 50px",
        fontSize: 14,
        color: "#707070",
        backgroundColor: "#fff",
        border: "1px solid #707070",
        borderRadius: 5,
        width: 200,
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
            width: 180,
        },
        '&:hover': {
            background: "#EEEEEE",
         },
    },
})) 

const Sanpark = () => {
    const classes = useStyles();
    const selector = useSelector((state) => state)
    const dispatch = useDispatch();
    const getSanparks = getSanpark(selector)

    useEffect(() => {
        dispatch(fetchSanpark());
    }, [])

    return(
        <div>
            <NotSignInHeader />
            <div className="module-spacer--medium" />
            <div className="module-spacer--medium" />
            <div className="module-spacer--medium" />
            <div className={classes.root}>
                {/* {getSanparks.length > 0 && (
                    getSanparks.map((sanpark) => <SanparkListItem key={sanpark.companyid} sanpark={sanpark} /> )
                )} */}
                {getSanparks.length > 0 ? (
                    getSanparks.map((sanpark) => <SanparkListItem key={sanpark.companyid} sanpark={sanpark} /> )
                ) : (
                    <div className={classes.paper}>
                        <h2 className={classes.subject}>現在小野田サンパークでの求人はございません...</h2>
                        <div className='module-spacer--medium' />   
                        <p className={classes.caution}>求人が掲載されるまでお待ちください。</p>
                        <div className='module-spacer--medium' />   
                        <button className={classes.button} onClick={() => dispatch(push('/mappage'))}>マップページへ</button>
                    </div>
                )}
            </div>
            <div className="module-spacer--medium" />
            <div className="module-spacer--medium" />
            <div className="module-spacer--medium" />
            <Footer />
        </div>
    )
}

export default Sanpark;