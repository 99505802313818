import React, { useEffect } from "react";
import { List, ListItem, ListItemText, Divider, Button  } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { db, FirebaseTimeStamp } from "../../firebase";
import { getUserId } from "../../redux/users/selectors";

const useStyles = makeStyles((theme) => ({
    list: {
        background: '#fff',
        height: 'auto',
        position: "relative"
    },
    text: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            fontSize: 14
        }
    },
    flex: {
        display: "flex",
        justifyContent: "flex-start",
        [theme.breakpoints.down('sm')]: {
            flexFlow: "column"
        }
    },
    space: {
        height: 5
    },
    button: {
        position: "absolute",
        right: 80,
        bottom: 10,
        backgroundColor: "#FF9D00",
        padding: "5px 10px",
        color: "#fff",
        borderRadius: 5,
        cursor: "pointer",
        '&:hover': {
            opacity: 0.6
        },
    },
    detail: {
        position: "absolute",
        right: 10,
        bottom: 10,
        backgroundColor: "#FF9D00",
        padding: "5px 10px",
        color: "#fff",
        borderRadius: 5,
        cursor: "pointer",
        '&:hover': {
            opacity: 0.6
         },
    }
}))

const RecruitmentItem = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);
    const uid = getUserId(selector)
    const datetimeToString = (dt) => {
        return dt.getFullYear() + '/'
            + ('00' + (dt.getMonth()+1)).slice(-2) + '/'
            + ('00' + dt.getDate()).slice(-2) + ' '
    };
    const recruitment = props.recruitment;
    const daysMonth = 365 /12;
    const daysYear = 365;
    const addedData = recruitment.added_at.toDate()
    const currentData = Date.now() 
    const timestamp = currentData - addedData
    let elapsedDay  = Math.floor( timestamp / 86400000 );
    const elapsedYear =  Math.floor( elapsedDay / daysYear );
    elapsedDay -= elapsedYear * 365;
    const elapsedMonth = Math.floor( elapsedDay / daysMonth );
    elapsedDay -= Math.floor(elapsedMonth * daysMonth);
    const dateTime = datetimeToString(recruitment.added_at.toDate());
    const id = props.recruitmentsId

    return(
        <div>
            <List>
                <ListItem className={classes.list}>
                    <div className={classes.text}>
                        <ListItemText 
                            primary={"採用店舗 : " + recruitment.companyName}
                        />
                        <ListItemText 
                            primary={"採用通知日時 : " + dateTime}
                        />
                        <ul className={classes.flex}>
                            <li>採用期間 : </li>
                            <li>{elapsedYear !== 0 && elapsedYear + "年"}</li>
                            <li>{elapsedMonth !== 0 && elapsedMonth + "ヶ月"}</li>
                            <li>{elapsedDay !== 0 && elapsedDay + "日"}</li>
                        </ul>
                    </div>
                    <div className={classes.flex}>
                        <p className={classes.button} onClick={() => dispatch(push('/workcompany/' + id))}>詳細</p>
                        <p className={classes.detail} onClick={() => dispatch(push('/retirecompany/' + id))}>退職</p>
                    </div>
                </ListItem>                      
            </List>
        </div>
    )

}

export default RecruitmentItem;