import React, {useCallback, useEffect, useState,} from "react";
import { useSelector } from "react-redux";
import { db, FirebaseTimeStamp } from "../../firebase";
import { getUserId } from "../../redux/users/selectors";
import {init, send } from "emailjs-com";
import { makeStyles } from "@material-ui/styles";
import "../mailConfirm/style.css";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { addApplication } from "../../redux/users/operations";
import NotSignInHeader from "../../components/header/NotSignInHeader";
import FooterVar2 from "../../components/footer/footer_ver2";
import { hideLoadingAction, showLoadingAction } from "../../redux/loading/actions";
import Footer from "../../components/footer/footer";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh"
    },
    card: {
        margin: "0 auto",
        width: "100%",
        maxWidth: 600,
        padding: "50px 100px",
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        border: "solid 1px #333",
    },
    button: {
        padding: "5px 50px",
        backgroundColor: "#FDC04F",
        color: "#fff",
        borderRadius: 5,
        border: "none",
        fontSize: 20,
        marginRight: 10,
        marginLeft: 10,
        '&:hover': {
            opacity: 0.6
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 12
        }        
    },
    space_big:{
        height: 50,
    },
    space_small: {
        height: 30,
    },
    position: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    reset: {
        fontSize: 14,
        paddingLeft: 150,
        cursor: "pointer",
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
            paddingLeft: 20,
        }
    },
    text: {
        cursor: "pointer",
        width: "max-content",
    },
    field: {
        minWidth: 300,
    },
    name: {
        fontSize: 20
    },
    birthday: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
  }));

const MailConfirm = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);
    const path = selector.router.location.pathname
    const id = path.split('/mailconfirm/')[1]
    const [user, setUser] = useState(null),
          [company, setCompany] = useState(null);

    useEffect(() => {
        const uid = getUserId(selector);
        db.collection('users').doc(uid).get()
         .then(snapshot => {
             const data = snapshot.data();
             setUser(data);
         })
    },[]);
    useEffect(() => {
        db.collection('companys').doc(id).get()
         .then(snapshot => {
             const data = snapshot.data()
             setCompany(data)
         })
    }, []);

    const sendMail = () => {
        dispatch(showLoadingAction('店舗応募中...'))
        const userID = process.env.REACT_APP_USER_ID;
        const serviceID = process.env.REACT_APP_SERVICE_ID;
        const templateID = process.env.REACT_APP_APLLICATIONS_TEMPLATE_ID;
        const timestamp = FirebaseTimeStamp.now();

        if(userID !== undefined && serviceID !== undefined && templateID !== undefined) {
            init(userID);
            const template_param = {
                companyName: company.companyName,
                from_firstName: user.firstName,
                from_lastName: user.lastName,
                from_email: user.email,
                age: user.age,
                tel: user.tel,    
                addressNum: user.addressNum,
                address: user.address,
                housenumber: user.housenumber,
                department: user.department,
                grade: user.grade,
                companyEmail: company.email
            };
            const toMailbox = {
                send_at: timestamp,
                companyName: company.companyName,
                from_firstName: user.firstName,
                from_lastName: user.lastName,
                from_email: user.email,
                age: user.age,
                tel: user.tel,    
                addressNum: user.addressNum,
                address: user.address,
                housenumber: user.housenumber,
                department: user.department,
                grade: user.grade,
            };

            send(serviceID, templateID, template_param)
            .then(() => {
                dispatch(push('/mailcomplete'))
                dispatch(hideLoadingAction());
            }).catch(() => {
                dispatch(hideLoadingAction());
                alert('送信に失敗しました。')
            })
            const companyRef = db.collection('companys').doc(id).collection('mailbox').doc()
            toMailbox['mailboxId'] = companyRef.id;
            companyRef.set(toMailbox);
        }
        dispatch(addApplicationCompany)
    }

    const addApplicationCompany = useCallback(() => {
        const timestamp = FirebaseTimeStamp.now();

        dispatch(addApplication({
            added_at: timestamp,
            companyName: company.companyName,
            images: company.images,
            address: company.address,
            partTime: company.partTime,
            saraly: company.saraly,
            addressNum: company.addressNum,
            category: company.category,
            companyid: company.companyid,
        }))
    }, [company])

    return (
        <div>
            <NotSignInHeader />
            <div className={classes.space_small} />
            <div className={classes.space_small} />
            <div className={classes.space_big} />
            {user && (
                <div>
                    <table className="table">
                    {company && (
                        <tr className="tr">
                            <th className="th">応募する店舗名</th>
                            <td className="td">{company.companyName}</td>
                        </tr>
                    )}
                        <tr className="tr">
                            <th className="th">姓</th>
                            <td className="td">{user.firstName}</td>
                        </tr>
                        <tr className="tr">
                            <th className="th">名</th>
                            <td className="td">{user.lastName}</td>
                        </tr>
                        <tr className="tr">
                            <th className="th">せい</th>
                            <td className="td">{user.firstReading}</td>
                        </tr>
                        <tr className="tr">
                            <th className="th">めい</th>
                            <td className="td">{user.lastReading}</td>
                        </tr>
                        <tr className="tr">
                            <th className="th">学年・学科</th>
                            <td className="td">{user.grade}/{user.department}</td>
                        </tr>    
                        <tr className="tr">
                            <th className="th">年齢</th>
                            <td className="td">{user.age}歳</td>
                        </tr>                   
                        <tr className="tr">
                            <th className="th">メールアドレス</th>
                            <td className="td">{user.email}</td>
                        </tr>
                        <tr className="tr">
                            <th className="th">電話番号</th>
                            <td className="td">{user.tel}</td>
                        </tr>                       
                        <tr className="tr">
                            <th className="th">郵便番号</th>
                            <td className="td">〒{user.addressNum}</td>
                        </tr>
                        <tr className="tr">
                            <th className="th">住所</th>
                            <td className="td">{user.address + user.housenumber}</td>
                        </tr>                                               
                    </table>                                                                                                                
                </div>
            )}
            <div className={classes.space_small} />            
            <button className={classes.button} onClick={() => dispatch(push('/companydetails/mailform/' + company.companyid))}>戻る</button>
            <button className={classes.button} onClick={() => sendMail()}>送信</button>
            <div className={classes.space_small} />
            <Footer />
        </div>
    )
}

export default MailConfirm;