import React, { useState, useEffect } from 'react';
import IconButton from "@material-ui/core/IconButton";
import { Typography, Badge } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { useSelector } from 'react-redux';
import { getIsSignedIn, getUserId } from '../../redux/users/selectors';
import { useDispatch } from 'react-redux';
import { signOut } from '../../redux/users/operations';
import { push } from 'connected-react-router';
import { makeStyles } from '@material-ui/core';
import { db } from '../../firebase';

const useStyles = makeStyles((theme) => ({
    menu: {
        paddingLeft: 20,
        fontSize: 14,
        cursor: "pointer",
        color: "#707070"
    },
    menuLg: {
        display: "none",
        [theme.breakpoints.up('sm')]: {
          display: "block",
        }
      },
    menuSm: {
        display: "block",
        [theme.breakpoints.up('sm')]: {
          display: "none",
        }
    },
    layout: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    }
}))
const NotSignHeaderMenu = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles();
    const selector = useSelector((state) => state)
    const signedIn = getIsSignedIn(selector)
    const uid = getUserId(selector)

    return (
        <>
        <div className={classes.layout}>
        <div className={classes.menuLg}>
            {!signedIn && (                
                <>
                <Typography variant="p" className={classes.menu} onClick={() => dispatch(push('/signup'))} style={{color: "#FF9300"}}>会員登録</Typography>
                <Typography variant="p" className={classes.menu} onClick={() => dispatch(push('/signin'))} style={{color: "#FF9300"}}>ログイン</Typography>
                <Typography variant="p" className={classes.menu} onClick={() => dispatch(push('/promotion'))}>求人の掲載（店舗）</Typography>
                <Typography variant="p" className={classes.menu} onClick={() => dispatch(push('/contactpage'))}>お問い合わせ</Typography>
                </>
            )}
            {signedIn && (                
                <>
                <Typography variant="p" className={classes.menu} onClick={() => dispatch(push('/mappage'))}>マップページへ</Typography>  
                <Typography variant="p" className={classes.menu} onClick={() => dispatch(push('/basicpro'))}>プロフィール</Typography>  
                <Typography variant="p" className={classes.menu} onClick={() => dispatch(push('/yourapplications'))}>応募</Typography>  
                <Typography variant="p" className={classes.menu} onClick={() => dispatch(push('/yourrecruitments'))}>バイト中</Typography>  
                <Typography variant="p" className={classes.menu} onClick={() => dispatch(push('/chatlogin'))}>口コミ</Typography>                
                <Typography variant="p" className={classes.menu} onClick={() => dispatch(push('/contactpage/login'))}>お問い合わせ</Typography>
                <Typography variant="p" className={classes.menu} style={{paddingRight: 20}} onClick={() => dispatch(signOut())}>サインアウト</Typography>
                </>
            )}                               
        </div>
        <div className={classes.layout}>
                <IconButton
                    aria-label="Menu Items"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={(e) => props.menuDrawerToggle(e)}
                    className={classes.menuSm}
                >
                    <MenuIcon />
                </IconButton>
        </div>
        </div>
        </>
    );
};

export default NotSignHeaderMenu;