import React from "react";
import { makeStyles } from "@material-ui/core";
import { AppBar, Toolbar, Typography, Box, Paper } from '@material-ui/core';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import MenuDrower from "../components/header/MenuDrower";
import NotSignHeaderMenu from "../components/header/NotSignHeaderMenu";
import { useState, useCallback} from "react";
import Logo from '../assets/img/logo.png';
import FooterVar2 from '../components/footer/footer_ver2'

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: "#F5F5F5"
    },
    subject: {
        fontSize: 20,
        textAlign: "left",
        marginLeft: 240,
        marginTop: 75,
        fontWeight: 600,
        color: "#707070",
        [theme.breakpoints.down('sm')]: {
            marginLeft: 20
        }
    },
    subject2: {
        fontSize: 20,
        textAlign: "left",
        marginLeft: 240,
        fontWeight: 400,
        color: "#707070",
        [theme.breakpoints.down('sm')]: {
            marginLeft: 20
        }
    },
    logo: {
        width: 270,
        [theme.breakpoints.down('sm')]: {
          width: "60%"
        }
      },
      title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
          display: 'block',
        },
      },
      toolbar: {
        display: 'flex', 
        justifyContent: 'space-between',
        borderBottom: "solid 2px #FF9D00",
        backgroundColor: "#F5F5F5",
        height: 70
      },
      appBar: {
        zIndex: theme.zIndex.drawer + 1,
        width: "100%",
        position: "fixed"
      },
      page :{
        width: 950,
        margin: "0 auto",
        padding: "40px 50px 70px 50px",
        marginTop: 20,
        backgroundColor: "fff",
        [theme.breakpoints.down('sm')]: {
            width: 300,
        }
      },
      paragrah: {
          textAlign: "left",
      },
      h3: {          
          fontSize: 14,
          color: "#000000"
      },
      section: {
          lineHeight:"24px",
          color: "#707070",
          fontSize: 14
      },
      margin: {
        paddingTop: 70
      },
      marginP: {
        marginTop: 24
      },
}))
const TermOfService = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [menuOpen, setMenuOpen] = useState(false);
    const menuDrawerToggle = useCallback((event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
          }
        setMenuOpen(!menuOpen)
    }, [setMenuOpen, menuOpen]);
    return(
        <div className={classes.background}>
        <div className={classes.root}>
        <header className={classes.appBar} >
            <Toolbar className={classes.toolbar}>
                <Typography variant="h6" noWrap component="div" className={classes.logo} onClick={() => dispatch(push('/'))} >
                    <img src={Logo} alt="ロゴ" onClick={() => dispatch(push('/'))}/>
                </Typography>
                <Box display="flex" style={{marginTop: 30}}>
                    <Typography variant="h6" >
                        <NotSignHeaderMenu menuDrawerToggle={menuDrawerToggle}/>
                    </Typography>
                </Box>
            </Toolbar>
            <MenuDrower open={menuOpen} onClose={menuDrawerToggle} />
        </header>
        </div>
        <div className={classes.margin} />
        <h2 className={classes.subject}>理科大生向けアルバイト情報サイトご利用規定</h2>
        <h2 className={classes.subject2}>「理科大生向けアルバイト情報サイト」利用者規定</h2>
        <Paper className={classes.page} elevation={0}>
            <div className={classes.paragrah}>
                <section>
                    <h3 className={classes.h3}>第1条(本規定の適用及び変更)</h3>
                    <p className={classes.section}> 
                    1 本規定は、合同会社住宅サポート（以下「当社」といいます）がインターネット等を通じて提供する「理科⼤⽣向けアルバイト情報サイトのインターネットサービス」（以下「当サービス」といいます）のサービスを利⽤するため、次条に従い利⽤登録を⾏い、それを当社が承認した者（以下「利⽤者」という）に関して適⽤するものとします。
                    <br />
                    2 当社は随時本規定を変更することができるものとします。本規定の変更は、変更後の規定を当サービスのサイト上に掲⽰します。掲⽰のときから、既に登録となっている利⽤者に対しても変更後の規定を適⽤するものとします。</p>
                </section>
                <div className={classes.marginP} />
                <section>
                    <h3 className={classes.h3}>第2条(利用登録)</h3>
                    <p className={classes.section}>1 利⽤登録を希望される場合は、本規定を同意のうえ当社が定める⽅法により所定の事項を登録していただきます。登録者のみ当サービスを利⽤することができます。<br/>
                    2 当社は、利⽤登録の申込みに対し、登録完了メールの送信をもって登録を承諾します。<br/>
                    3 当社は次の場合には、利⽤登録の申込みを承諾しない場合があります。<br />
                    〈1〉 利⽤登録を申込みされた⽅が所定の事項を登録していない、または申込み時に虚偽の事項を通知したことが判明した場合<br />
                    〈2〉 同⼀利⽤者が複数の申込みをされた場合（初回申込みのみ有効です）<br />
                    〈3〉 その他、当社が不適当と判断する⾏動を登録申込みされた⽅が⾏った場合<br />
                    </p>
                </section>
                <div className={classes.marginP} />
                <section>
                    <h3 className={classes.h3}>第3条(ユーザーID及びパスワードの付与)</h3>
                    <p className={classes.section}>1 利⽤者は、ユーザーＩＤ及びパスワードの管理及び使⽤について責任を負うものとします。<br/>
                    2 利⽤者は、理由の如何を問わず、ユーザーＩＤ及びパスワードを第三者に使⽤させてはならないものとします。<br/>
                    3 利⽤者は、当サービスを利⽤する権利を譲渡することができないものとします。<br/>
                    4 当サービスにおいてユーザーＩＤやパスワードが第三者に使⽤されたときは、その使⽤に関する当サービスにおける⾏為・⾏動は全て当該利⽤者が⾏ったものとみなし、その⾏為によって発⽣したいかなる損害も、当該利⽤者が負担するものとします。</p>
                </section>
                <div className={classes.marginP} />
                <section>
                <h3 className={classes.h3}>第4条(変更の届出)</h3>   
                <p className={classes.section}> 利⽤者は、登録申込みの際届け出た内容に変更があった場合、すみやかにその旨を当社が指定する⽅法により届け出るものとします。 </p>
                      
                </section>
                <div className={classes.marginP} />
                <section>
                    <h3 className={classes.h3}>第5条(利用者の責任)</h3>                
                    <p className={classes.section}>
                    利⽤者は、⾃らの意思によって本サービスを利⽤し、利⽤に関わるすべての責任を負うものとします。当社は、求職している学⽣（以下「求職者」といいます）が求⼈企業（以下「求
                    ⼈者」といいます）等にて就労した場合の仕事内容や処遇など労働条件について確認を⾏うものの、求職者は、⾃らの責任において、改めて求⼈者に労働条件を直接確認したうえで雇
                    ⽤契約を結ぶものとします。当社は、当社が確認し、かつ、求職者に通知した労働条件が、当該雇⽤契約の確定的なものであることに関して保証できないものとします。利⽤者が本
                    サービスの利⽤に起因して、第三者との間で紛争等が⽣じた場合、当社は、当社に責任がある場合を除き、何らの責任を負わず、利⽤者は、⾃らの責任においてこれを対処するものと
                    します。利⽤者は、本サービスが、必ずしも求職者を就労の成功に導かない可能性があること、また、求職者に対して求⼈案件を紹介できない可能性があることを認識したうえで、本サービスを利⽤するものとします。 <br />
                    〈1〉 利⽤者が当サービスに情報登録を⾏う際、虚偽の内容を送信・登録する⾏為 <br/>
                    〈2〉 当サービスにより⼊⼿した情報を転⽤または改ざんする⾏為<br/>
                    〈3〉 有害なコンピュータプログラム等を送信し、または書込む⾏為<br/>
                    〈4〉 他の利⽤者のユ-ザーＩＤ及びパスワードを不正に使⽤する⾏為<br/>
                    〈5〉 当社の著作権その他の知的財産権を侵害し、または侵害するおそれのある⾏為<br/>
                    〈6〉 当社、他の利⽤者または第三者を誹謗中傷、名誉を傷つける⾏為<br/>
                    〈7〉 当社、他の利⽤者または第三者の財産・プライバシーを侵害し、または侵害するおそれのある⾏為<br/>
                    〈8〉 当サービスの運営を妨げる⾏為もしくはそのおそれのある⾏為<br/>
                    〈9〉 公序良俗に反する内容の情報・⽂書・図⾯・図形・⾳声・動画等を当サイト上で公開する⾏為<br/>
                    〈10〉 その他、当社が不適切と判断する⾏為<br/>
                    </p>
                </section>
                <div className={classes.marginP} />
                <section>
                    <h3 className={classes.h3}>第6条(禁止事項)</h3>
                    <p className={classes.section}>1.求職者は、本サービスにおいて、以下に定める⾏為またはそのおそれのある⾏為を⾏ってはならないものとします。<br/>
                    〈1〉 当社または第三者の知的財産権、プライバシー、名誉、肖像権等の権利を侵害する⾏為。<br/>
                    〈2〉 当社または第三者を差別、批判、攻撃または誹謗中傷する⾏為。<br />
                    〈3〉 当社または第三者に対して、不利益を与える⾏為。<br />
                    〈4〉 重複、虚偽の情報または求職者本⼈以外の情報を登録、告知、提供する⾏為。<br />
                    〈5〉 営業活動、または営利を⽬的とする情報を提供する⾏為。<br />
                    〈6〉 政治活動、選挙活動、宗教活動、またはそれらに類する⾏為。<br />
                    〈7〉 本サービスを通じて⼊⼿した情報を、複製、販売、出版、その他私的利⽤の範囲を超えて使⽤する⾏為。<br />
                    〈8〉 本サービスの運営を妨げ、または当社の信⽤を毀損する⾏為。<br />
                    〈9〉 暴⼒的な⾔動、またはそれに類する⾏為。<br />
                    〈10〉 法令、または公序良俗に反する⾏為。<br />
                    〈11〉 前各号に定めるほか、求職活動に関係しないと当社が判断する⾏為。<br />
                    2.求⼈者は、本サービスにおいて、以下に定める⾏為またはそのおそれのある⾏為を⾏ってはならないものとします。<br />
                    〈1〉 当社または第三者の知的財産権、プライバシー、名誉、肖像権等の権利を侵害する⾏為。<br />
                    〈2〉 当社または第三者を差別、批判、攻撃または誹謗中傷する⾏為。<br />
                    〈3〉 当社または第三者に対して、不利益を与える⾏為。<br />
                    〈4〉 営業活動、または営利を⽬的とする情報を提供する⾏為。<br />
                    〈5〉 政治活動、選挙活動、宗教活動、またはそれらに類する⾏為。<br />
                    〈6〉 本サービスを通じて⼊⼿した情報を、複製、販売、出版、その他私的利⽤の範囲を超えて使⽤する⾏為。<br />
                    〈7〉 本サービスの運営を妨げ、または当社の信⽤を毀損する⾏為。<br />
                    〈8〉 暴⼒的な⾔動、またはそれに類する⾏為。<br />
                    〈9〉 法令、または公序良俗に反する⾏為。<br />
                    〈10〉 前各号に定めるほか、求職活動に関係しないと当社が判断する⾏為。<br />
                    3.当社は、前項に定める⾏為を⾏った利⽤者に対して、本サービスの利⽤を停⽌し、将来にわたって利⽤することを拒否する権利を有するものとします。</p>
                </section>
                <div className={classes.marginP} />
                <section>
                    <h3 className={classes.h3}>第7条(提供情報について))</h3>                
                    <p className={classes.section}>利⽤者は、本サービスにおいて提供される情報は求⼈者の責任で提供されるものであり、当該情報について、当社が⼀切保証しないことを認識したうえで、⾃らの責任において利⽤するものとします。</p>
                </section>
                <div className={classes.marginP} />
                <section>
                    <h3 className={classes.h3}>第8条(利用登録の抹消)</h3>                
                    <p className={classes.section}>1 利⽤者が登録の抹消を希望するときは、当社が指定する⽅法により届け出るものとします。<br />
                    2 当社は、利⽤者について以下のいずれかの事由が発⽣した場合、利⽤者の承諾なくして利⽤登録を抹消できるものとします。<br/>
                    〈1〉 利⽤者が、当社の指定する保険契約の契約者ではなくなった場合<br/>
                    〈2〉 利⽤者が、登録申込み時に虚偽の事項を通知したことが判明した場合<br/>
                    〈3〉 利用者が本規定に違反した場合<br/>
                    〈4〉 その他、当社が不適当と判断する⾏為を利⽤者が⾏った場合</p>
                </section>
                <div className={classes.marginP} />
                <section>
                    <h3 className={classes.h3}>第9条(当サービスの変更、一時中断、中止)</h3>                
                    <p className={classes.section}>1 当社は当サービスの内容を予告なく変更できるものとします。<br/>
                    2 当社は、サービス提供のための装置の保守点検・設備更新・運営上の必要、及び天災・災害・装置の故障等の事由により当サービスの提供を中断することがあります。 <br/>
                    3 当社は、運営上その他の理由により当サービスを廃⽌することがあります。 <br/>
                    4 本条項に基づくサービスの変更、中断等によって利⽤者に損害が⽣じても、当社はそれについて⼀切責任を負わないものとします。 <br/>
                    </p>
                </section>
                <div className={classes.marginP} />
                <section>
                    <h3 className={classes.h3}>第10条(利用者情報の取扱い)</h3>                
                    <p className={classes.section}>当社は利⽤者が登録した情報または、当サービスの利⽤に関して届け出た各種情報（以下「利⽤者情報」といいます）について、当社の「プライバシーポリシー｣の定めに従い、適正に取扱います。<br />
                    1 当社は、本サービスでは、以下の⽬的で、本サービス内のお客様に関連する各種情報（例えば、お客様が設定したプロフィール情報、お客様の内部識別⼦、本サービス内で送受信されたメッセージ、投稿コンテンツ及びログ（送受信状況・開封状況・リンク等のクリック状
                    況·動画閲覧状況を含みます）を含みますが、これらに限りません）を確認、利⽤しています。また以下の⽬的の達成に必要な範囲で各種情報を当社の⼦会社若しくは関連会社や当社の業務委託先と共有する場合があり、お客様はこれに同意するものとします。<br/>
                    〈1〉 本サービスの不正利⽤防⽌<br/>
                    〈2〉 提供するサービスの開発·改善·統計分析<br/>
                    〈3〉 最適なサービス（当社⼜は第三者の広告·コンテンツ配信、⼊⼒補助機能を含みます）の提供<br/>
                    2 当社は、本サービス内で設定いただいたプロフィール情報について、アカウントを削除した場合であっても、本サービス内で表⽰するために、⼀定期間保持します。
                    </p>
                </section>
                <div className={classes.marginP} />
                <section>
                    <h3 className={classes.h3}>第11条(利用者情報に関する権利)</h3>                
                    <p className={classes.section}>利⽤者から⾃⼰の利⽤者情報について開⽰・変更・削除を求められた場合は、当社は原則としてすみやかにこれに応じます。</p>
                </section>
                <section>
                    <h3 className={classes.h3}>第12条(著作権等の尊重)</h3>                
                    <p className={classes.section}>当サービスに関する全ての著作権その他の知的財産権は、すべて当社またはその権利者に帰属するものであり、利⽤者はそれらの権利を侵害しもしくは侵害するおそれのある⾏為を⾏ってはならないものとします。</p>
                </section>
                <section>
                    <h3 className={classes.h3}>第13条(利用者に対する通知)</h3>                
                    <p className={classes.section}>1 利⽤者は、登録されたＥメールアドレスを利⽤者に対する通知や情報提供に当社が利⽤することについて承諾するものとします。ただし、利⽤者は、当社所定の届出をすることにより、必要通知を除くＥメールによる情報提供の中⽌を依頼することができるものとします。<br />
                    2 当サービスの利⽤及び本規定に基づく利⽤者宛ての諸通知は、利⽤者が申し出たＥメールアドレスに当社がその内容を送信した時をもって、到着したものとします。</p>
                </section>
                <section>
                    <h3 className={classes.h3}>第14条(クッキーについて)</h3>                
                    <p className={classes.section}>会員は、当社が本サービスの利⽤状況を把握するための仕組みであるクッキー（Cookie）により利⽤状況を記録することに同意するものとします。なお、当社はこの記録について、本サービスの提供・向上等の事業活動をする⽬的として利⽤する場合があります。</p>
                </section>
                <section>
                    <h3 className={classes.h3}>第15条(免責事項)</h3>                
                    <p className={classes.section}>
                        1 当社は、当サービスの利⽤に関して、ＳＳＬの⾼度なデータ暗号化システムを利⽤しており、サービスにおける情報の保護にも万全を期しています。ただし、インターネット通信の性格上セキュリティーを完全に保証するものではなく、いかなる保証も⾏わないものとします。<br />
                        2 当社は、当サービスの利⽤に起因して⽣じた利⽤者の損害について、⼀切責任を負わないものとします。<br />
                        3 当社は、当サービスに不具合、エラー、障害等の瑕疵がないこと、および当サービスが中断なく提供されることをなんら保証しません。また、特定の⽬的に対する適応性、知的財産権その他の権利の侵害等に対してもなんら保証しません。当社は、本サービスにいかなる不備があってもそれを回復、訂正等する義務を負いません。<br />
                        4 当社は、お客様が当サービスを利⽤すること、または、利⽤できなかったことによって損害、トラブル等が⽣じた場合であっても、いかなる責任も負いません。<br />
                        5 当社は、以下に掲げる場合(お客様の情報の消失、毀損を含みますがこれらに限定されません)等において、本規約に別途定める場合を除き、お客様に⽣じる損害、トラブルに関して、その原因の如何を問わず、いかなる責任も負いません。<br />
                        〈1〉 お客様のインターネット使⽤環境により、当サービスが利⽤できない場合<br/>
                        〈2〉 当社が当サービスを変更し、または当サービスの利⽤を中⽌した場合<br/>
                        〈3〉 当サービスの利⽤により、お客様のコンピュータ、OS、ブラウザ、各種ソフトウェア、その他付属機器に不具合が⽣じ、またはお客様のデータが消失、毀損等した場合<br/>
                        〈4〉 当サービスにおいて、お客様同⼠またはお客様と第三者の間で法令または公序良俗に反する⾏為、名誉毀損、侮辱、プライバシー侵害、脅迫、誹謗中傷、いやがらせ等が⽣じた場合<br />
                        6 当社は当サービス上で表⽰される情報等について、明⽰または黙⽰を問わず、その正確性、完全性、最新性、および品質等についてなんら保証しません。また、当社は、当サービス上で表⽰される情報等およびその変更、更新等に関連して、お客様に⽣じた⼀切の損害、トラブルに関していかなる責任も負いません。<br />
                        7 当サービスにおいて投稿情報が本規約または個別規約に定める事項の⼀つにでも違反した場合、当社は、当該情報の全部または⼀部の削除を⾏い、第 6 条の定めにしたがって当サービスの利⽤を中⽌させる場合がありますが、それによって⽣じた⼀切の損害に関していかなる責任も負いません。また、当社は、違反があってもそれを削除等する義務を負いません。<br />
                        8 本規定および個別規約に基づく契約が消費者契約法(平成 12 年法律第 61 号)第 2 条第 3 項の消費者契約に該当する場合には、本規約および個別規約のうち、当社の責任を完全に免責する規定は適⽤されないものとします。この場合においてお客様に発⽣した損害が当社の債務不履⾏または不法⾏為に基づくときは、当社は、当社に重過失がある場合に限り、お客様が直接被った損害を上限として損害賠償責任を負うものとします。<br />
                        9 本規定または個別規約の⼀部の規定が法令に基づいて無効と判断された場合であっても、その他の規定は有効とします。また、本規定または個別規約の⼀部が特定のお客様との間で無効とされ、または取り消された場合でも、本規定および個別規約はその他のお客様との関係では有効とします。<br />
                        10 当社は、本サービスにおける利⽤者のデータが消去・変更されないことを保証しません。必要なデータは利⽤者が⾃らの責任において保存・保管するものとします。<br />
                        11 当社は、本サービスにおける当社および求⼈者の検討基準や判断理由、当社および求⼈者の機密に属する事項について、質問等への回答、その他対応等は⼀切⾏いません。<br />
                        12 当社は、求職者に対して必ず求⼈案件を紹介すること、および本サービスにおける利⽤者の活動が必ず成功することは、保証いたしません。
                    </p>
                </section>
                <section>
                    <h3 className={classes.h3}>第16条(著作物並びに印刷物)</h3>                
                    <p className={classes.section}>
                        1 当サービスからの情報について(印刷含む)、第4条の規定により一切の加工や毀損、廃棄はできません。利用しなくなった場合は必ず当社へ返還してください。尚、返還時には1
                        枚当たり3円相当で引き取りとなります。<br />
                        2 利用者は如何なる理由や方法等をもってしても、当サービスの画面(映像・動画・写真・ 印刷物・アドレス等)を利用者以外に提供することはできません。<br />
                        3 利⽤者による電⼦端末等を介して印刷されたものであっても、印刷物の所有権は当社にあり、無断で他⼈へ渡すことや処分することはできません。また加⼯等をした場合は以後の利⽤を禁⽌すると共に、加⼯等を防ぐ対策システムに伴う費⽤並びに損害が発⽣した場合は請求する場合があります。
                    </p>
                </section>
                <section>
                    <h3 className={classes.h3}>第17条(損害賠償)</h3>                
                    <p className={classes.section}>
                        1 利⽤者が本規定に反し、相⼿⽅に損害を与えた場合は、その相⼿⽅に対しその法律上の賠償責任を負うものとします。<br />
                        2 当社が、個⼈情報等の取り扱いにおいて情報漏洩等が発⽣し被害が⽣じた場合は、当社が加⼊する個⼈情報漏洩に対する賠償責任保険の保険⾦額の範囲内で責任を負うものとします。
                    </p>
                </section>
                <section>
                    <h3 className={classes.h3}>第18条(合意管轄)</h3>                
                    <p className={classes.section}>
                    本規定の解釈、履⾏にあたっては⽇本法が適⽤されるものとし、当サイトの利⽤に関して当社と利⽤者との間に⽣じた紛争については、⼭⼝地⽅裁判所を第⼀審の専属的合意管轄裁判所とします。
                    </p>
                </section>
                <div className={classes.marginP} />
                <section>
                    <h3 className={classes.h3}>付則</h3>
                    <p className={classes.section}>ユーザーが負担すべき金銭に関する事項<br/>
                    ・販売価格以外でお客様に発生する金銭、当サイトのページの閲覧、ソフトウェアのダウンロード等に必要となるインターネット接続料金、通信料金は、お客様のご負担となります。<br/>
                    手数料に関する事項<br/>
                    ・求職者からの手数料については、一切徴収しません。 <br/>
                    ・求人者からの手数料については、次のとおりです。<br/>
                    求職者(アルバイト)を採用時、<br/>
                    雇用予定期間が1ヶ月未満の紹介について、アルバイト紹介が成功した場合において、当該求職者の入社後、雇用契約期間中に支払われる予定の賃金(時給、予定勤務時間・日数から予想)の20%<br/>
                    雇用予定期間に定めのない紹介について、アルバイト紹介が成功した場合において、当該求職者の入社後、3ヶ月で支払われる予定の賃金(勤務5時間は固定、勤務日数は当サイトにて登録いただいた最低日数+2日とする)の15%を紹介手数料として求人者が支払う。<br/>
                    ただし、雇用予定期間が1ヶ月未満の紹介について継続して雇用する場合は、その旨を申告して雇用予定期間に定めのない紹介へと切り替える。紹介手数料については差額を支払う。<br/>
                    ・当社のアルバイト紹介により入社した求職者について、下記に示す期間を満たずに自己都合を理由に退職された場合は、求人者から支払いを受けた紹介手数料を以下の通りの返金対応を行います。<br/>
                    就労期間が1ヶ月に満たずに退職した場合、紹介手数料の2ヶ月分<br />
                    就労期間が2ヶ月に満たずに退職した場合、紹介手数料の1ヶ月分<br />
                    を返金致します。<br />
                    </p>
                </section>
            </div>
        </Paper>
        <div className={classes.margin} />
        <FooterVar2 />
        </div>
    )
}
        

export default TermOfService;