import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import YumetownListItem from "../../components/companys/YumetownListItem";
import Footer from "../../components/footer/footer";
import NotSignInHeader from "../../components/header/NotSignInHeader";
import { fetchYumetown } from "../../redux/company/operation";
import { getYumetown } from "../../redux/company/selectors";
import { makeStyles } from "@material-ui/core";
import { push } from "connected-react-router";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    paper: {
        position: "absolute",
        top: "50%",
        left: "50%",
        WebkitTransform: "translateY(-50%) translateX(-50%)",   
        padding: 20,
        border: "3px solid #FF9D00",
        borderRadius: 5,
        [theme.breakpoints.down('sm')]: {
            width: "90%"
        }
    },
    subject: {
        fontWeight: "bold",
        fontSize: 30,
        [theme.breakpoints.down('sm')]: {
            fontSize: 20
        }
    },
    caution: {
        lineHeight: 2,
        textAlign: "left",
        [theme.breakpoints.down('sm')]: {
            fontSize: 14
        }
    },
    button: {
        padding: "5px 50px",
        fontSize: 14,
        color: "#707070",
        backgroundColor: "#fff",
        border: "1px solid #707070",
        borderRadius: 5,
        width: 200,
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
            width: 180,
        },
        '&:hover': {
            background: "#EEEEEE",
         },
    },
})) 

const Yumetown = () => {
    const classes = useStyles();
    const selector = useSelector((state) => state)
    const dispatch = useDispatch();
    const getYumetowns = getYumetown(selector)

    useEffect(() => {
        dispatch(fetchYumetown());
    }, [])

    return(
        <div>
            <NotSignInHeader />
            <div className="module-spacer--medium" />
            <div className="module-spacer--medium" />
            <div className="module-spacer--medium" />
            <div className={classes.root}>
                {getYumetowns.length > 0 ? (
                    getYumetowns.map((yumetown) => <YumetownListItem key={yumetown.companyid} yumetown={yumetown} /> )
                ) : (
                    <div className={classes.paper}>
                        <h2 className={classes.subject}>現在ゆめタウン宇部での求人はございません...</h2>
                        <div className='module-spacer--medium' />   
                        <p className={classes.caution}>求人が掲載されるまでお待ちください。</p>
                        <div className='module-spacer--medium' />   
                        <button className={classes.button} onClick={() => dispatch(push('/mappage'))}>マップページへ</button>
                    </div>
                )}
            </div>
            <Footer />
        </div>
    )
}

export default Yumetown;