// 第一引数にstate、第２引数にactionsがreturnした値
import * as Actions from './actions';
import { initialState } from '../stores/initialState';

export const UserReducers = (state = initialState.users, action) => {
    switch (action.type) {
        case Actions.SIGN_IN:
            return{
                ...state,
                ...action.payload 
            }
        case Actions.SIGN_OUT:
            return{
                ...action.payload //actions(SIGN_OUT)のpayloadを展開
            }
        default:
            return state
    }
}