// Storeで管理しているstateを参照するファイル
import { createSelector } from "reselect";

const usersSelector = (state) => state.users;

export const getIsSignedIn = createSelector(
    [usersSelector],
    state => state.isSignedIn, //initialState(user)のisSignedInを展開している
)
export const getUserId = createSelector(
    [usersSelector],
    state => state.uid, //initialState(user)のuidを展開している
)
export const getUserRole = createSelector(
    [usersSelector],
    state => state.role
);
