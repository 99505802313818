import React, { useState } from "react";
import PromotionTop from "../assets/img/promotiontop.png";
import PromotionTopPhone from "../assets/img/promotiontop_phone.png";
import PromotionBtoom from "../assets/img/promotionbottom.png";
import PromotionBtoomPhone from "../assets/img/promotionbottom_phone.png";
import Publish1 from "../assets/img/publish1.png";
import Publish1Phone from "../assets/img/publish1_phone.png";
import Publish2 from "../assets/img/publish2.png";
import Publish2Phone from "../assets/img/publish2_phone.png";
import ToRegister from "../assets/img/toregister.png"
import Contact from "../assets/img/contact.png"
import FooterButton from "../assets/img/footerbutton.png"
import FooterButtonPhone from "../assets/img/footerbutton_phone.png"
import { makeStyles } from "@material-ui/core";
import NotSignInHeader from "../components/header/NotSignInHeader";
import FooterVar2 from "../components/footer/footer_ver2";
import ModalGakutika from "../UIkit/ModalGakutika";
import ModalMedical from "../UIkit/ModalMedical";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",        
        margin: "0 auto",
        display: "flex" ,
        flexFlow: "column"       
    },
    background: {
        width: "100%",
        [theme.breakpoints.down('sm')]: {
            display: "none"
        }
    },    
    background_phone: {
        display: "none",
        [theme.breakpoints.down('sm')]: {
            display: "block",
            width: "100%",
        }
    },    
    publish: {
        width: "70%",
        margin: "0 auto",
        [theme.breakpoints.down('sm')]: {
            display: "none"
        }
    },    
    publish_phone: {    
        display: "none",
        [theme.breakpoints.down('sm')]: {
            display: "block",
            width: "70%",
            margin: "0 auto",
        }
    },    
    phone_space: {        
        [theme.breakpoints.down('sm')]: {
            height: 70
        }
    },
    flowbutton: {
        width: "100%",
        position: "fixed",
        bottom: 0,
        left: 0,
        cursor: "pointer",
        [theme.breakpoints.down('sm')]: {
            display: "none"
        }
    },
    flowbutton_phone: {     
        display: "none",
        [theme.breakpoints.down('sm')]: {
            display: "block",
            width: "100%",
            position: "fixed",
            bottom: 0,
            left: 0,
            cursor: "pointer",
        }
    },
    modal: {
        position: "absolute",
        display: "flex", 
        justifyContent: "center",
        bottom: 30,
        left: "50%",
        WebkitTransform: "translateX(-50%)",  
        [theme.breakpoints.down("sm")]: {
            bottom: 10
        }
    },
    contact: {
        position: "absolute",
        display: "flex", 
        justifyContent: "center",
        bottom: 50,
        left: "50%",
        WebkitTransform: "translateX(-50%)",  
        [theme.breakpoints.down("sm")]: {
            bottom: 10
        }
    },
    contactbutton: {
        width: 300,
        margin: "0 10px",
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {
            width: 150
        }
    },
    position: {
        position: "relative"
    }
}))
const Promotion = () => {
    const classes = useStyles();
    return(
        <div className={classes.root}>
        <NotSignInHeader />
        <div className={classes.phone_space} />
        <img className={classes.background} src={PromotionTop} /> 
        <img className={classes.background_phone} src={PromotionTopPhone} />   
        <div className={classes.position}>
        <img className={classes.publish} src={Publish1} />  
        <img className={classes.publish_phone} src={Publish1Phone} /> 
            <div className={classes.modal}>
                <ModalGakutika />
                <ModalMedical />
            </div>
        </div>
        <div className="module-spacer--small" />
        <div className="module-spacer--small" />
        <div className={classes.position}>
        <img className={classes.publish} src={Publish2} />  
        <img className={classes.publish_phone} src={Publish2Phone} />  
            <div className={classes.contact}>
                <a href="https://socubaito-companys.com/signup"><img src={ToRegister} alt="掲載を申し込む" className={classes.contactbutton} /></a>
                <a href="https://socubaito-companys.com/contactform"><img src={Contact} alt="質問・お問い合わせ" className={classes.contactbutton} /></a>
            </div>
        </div>
        <img className={classes.background} src={PromotionBtoom} />  
        <a href="https://socubaito-companys.com/signup"><img src={FooterButtonPhone} alt="掲載を申し込む" className={classes.flowbutton_phone}/></a>
        <FooterVar2 />
        </div>
    )
}

export default Promotion;
