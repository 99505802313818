import {
    createStore as reduxCreateSore,
    combineReducers,
    applyMiddleware
} from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { UserReducers } from '../users/reducers';
import { CompanyReducer } from '../company/reducers';
import { LoadingReducer } from '../loading/reducers';
import { PostsReducer } from '../posts/reducers'

export default function createStore(history) {
    return reduxCreateSore(
        combineReducers({
            loading: LoadingReducer,
            companys: CompanyReducer,
            router: connectRouter(history),
            users: UserReducers,
            posts: PostsReducer
        }),
        applyMiddleware(
            routerMiddleware(history),
            thunk
        )
    )
}