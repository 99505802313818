import { Paper, makeStyles, Typography, IconButton, TextField, OutlinedInput, InputAdornment } from '@material-ui/core';
import React, { useState, useCallback} from 'react';
import { useDispatch } from 'react-redux';
import { signUp } from '../redux/users/operations';
import { push } from 'connected-react-router';
import NotSignInHeader from '../components/header/NotSignInHeader';
import FooterVar2 from '../components/footer/footer_ver2'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { isValidRequiredInput } from '../function/common';
import { useContext } from 'react';
import { multiStateContext } from '../StateContext';

const useStyle = makeStyles((theme) => ({
    root: {
        backgroundColor: "#FCFCFC",        
    },
    card: {
        margin: "0 auto",
        width: "100%",
        maxWidth: 650,
        padding: "50px 100px",
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        border: "solid 1px #707070",
        [theme.breakpoints.down('sm')]: {            
            width: "90%",               
        },
    },
    button: {
        padding: "5px 50px",
        fontSize: 14,
        color: "#707070",
        backgroundColor: "#fff",
        border: "1px solid #707070",
        borderRadius: 5,
        width: 200,
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
            width: 150,
        },
        '&:hover': {
            background: "#EEEEEE",
         },
    },
    buttonDisabled: {
        padding: "5px 50px",
        fontSize: 14,
        color: "#707070",
        backgroundColor: "#ccc",
        border: "1px solid #707070",
        borderRadius: 5,
        width: 200,
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
            width: 150,
        },
    },
    position: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            flexFlow: "column",
        }
    },
    text: {
        width: "max-content",
        cursor: "pointer",
        paddingRight: 20,
        fontSize: 12,
        color: "#707070",
        [theme.breakpoints.down('sm')]: {            
            marginTop: 10
        },
        '&:hover': {
            opacity: 0.8
        },
    },
    field: {
        width: 300,
        marginTop: 0,
    },
    fieldHarf: {
        [theme.breakpoints.down('sm')]: {
            width: 150
        }
    },
    link: {
        display: "flex",
        flexFlow: "column",
        textAlign: "left",
        justifyContent: "start",
        marginRight: 160,
        color: "#707070",
        [theme.breakpoints.down('sm')]: {
            marginRight: 0
        }
    },
    list: {
        fontSize:12,
        cursor: "pointer",
        color: "#0027FF",
        '&:hover': {
            opacity: 0.8
        },
        [theme.breakpoints.down('sm')]: {
            width: 200
        },
    },
    flexFlow: {
        display: "flex",
        flexFlow: "column",
        alignItems: "flex-start",
    },
    flex: {
        display: "flex",
        width: 300,
        [theme.breakpoints.down('sm')]: {
            justifyContent: "center"
        }
    },
    space_big:{
        height: 50,
    },
    space_small: {
        height: 15,
    },
    phone_space: {        
        [theme.breakpoints.down('sm')]: {
            height: 100
        }
    }
}))


const SignUp = () => {
    const classes = useStyle();
    const dispatch = useDispatch();

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("")

    const {
        firstReading, lastReading, firstName, lastName, email, addressNum, address, housenumber,  tel, 
        inputFirstReading, inputLastReading, inputFirstName, inputLastName, inputEmail, inputTel,
        inputAddress, inputHouseNumber, inputAddressNum,
    } = useContext(multiStateContext)
    const [isRevealPassword, setIsRevealPassword] = useState(false);
    const [isRevealConfirmPassword, setIsRevealConfirmPassword] = useState(false);
    const togglePassword = () => {
        setIsRevealPassword((prevState) => !prevState);
    }
    const toggleConfirmPassword = () => {
        setIsRevealConfirmPassword((prevState) => !prevState);
    }
    const inputPassword = useCallback((e) => {
      setPassword(e.target.value)
    }, [setPassword]);
    const inputConfirmPassword = useCallback((e) => {
      setConfirmPassword(e.target.value)
    }, [setConfirmPassword]);

    return(
        <div className={classes.root}>
            <NotSignInHeader />
            <div className={classes.space_big} />
            <div className={classes.space_big} />            
            <Paper elevation={0} className={classes.card}>
            <div className={classes.flex}>
                <div className={classes.flexFlow}> 
                <p style={{fontSize: 14, color: "#707070"}}>姓</p>    
                <TextField
                multiline={true} variant="outlined" margin='dense'style={{marginTop: 0}} placeholder='例)山田'
                required={true} rows={1} value={firstName} type={"text"} onChange={inputFirstName} className={classes.fieldHarf}
                />
                </div>
                <div className={classes.flexFlow}> 
                <p style={{fontSize: 14, color: "#707070"}}>名</p>    
                <TextField
                multiline={true} variant="outlined" margin='dense'style={{marginTop: 0}} placeholder='例)太郎'
                required={true} rows={1} value={lastName} type={"text"} onChange={inputLastName} className={classes.fieldHarf}
                />
                </div>
            </div>
            <div className='module-spacer--extra' /> 
            <div className={classes.flex}>
                <div className={classes.flexFlow}> 
                <p style={{fontSize: 14, color: "#707070"}}>せい</p>   
                <TextField
                multiline={true} variant="outlined" margin='dense' style={{marginTop: 0}} placeholder='例)やまだ'
                required={true} rows={1} value={firstReading} type={"text"} onChange={inputFirstReading} className={classes.fieldHarf}
                />
                </div>
                <div className={classes.flexFlow}> 
                <p style={{fontSize: 14, color: "#707070"}}>めい</p>    
                <TextField
                multiline={true} variant="outlined" margin='dense' style={{marginTop: 0}} placeholder='例)たろう'
                required={true} rows={1} value={lastReading} type={"text"} onChange={inputLastReading} className={classes.fieldHarf}
                />
                </div>
            </div>
            <div className='module-spacer--extra' /> 
            <div className={classes.flexFlow}> 
            <p style={{fontSize: 14, color: "#707070"}}>メールアドレス</p>    
            <TextField
             multiline={true} variant="outlined" className={classes.field} margin='dense'
             required={true} rows={1} value={email} type={"email"} onChange={inputEmail} placeholder='例)example@aaa.com'
            />
            </div>
            <div className={classes.flexFlow}> 
            <p style={{fontSize: 14, color: "#707070"}}>郵便番号</p>    
            <TextField
             multiline={true} variant="outlined" className={classes.field} margin='dense'
             required={true} rows={1} value={addressNum} type={"text"} onChange={inputAddressNum} placeholder='例)123-4567'
            />
            </div>
            <div className={classes.flexFlow}> 
            <p style={{fontSize: 14, color: "#707070"}}>市町村</p>    
            <TextField
             multiline={true} variant="outlined" className={classes.field} margin='dense'
             required={true} rows={1} value={address} type={"text"} onChange={inputAddress} placeholder='例)山陽小野田市'
            />
            </div>
            <div className={classes.flexFlow}> 
            <p style={{fontSize: 14, color: "#707070"}}>番地</p>    
            <TextField
             multiline={true} variant="outlined" className={classes.field} margin='dense'
             required={true} rows={1} value={housenumber} type={"text"} onChange={inputHouseNumber} placeholder='例)大学通1丁目1-1'
            />
            </div>
            <div className='module-spacer--extra' /> 
            <div className={classes.flexFlow}> 
            <p style={{fontSize: 14, color: "#707070"}}>電話番号</p>    
            <TextField
             multiline={true} variant="outlined" className={classes.field} margin='dense' 
             required={true} rows={1} value={tel} type={"text"} onChange={inputTel} placeholder='例)000-0000-0000'
            />    
            </div>
            <div className='module-spacer--extra' /> 
            <div className={classes.flexFlow}> 
            <p style={{fontSize: 14, color: "#707070"}}>パスワード</p>    
            <OutlinedInput 
                id="outlined-adornment-password"    
                placeholder='8文字以上(半角英数)'            
                className={classes.field}           
                margin='dense'  
                type={isRevealPassword ? "text" : "password"}
                value={password}
                onChange={inputPassword}
                endAdornment={
                    <InputAdornment position='end'>
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={togglePassword}
                            edge="end"
                        >
                            {isRevealPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
                labelWidth={70}
            /> 
            </div>   
            <div className='module-spacer--extra' />                              
            <div className={classes.flexFlow}> 
            <p style={{fontSize: 14, color: "#707070"}}>パスワード(確認)</p>    
            <OutlinedInput 
                id="outlined-adornment-password"      
                placeholder='8文字以上(半角英数)'             
                className={classes.field}             
                margin='dense'
                type={isRevealConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={inputConfirmPassword}
                endAdornment={
                    <InputAdornment position='end'>
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={toggleConfirmPassword}
                            edge="end"
                        >
                            {isRevealConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
                labelWidth={70}
            />     
            </div>         
            <div className='module-spacer--extra' /> 
            <ul className={classes.link}>
                <li className={classes.list} onClick={() => dispatch(push('/poricyandtermpage'))}>ポリシー・規定一覧</li>
            </ul>
            <div className='module-spacer--extra' /> 
            <button 
                className={isValidRequiredInput(firstName, lastName, firstReading, lastReading, email, tel, addressNum, address, housenumber, password, confirmPassword) && classes.button
                            || !isValidRequiredInput(firstName, lastName, firstReading, lastReading, email, tel, addressNum, address, housenumber, password, confirmPassword) && classes.buttonDisabled} 
                onClick={() => dispatch(signUp(firstName, lastName, firstReading, lastReading, email, tel, addressNum, address, housenumber, password, confirmPassword))}
                disabled={!isValidRequiredInput(firstName, lastName, firstReading, lastReading, email, tel, addressNum, address, housenumber, password, confirmPassword)}
            >
                会員登録
            </button>
            <div className={classes.space_small} />
            <div className={classes.position}>
                <Typography variant="p" className={classes.text} onClick={() => dispatch(push('/signin'))}>アカウントをお持ちの方はこちら</Typography>
                <Typography variant="p" className={classes.text} onClick={() => dispatch(push('/signin/reset'))}>パスワードを忘れた方はこちら</Typography>
            </div>
            </Paper>
            <div className={classes.phone_space} />
            <div className='module-spacer--small' />    
            <FooterVar2 />
        </div>
    )
}

export default SignUp;