import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { AppBar, Toolbar, Typography, Box, Button, Paper } from '@material-ui/core';
import BackGround from '../assets/img/background.png';
import Footer from '../components/footer/footer';
import Logo from '../assets/img/logo.png';
import Main from '../assets/img/main.png';
import Chat from './Chat';

const useStyles = makeStyles((theme) => ({
  img: {
    position: "relative",
    width: "100%",
    height: "100vh",
  },
  logo: {
    width: "20.8%",
    position: "absolute",
    left: "1.35%",
    top: "35%",
    [theme.breakpoints.down('sm')]: {
      width: "60%",
    }
  },
  menu: {
    position: "absolute",
    width: "6.4%",
    height: 220,
    backgroundColor: "#fcfcfc",
    top: 19,
    paddingTop: 72,
    left: "9.27%",
    zIndex: -10,
    borderRadius: 3,
    [theme.breakpoints.down('sm')]: {
      width: "20%",
      height: 160,
      paddingTop: 40      
    }
  },
  text: {
    width: "63%",
    position: "absolute",
    top: "48%",
    left: "30%",
    WebkitTransform: "translateY(-50%) translateX(-30%)",
    fontSize: 40,
    color: "#fff",
    [theme.breakpoints.down('sm')]: {
      width: "80%",
    }
  },
  button: {
    position: "absolute",
    transform: "translateY(-50%) translateX(-50%)",
    WebkitTransform: "translateY(-50%) translateX(-50%)",
    top: "76%",
    left: "43%",
    backgroundColor: "#fca505",
    color: "#fff",
    width: "100%",
    maxWidth: 240,
    height: 55,
    fontFamily: "MS Pゴシック",
    fontSize: 18,
    '&:hover': {
      backgroundColor: "#fdc04f"
    },
    [theme.breakpoints.down('sm')]: {
      width: 200,
      height: 50,
      fontSize: 14,
      left: "50%",
      top: "70%"
    }
  },
  point: {
    cursor: "pointer",
    fontSize: 14,
    fontFamily: "Hiragino Kaku Gothic ProN",
    [theme.breakpoints.down('sm')]: {
      fontSize: 12
    }
  },
  paper: {
    position: "absolute",
    right: 0,
    top: 0,
    width: 250,
    height: "100vh",
    overflowY: "auto",
    border: "3px solid #fca505",
    [theme.breakpoints.down('sm')]: {
      position: "relative",
      width: "100%",
      marginBottom: 30
    }
  },
  title: {
    fontWeight: "bold",
    textAlign: "left",
    padding: 10,
    backgroundColor: "#fca505",
    color: "#fff"
  },
}));

const Home = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
      <div>
        <AppBar style={{ background: 'transparent', boxShadow: 'none'}} position="fixed">
          <Toolbar>
            <Typography variant="h6" noWrap component="div" className={classes.logo}>
              <img src={Logo} alt="タイトル" />
            </Typography>
              <Typography className={classes.menu}>
                <p onClick={() => dispatch(push('/signup'))} className={classes.point}>会員登録</p>
                <p onClick={() => dispatch(push('/signin'))} className={classes.point}>ログイン</p>                
                <p onClick={() => dispatch(push('/contactpage'))} className={classes.point}>お問合せ</p>
              </Typography>
          </Toolbar>
        </AppBar>
        <img src={BackGround} className={classes.img} />
        <Typography variant="h6">
          <img className={classes.text} src={Main} alt="メイン画像です" />
        </Typography>
        <Paper className={classes.paper}>
          <h2 className={classes.title}>口コミ</h2>
          <Chat />
        </Paper>
        <Button className={classes.button} onClick={() => dispatch(push('/samplemap'))}>アルバイトを探す</Button>
        <Footer />
      </div>
  );
}

export default Home;
