import React, { useState, useCallback } from "react";
import App from "./App";

export const multiStateContext = React.createContext();

const StateContext = () => {
    const [currentstep, setcurrentStep] = useState(1),
          [firstReading, setFirstReading] = useState(""),
          [lastReading, setLastReading] = useState(""),
          [firstName, setFirstName] = useState(""),
          [lastName, setLastName] = useState(""),
          [email, setEmail] = useState(""),
          [tel, setTel] = useState(""),
          [addressNum, setAddressNum] = useState(""),
          [address, setAddress] = useState(""),          
          [housenumber, setHousenumber] = useState(""),          
          [age, setAge] = useState(""),
          [department, setDepartment] = useState([]),
          [grade, setGrade] = useState([]),
          [one, setOne] = useState([]),
          [two, setTwo] = useState([]),
          [three, setThree] = useState([]),
          [four, setFour] = useState([]),
          [five, setFive] = useState([]),
          [itemone, setItemone] = useState(""),
          [itemtwo, setItemtwo] = useState(""),
          [itemthree, setItemThree] = useState(""),
          [itemfour, setItemfour] = useState(""),
          [itemfive, setItemfive] = useState("");

    const inputFirstReading = useCallback((e) => {
        setFirstReading(e.target.value)
    }, [setFirstReading]);
    const inputLastReading = useCallback((e) => {
        setLastReading(e.target.value)
    }, [setLastReading]);      
    const inputFirstName = useCallback((e) => {
        setFirstName(e.target.value)
    }, [setFirstName]);
    const inputLastName = useCallback((e) => {
        setLastName(e.target.value)
    }, [setLastName]);
    const inputEmail = useCallback((event) => {
        setEmail(event.target.value)
    }, [setEmail]);
    const inputTel = useCallback((event) => {
        setTel(event.target.value)
    }, [setTel]);
    const inputAddress = useCallback((event) => {
        setAddress(event.target.value)
    }, [setAddress])
    const inputHouseNumber = useCallback((event) => {
        setHousenumber(event.target.value)
    }, [setHousenumber])
    const inputAddressNum = useCallback((event) => {
        setAddressNum(event.target.value)
    }, [setAddressNum])
    const inputAge = useCallback((event) => {
        setAge(event.target.value)
    }, [setAge])
    const inputItemone = useCallback((event) => {
        setItemone(event.target.value)
    }, [setItemone])
    const inputItemtwo = useCallback((event) => {
        setItemtwo(event.target.value)
    }, [setItemtwo])
    const inputItemthree = useCallback((event) => {
        setItemThree(event.target.value)
    }, [setItemThree])
    const inputItemfour = useCallback((event) => {
        setItemfour(event.target.value)
    }, [setItemfour])
    const inputItemfive = useCallback((event) => {
        setItemfive(event.target.value)
    }, [setItemfive])
    return(
        <div>
            <multiStateContext.Provider
                value={{
                    currentstep, setcurrentStep,
                    firstReading, setFirstReading,
                    lastReading, setLastReading,
                    firstName, setFirstName,
                    lastName, setLastName,
                    email, setEmail,
                    tel, setTel,
                    addressNum, setAddressNum,
                    address, setAddress,
                    housenumber, setHousenumber,
                    age, setAge,
                    department, setDepartment,
                    grade, setGrade,
                    one, setOne,
                    two, setTwo,
                    three, setThree,
                    four, setFour,
                    five, setFive,
                    itemone, setItemone,
                    itemtwo, setItemtwo,
                    itemthree, setItemThree,
                    itemfour, setItemfour,
                    itemfive, setItemfive,
                    inputFirstReading,
                    inputLastReading,
                    inputFirstName,
                    inputLastName,
                    inputEmail,
                    inputTel,
                    inputAddress,
                    inputHouseNumber,
                    inputAddressNum,
                    inputAge,
                    inputItemone,
                    inputItemtwo,
                    inputItemthree,
                    inputItemfour,
                    inputItemfive,
                }}
            >
                <App />
            </multiStateContext.Provider>
        </div>
    )
}

export default StateContext;