import React from "react";
import { ListItem, ListItemAvatar, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import DeleteIcon from "@material-ui/icons/Delete"
import { IconButton } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { getUserId } from "../../redux/users/selectors";
import { db } from "../../firebase";
import { push } from "connected-react-router";
import NoImage from "../../assets/img/no_image.png"

const useStyles = makeStyles((theme) => ({
    list: {        
        height: 200,
        width: 290,
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
        }
    },
    image: {
        objectFit: 'cover',
        height: 120,
        width: 130
    },
    companyName: {
        fontWeight: "bold",
        fontSize: 14,
        [theme.breakpoints.down('sm')]: {
            fontSize: 12
        }
    },
    text: {
        width: '100%',
    },
    flex: {
        display: "flex",
        alignItems: "center"
    },
    detail: {
        color: "#fff",    
        padding: "1px 10px",
        borderRadius: 10,
        backgroundColor: "#FF9D00",
        width: "fit-content",
        fontSize: 12,
        cursor: "pointer",
        '&:hover': {
            opacity: 0.6
        }
    },
    delete: {
        paddingTop: 20,
        height: 40,
        width: 40,
    }
}))
const CompanyListItem = (props) => {
    const classes = useStyles();
    const selector = useSelector((state) => state);
    const dispatch = useDispatch()
    
    const image = props.company.images.length === 0 ? (
                    NoImage
                  ) : (
                      props.company.images[0].path
                  )
    const companyName = props.company.companyName;
    const saraly = props.company.saraly;
    const introduce = props.company.introduce;
    
    const removeCompnayFormList = (id) => {
        const uid = getUserId(selector);
        return db.collection('users').doc(uid)
                 .collection('companylist').doc(id)
                 .delete();        
    }
    
    return(
        <>
            <ListItem className={classes.list}>
                <ListItemAvatar>
                    <img src={image} alt="店舗写真" className={classes.image} />
                </ListItemAvatar>
                <div className={classes.text}>
                    <ul style={{lineHeight: 1}}>
                        <li className={classes.companyName}>{companyName}</li>
                        <li style={{fontSize: 12}}>{introduce}</li>
                        {saraly && (
                            <li style={{fontSize: 12}}>時給{saraly}円</li>
                        )}                        
                        <div className={classes.flex}>
                            <li onClick={() => dispatch(push("/companydetaillogin/" + props.company.companyid))} className={classes.detail}>詳細</li>
                           <IconButton className={classes.delete}><li onClick={() => removeCompnayFormList(props.company.companylistId)}><DeleteIcon /></li></IconButton> 
                        </div>                      
                    </ul>
                </div>
            </ListItem>
            <Divider />
        </>
    )
}

export default CompanyListItem;
                