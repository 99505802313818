import React, {useCallback, useEffect, useState} from "react";
import { List, ListItem, ListItemAvatar, ListItemText, Divider, Button  } from "@material-ui/core";
import { init, send } from "emailjs-com";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { push } from "connected-react-router";
import { getUserId } from "../../redux/users/selectors";
import { useSelector } from "react-redux";
import { db, FirebaseTimeStamp } from "../../firebase";
import { hideLoadingAction, showLoadingAction } from "../../redux/loading/actions";
import NoImage from "../../assets/img/no_image.png"
import { addRecruitment } from "../../redux/users/operations";

const useStyles = makeStyles((theme) => ({
    list: {
        background: '#fff',
        height: 'auto',
        position: "relative"
    },
    image: {
        objectFit: 'cover',
        margin: '8px 16px 8px 0',
        height: 96,
        width: 96
    },
    text: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            fontSize: 14
        }
    },
    flex: {
        display: "flex",
        justifyContent: "flex-end",        
        [theme.breakpoints.down('sm')]: {
            display: "grid",
            justifyContent: "flex-start",
            gridTemplateColumns: `repeat(2, 1fr)`
        }
    },
    space: {
        height: 5
    },
    button: {
        width: 200,
        fontWeight: "bold",
        marginLeft: 10,
        [theme.breakpoints.down('sm')]: {
            width: "80%"
        }
    },
    detail: {
        position: "absolute",
        right: 10,
        bottom: 10,
        backgroundColor: "#FF9D00",
        padding: "5px 10px",
        color: "#fff",
        borderRadius: 5,
        cursor: "pointer",
        '&:hover': {
            opacity: 0.6
         },
    }
}))
const ApplicationHistoryItem = (props) => {
    const classes = useStyles();
    const [user, setUser] = useState(null);    
    const selector = useSelector((state) => state);
    const uid = getUserId(selector)
    const dispatch = useDispatch();

    const datetimeToString = (dt) => {
        return dt.getFullYear() + '/'
            + ('00' + (dt.getMonth()+1)).slice(-2) + '/'
            + ('00' + dt.getDate()).slice(-2) + ' '
    };
    const removeApplicationUser = (id) => {
        const uid = getUserId(selector)        
        return db.collection('users').doc(uid)
                 .collection('applications').doc(id)
                 .delete()
                 .then(() => {
                    console.log('Deleted')
                 })
    }

    useEffect(() => {
        db.collection('users').doc(uid).get()
            .then(doc => {
                const data = doc.data();
                setUser(data)   
            })
    }, [])

    const application = props.application

    const image = props.application.images.length === 0 ? (
                    NoImage
                ) : (
                    props.application.images[0].path
                )

    const applicationDateTime = datetimeToString(application.added_at.toDate());

    const addUserRecruitment = useCallback(() => {
        const timestamp = FirebaseTimeStamp.now();
        dispatch(addRecruitment({
            added_at: timestamp,
            companyName: application.companyName,
            companyid: application.companyid,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
        }))
    }, [application, user])

    const sendMail = () => {
        dispatch(showLoadingAction("面接完了受理中..."))
        const userID = process.env.REACT_APP_USER_ID;
        const serviceID = process.env.REACT_APP_SERVICE_ID;
        const templateID = process.env.REACT_APP_CONTACT_TO_APPLICATION_TEMPLATE_ID;

        if(userID !== undefined && serviceID !== undefined && templateID !== undefined) {
            init(userID);
            const template_param = {
                companyName: application.companyName,
                firstName: user.firstName,
                lastName: user.lastName,
                recurument: "採用"
            }; 

            send(serviceID, templateID, template_param)
            .then(() => {                
                dispatch(hideLoadingAction());
                addUserRecruitment()
                removeApplicationUser(application.applicationsId)
                dispatch(push('yourrecruitments'))
            }).catch(() => {
                dispatch(hideLoadingAction());
                alert("送信に失敗しました。")
            })                        
        }       
    }       

    const sendMailNotRecruitment = () => {
        dispatch(showLoadingAction("面接完了受理中..."))
        const userID = process.env.REACT_APP_USER_ID;
        const serviceID = process.env.REACT_APP_SERVICE_ID;
        const templateID = process.env.REACT_APP_CONTACT_TO_APPLICATION_TEMPLATE_ID;

        if(userID !== undefined && serviceID !== undefined && templateID !== undefined) {
            init(userID);
            const template_param = {
                companyName: application.companyName,
                firstName: user.firstName,
                lastName: user.lastName,
                recurument: "不採用"
            }; 

            send(serviceID, templateID, template_param)
            .then(() => {                
                dispatch(hideLoadingAction());
                removeApplicationUser(application.applicationsId)
            }).catch(() => {
                dispatch(hideLoadingAction());
                alert("送信に失敗しました。")
            })                        
        }       
    }       

    return(
        <div>           
            <List>        
                <>
                    <ListItem className={classes.list}>
                        <ListItemAvatar>
                            <img 
                                className={classes.image}
                                src={image}
                            />
                        </ListItemAvatar>
                        <div className={classes.text}>
                            <ListItemText 
                                primary={"応募日時 : " + applicationDateTime}
                            />
                            <ListItemText 
                                primary={application.companyName}
                            />
                            <ListItemText 
                                primary={"〒 " + application.addressNum}
                            />
                            <ListItemText 
                                primary={application.address}
                            />
                        </div>
                            <p 
                                onClick={() => dispatch(push('/companydetaillogin/' + application.companyid))}
                                className={classes.detail}
                            >
                                詳細
                            </p>
                    </ListItem>
                        <div className="module-spacer--extra-small" />
                    <div className={classes.flex}>
                            <Button 
                                className={classes.button} variant="contained" color="secondary" value={application.companyid}
                                onClick={() => dispatch(push('/contactcompany/' + application.companyid))}
                            >
                            初期連絡
                            </Button> 
                            <Button 
                                className={classes.button} variant="contained" color="secondary"
                                onClick={() => dispatch(push('/contactcompanyinteview/' + application.companyid))}
                            >連絡(2回目以降)
                            </Button>                                                     
                        <Button className={classes.button} variant="contained" onClick={() => sendMail()}>採用</Button>                    
                        <Button className={classes.button} variant="contained" onClick={() => sendMailNotRecruitment()}>不採用</Button>                    
                    </div>
                </>
            </List>
            <div className="module-spacer--extra-small" />
            <Divider />
        </div>
    )
}

export default ApplicationHistoryItem;