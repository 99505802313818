import React, {Component} from "react";
import { functions } from "../firebase";
class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {      
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      const reportError = functions.httpsCallable('reportRenderError');
      this.setState({hasError: true})
      reportError(error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {        
        return <h1>エラーです</h1>;
      }
  
      return this.props.children; 
    }
  }

  export default ErrorBoundary;