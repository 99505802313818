import React from "react";
import { CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getLoadingState, getLoadingText } from '../redux/loading/selectors';
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
    circular: {
        color: "#FDC04F"
    }
})

const Loading = ({children}) => {
    const selector = useSelector((state) => state);
    const isBeingLoaded = getLoadingState(selector);
    const loadingText = getLoadingText(selector);
    const classes = useStyles()

    return(
        <>
            {(isBeingLoaded) && (
                <section className="c-section__loading">
                    <CircularProgress className={classes.circular} />
                    <p>{loadingText}</p>
                </section>
            )}
            {children}
        </>
    )
}

export default Loading;