import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api'
import MapStyle from '../../maps/Map/MapStyle';
import '@reach/combobox/styles.css';
import { db } from '../../../firebase';
import { useDispatch } from 'react-redux';
import MyPosition from '../../../assets/img/home.png';
import { makeStyles } from '@material-ui/styles';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Hits } from 'react-instantsearch-dom';
import { CssBaseline, Box, Drawer, Toolbar, Paper, TextField, Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SampleHeader from '../../header/SampleHeader';
import Search from '../../maps/Search/Search';
import { push } from 'connected-react-router';
import EatPin from '../../../assets/img/eat.png'
import LeisurePin from '../../../assets/img/leisure.png'
import CustomorPin from '../../../assets/img/customer.png'
import SalePin from '../../../assets/img/sale.png'
import EducationPin from '../../../assets/img/education.png'
import DeliveryPin from '../../../assets/img/delivery.png'
import EasyWorkPin from '../../../assets/img/easy_work.png'
import ItPin from '../../../assets/img/it.png'
import MedicalPin from '../../../assets/img/medical.png'
import SpecialtyPin from '../../../assets/img/specialty.png'
import OfficePin from '../../../assets/img/office.png'
import AtherPin from '../../../assets/img/ather.png'
import NoImage from '../../../assets/img/no_image.png'
import Building from "../../../assets/img/ather.png";

const APIKEY = process.env.REACT_APP_GOOGLE_KEY

const containerStyles = {
    width: "100%",
    height: "100vh",
}

const options = {
    styles: MapStyle,
    zoomControl: true,
    disableDefaultUI: true,
}

const libraries = ["places"];
const drawerWidth = 280;
const useStyles = makeStyles((theme) => ({
    flex: {
        display: "flex",
        justifyContent: "space-between",        
        [theme.breakpoints.down('sm')]: {
            flexFlow: "column",
        }
    },    
    root: {
        display: 'flex',
      },
    drawerLg: {
        width: drawerWidth,
        flexShrink: 0,
        display: "none",
        [theme.breakpoints.up('sm')]: {
            display: "block",
        }
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerContainer: {
        overflow: 'auto',
    },
    content: {
        flexGrow: 1,
    },
    img: {
        objectFit: 'cover',
        height: 120,
        width: 130,
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        }
    },
    inforoot: {
        width: 280,
        display: "flex",
        justifyContent: "flex-start",
        [theme.breakpoints.down('sm')]: {
            flexFlow: "column",
            width: 150
        }
    },
    flexinfo: {
        display: "flex",
        alignItems: "start",  
        flexFlow: "column",
        marginLeft: 10,
    },
    infoIntro: {
        textAlign: "left",
        fontSize: 12
    },
    companyName: {
        fontWeight: "bold",
        textAlign: "left",
        [theme.breakpoints.down('sm')]: {
            fontSize: 14
        }
    },
    detailsbutton: {
        color: "#fff",
        padding: "3px 10px",
        borderRadius: 15,
        backgroundColor: "#FF9D00",
        width: "fit-content",
        fontSize: 14,
        cursor: "pointer",
        '&:hover': {
            opacity: 0.7,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 12
        }
    },
    complex: {
        color: "blue",
        cursor: "pointer",
        '&:hover' : {
            opacity: 0.6
        }
    },
    saralyCalculation: {
        position: "fixed",
        zIndex: 100,
        bottom: 2,
        left: 280,        
        border: "3px solid #FDC04F",
        [theme.breakpoints.down('sm')]: {
            display: "none"
        }
    },
    saralyTitle: {
        backgroundColor: "#FDC04F",
        color: "#fff",
        fontWeight: "bold",
        fontSize: 18
    },
    saralyField: {
        width: 80
    },
    saralyTh: {
        textAlign: "left"
    },
    saralyTd: {
        display: "flex",
        alignItems: "center"
    },
    accordion: {
        display: "none",
        [theme.breakpoints.down('sm')]: {
            width: 250,
            display: "block",
        }    
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}))
const applicationId = process.env.REACT_APP_ALGOLIA_APP_ID;
const algolia_APIKEY = process.env.REACT_APP_ALGOLIA_API_KEY;
const searchClient = algoliasearch(applicationId, algolia_APIKEY);

const SampleMap = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: APIKEY,
        libraries,
    });
    const [currentPosition, setCurrentPosition] = useState();
    const [center, setCenter] = useState({lat: "", lng: ""});
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [sanpark, setSanpark] = useState(null);
    const [yumetown, setYumetown] = useState(null);

    const [saraly, setSaraly] = useState(""),
          [time, setTime] = useState(""),
          [date, setDate] = useState("");

    const inputSaraly = useCallback((e) => {
    setSaraly(e.target.value)
    }, [setSaraly])
    const inputTime = useCallback((e) => {
    setTime(e.target.value)
    }, [setTime])
    const inputDate = useCallback((e) => {
    setDate(e.target.value)
    }, [setDate])

    const mapRef = useRef();
    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
    }, []);

    const success = data => {
        const currentPosition = {
            lat: data.coords.latitude,
            lng: data.coords.longitude,
        };
        setCurrentPosition(currentPosition);
        setCenter(currentPosition);
    };
  
    const error = data => {
      const currentPosition = {
        lat: 33.95724965440527,
        lng: 131.1878923993435
      };
      setCurrentPosition(currentPosition);
      setCenter(currentPosition);
    };

    const sanparkInfo = [
        { location: { lat: 33.985168684938174, lng: 131.170980697499 } },        
    ];
    const yumetownInfo = [
        { location: { lat: 33.98485781984043, lng: 131.20621563982903 } },
    ];

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(success, error)
    },[])

    useEffect(() => {
        db.collection('companys').doc().get()
         .then(doc => {
             const data = doc.data();
             setSelectedCompany(data);
         })
    }, []);

    function HitData ({hit}){
        return(
            <div>
            <Marker
             position={{lat: parseFloat(hit.lat), lng: parseFloat(hit.lng)}}
             onClick={() => {
                setSelectedCompany(hit)
            }}
            icon={{
                url: 
                hit.category === "飲食" && EatPin || 
                hit.category === "レジャー" && LeisurePin || 
                hit.category === "接客" && CustomorPin || 
                hit.category === "販売" && SalePin || 
                hit.category === "教育" && EducationPin || 
                hit.category === "配送" && DeliveryPin || 
                hit.category === "軽作業" && EasyWorkPin || 
                hit.category === "IT" && ItPin || 
                hit.category === "医療・福祉" && MedicalPin || 
                hit.category === "専門" && SpecialtyPin || 
                hit.category === "事務" && OfficePin || 
                hit.category === "その他" && AtherPin,
                anchor: new window.google.maps.Point(17, 46),
                scaledSize: new window.google.maps.Size(37, 37),
                origin: new window.google.maps.Point(0, 0),
            }}
            />
            {selectedCompany && (
                 <InfoWindow 
                 position={{lat: parseFloat(selectedCompany.lat), lng: parseFloat(selectedCompany.lng)}}
                 onCloseClick={() => setSelectedCompany(null)}
             >
                 <div>
                     <div className={classes.inforoot}>
                     {selectedCompany.images.length === 0 ? (
                            <img src={NoImage} alt="No Image" className={classes.img} />
                        ) : (
                            <img src={selectedCompany.images[0].path} className={classes.img} />
                        )}    
                     <ul className={classes.flexinfo}>
                        <h2 className={classes.companyName}>{selectedCompany.companyName}</h2>
                        <li className={classes.infoIntro}>{selectedCompany.introduce}</li>
                        <li className={classes.info}><strong>時給</strong> {selectedCompany.saraly}円</li>
                        <li onClick={() => dispatch(push('/companydetail/' + selectedCompany.companyid))} className={classes.detailsbutton}>詳細を見る</li>
                     </ul>
                     </div>
                 </div>
             </InfoWindow>
            )}
             {hit.complex === "小野田サンパーク" && sanparkInfo.map((marker) => (
                <Marker
                key={`${marker.location.lat * marker.location.lng}`}
                position={{
                    lat: marker.location.lat,
                    lng: marker.location.lng,
                }}
                icon={{
                    url: Building,
                    anchor: new window.google.maps.Point(17, 46),
                    scaledSize: new window.google.maps.Size(37, 37),
                    origin: new window.google.maps.Point(0, 0),
                }}
                onClick={() => {
                    setSanpark(marker);
                }}
                />
            ))}
             {sanpark ? (
                <InfoWindow
                position={{
                    lat: sanpark.location.lat,
                    lng: sanpark.location.lng,
                }}
                onCloseClick={() => {
                    setSanpark(null);
                }}
                >
                <div onClick={() => dispatch(push('/sanparkshopsguest'))} className={classes.complex}>
                サンパーク一覧
                </div>
                </InfoWindow>
            ) : null}
             {hit.complex === "宇部ゆめタウン" && yumetownInfo.map((marker) => (
                <Marker
                key={`${marker.location.lat * marker.location.lng}`}
                position={{
                    lat: marker.location.lat,
                    lng: marker.location.lng,
                }}
                onClick={() => {
                    setYumetown(marker);
                }}
                icon={{
                    url: Building,
                    anchor: new window.google.maps.Point(17, 46),
                    scaledSize: new window.google.maps.Size(37, 37),
                    origin: new window.google.maps.Point(0, 0),
                }}
                />
            ))}
             {yumetown ? (
                <InfoWindow
                position={{
                    lat: yumetown.location.lat,
                    lng: yumetown.location.lng,
                }}
                onCloseClick={() => {
                    setYumetown(null);
                }}
                >
                <div onClick={() => dispatch(push('/yumetownshopsguest'))} className={classes.complex}>ゆめタウン一覧</div>
                </InfoWindow>
            ) : null}
            </div>
            )            
    }


    const THeHit = ({hit}) => {
        return <HitData hit={hit} />
    }
        
    if(loadError) return "Error loading Maps"
    if(!isLoaded) return "Loading Maps"
    return(
        <InstantSearch searchClient={searchClient} indexName={"companys"}>
        <div className={classes.root}>
            <CssBaseline />
            <SampleHeader />
            <Drawer
                variant="permanent"
                className={classes.drawerLg}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
            <Toolbar />
            <div className={classes.drawerContainer}>
                <Search />
            </div>
            </Drawer>
            <Box component="main" className={classes.content}>
            <div className={classes.mapContainer}>
                <GoogleMap
                    mapContainerStyle={containerStyles}
                    center={center}
                    zoom={14}
                    options={options}
                    onLoad={onMapLoad}
                    >
                    <Hits hitComponent={THeHit}/>
                    {currentPosition ? (
                        <Marker 
                            position={{ lat: currentPosition.lat, lng: currentPosition.lng }}
                            icon={{
                                url: MyPosition,
                                anchor: new window.google.maps.Point(17, 46),
                                scaledSize: new window.google.maps.Size(37, 37),
                                origin: new window.google.maps.Point(0, 0),
                            }}
                        />
                        ): null}

                    <Paper className={classes.saralyCalculation}>
                        <h2 className={classes.saralyTitle}>1ヶ月の給料を計算</h2>
                        <div style={{padding: 10}}>
                            <table>
                            <tr>
                                <th className={classes.saralyTh}>時給</th>
                                <td className={classes.saralyTd}>
                                    <TextField value={saraly} onChange={inputSaraly} variant="outlined" className={classes.saralyField} margin="dense" /> 円
                                </td>
                            </tr>
                            <tr>
                                <th className={classes.saralyTh}>勤務時間</th>
                                <td className={classes.saralyTd}>
                                    <TextField value={time} onChange={inputTime} variant="outlined" className={classes.saralyField} margin="dense" /> 時間
                                </td>
                            </tr>                        
                            <tr>
                                <th className={classes.saralyTh}>日数</th>
                                <td className={classes.saralyTd}>
                                    <TextField value={date} onChange={inputDate} variant="outlined" className={classes.saralyField} margin="dense" /> 日
                                </td>
                            </tr>                        
                            <tr>
                                <th className={classes.saralyTh}>合計</th>
                                <td className={classes.saralyTd}>
                                    <TextField value={parseInt(saraly)*parseInt(time)*parseInt(date)} className={classes.saralyField} variant="outlined" margin="dense" /> 円
                                </td>
                            </tr>                        
                            </table>    
                            <p style={{fontSize: 12, textAlign: "left"}}>*あくまでも推定の月収で<br />確定された金額ではありません</p>               
                        </div>
                    </Paper>
                    <div className={classes.accordion}>
                         <Accordion>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography className={classes.heading}>1ヶ月の給料を計算</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                                <table>
                                    <tr>
                                        <th className={classes.saralyTh}>時給</th>
                                        <td className={classes.saralyTd}>
                                            <TextField value={saraly} onChange={inputSaraly} variant="outlined" className={classes.saralyField} margin="dense" /> 円
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className={classes.saralyTh}>勤務時間</th>
                                        <td className={classes.saralyTd}>
                                            <TextField value={time} onChange={inputTime} variant="outlined" className={classes.saralyField} margin="dense" /> 時間
                                        </td>
                                    </tr>                        
                                    <tr>
                                        <th className={classes.saralyTh}>日数</th>
                                        <td className={classes.saralyTd}>
                                            <TextField value={date} onChange={inputDate} variant="outlined" className={classes.saralyField} margin="dense" /> 日
                                        </td>
                                    </tr>                        
                                    <tr>
                                        <th className={classes.saralyTh}>合計</th>
                                        <td className={classes.saralyTd}>
                                            <TextField value={parseInt(saraly)*parseInt(time)*parseInt(date)} className={classes.saralyField} variant="outlined" margin="dense" /> 円
                                        </td>
                                    </tr>                        
                                </table>    
                            </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </GoogleMap>
            </div>
            </Box>
        </div>
        </InstantSearch>
    )
}
    
export default SampleMap;
                





