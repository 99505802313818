import React, { useCallback, useState } from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import useStyle from './styles'
import SearchIcon from '@material-ui/icons/Search';
import MenuDrower from './MenuDrower';
import SampleHeaderMenu from './SampleHeaderMenu';
import SearchDrower from './SerchDrower';
import Logo from '../../assets/img/logo.png';
import { push } from 'connected-react-router';

const SampleHeader = () => {
    const classes = useStyle();
    const dispatch = useDispatch();
    const [searchOpen, setSearchOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const searchDrawerToggle = useCallback((event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
          }
        setSearchOpen(!searchOpen)
    }, [setSearchOpen, searchOpen]);
    const menuDrawerToggle = useCallback((event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
          }
        setMenuOpen(!menuOpen)
    }, [setMenuOpen, menuOpen]);
    return(
        <div className={classes.root}>
        <header className={classes.appBar} >
            <Toolbar className={classes.toolbar}>
                <Typography variant="h6" noWrap component="div" className={classes.logo} >
                <IconButton
                        aria-label="Menu Items"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={(e) => searchDrawerToggle(e)}
                        className={classes.search}
                    >
                        <SearchIcon searchDrawerToggle={searchDrawerToggle}/>
                </IconButton>
                <div>
                    <img src={Logo} alt="ロゴ" onClick={() => dispatch(push('/'))}/>
                </div>
                </Typography>
                <Box display="flex">
                    <Typography variant="h6">
                        <SampleHeaderMenu menuDrawerToggle={menuDrawerToggle}/>
                    </Typography>
                </Box>
            </Toolbar>
            <SearchDrower open={searchOpen} onClose={searchDrawerToggle} />
            <MenuDrower open={menuOpen} onClose={menuDrawerToggle} />
        </header>
        </div>
    )
}

export default SampleHeader;
