import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

const useStyles = makeStyles((theme) => ({
    footer: {
        width: "100%",
        height: 40,
        display: "flex",
        position: "fixed",
        bottom: 0,
        paddingRight: 60,
        alignItems: "center",
        justifyContent: "flex-end",
        color: "#fff",
        backgroundColor: "#414141",
        border: "solid 1px #707070",
        [theme.breakpoints.down('sm')]: {
            padding: "40px 20px",
            flexFlow: "column",
            alignItems: "flex-end",
            justifyContent: "center",
            textAline: "left",
        }
      },
    mobile: {
        marginRight: 30,
        textDecoration: "underline",
        fontSize: 14,
        cursor: "pointer",
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            marginRight: 0,
            fontSize: 12
        }
    }
}))

const Footer = () => {
    const classes = useStyles();
    const dispatch = useDispatch()
    return(
        <div className={classes.footer}>
        <Typography className={classes.mobile} onClick={() => dispatch(push('/promotion'))}>店舗の掲載に関する情報はこちら</Typography>
        <Typography className={classes.mobile} onClick={() => dispatch(push('/companyOverview'))}>会社概要</Typography>
        <Typography className={classes.mobile} onClick={() => dispatch(push('/poricyandtermpage'))}>ポリシー・規定一覧</Typography>
    </div>
    )
}

export default Footer;