import React, { useEffect } from "react";
import { List } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { fetchPosts } from "../redux/posts/operations";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getPosts } from "../redux/posts/selectors";
import PostsItem from "../components/companys/PostsItem";

const useStyle = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            height: 50
        }
    },
    title: {
        fontWeight: "bold",
        textAlign: "left",
        paddingTop: 10,
    },
    flex: {
        display: "flex",
        alignItems: "flex-start",
        flexFlow: "column"
    }
}))

const Chat = () => {
    const classes = useStyle();
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);
    const posts = getPosts(selector);

    useEffect(() => {
        dispatch(fetchPosts());
    }, [])


    return (
        <div className={classes.root}>    
             <section className="c-section-wrapin">
                <List>
                    {posts.length > 0 && (
                        posts.map((post) => <PostsItem post={post} key={post.postId}/>)
                    )}
                </List>
            </section>          
        </div>
    )
}

export default Chat;