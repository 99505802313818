import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Modal, Paper } from "@material-ui/core";
import AboutGakutika from "../assets/img/aboutgakutika.png"
import GakutikaContent from "../assets/img/gakutikacontent.png"

const useStyles = makeStyles((theme) => ({
    root: {
      position: 'absolute',
      width: 800,
      top: "50%",
      left: "50%",
      WebkitTransform: "translateY(-50%) translateX(-50%)",   
    },
    paper: {
        padding: 10,
        border: "3px solid #fca505",
        [theme.breakpoints.down('sm')]: {
          width: "40%",
          margin: "0 auto"
        }
    },
    button: {
      width: 250,
      height: 125,
      marginLeft: 10,
      marginRight: 10,
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        width: 80,
        height: "auto"
      }
    },
    image: {
      [theme.breakpoints.down('sm')]: {
        width: "40%",
        margin: "0 auto"
      }
    }
}));

const ModalGakutika = () => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const body = (
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <img src={GakutikaContent} alt="ガクチカの内容" />
          </Paper>
        </div>
    );

    return (
        <div>
          <img src={AboutGakutika} alt="ガクチカとは？" className={classes.button} onClick={handleOpen} />
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.image}
          >
            {body}
          </Modal>
        </div>
    );
}

export default ModalGakutika;