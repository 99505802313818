import { Paper, makeStyles } from "@material-ui/core";
import React from "react";
import Footer from "../components/footer/footer";
import NotSignInHeader from "../components/header/NotSignInHeader";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#FCFCFC",   
        height: "100vh"
    },
    paper: {
        width: "70%",
        borderTop: "2px solid #FF9D00",
        margin: "0 auto",
        padding: 20
    },
    table: {
        width: "100%",
        margin: "0 auto",
    },
    tr: {
        borderBottom: "1px solid #ccc",    
        [theme.breakpoints.down('sm')]: {
            display: "flex",
            flexFlow: "column"
        }
    },
    th: {
        padding: 20,
        textAlign: "left",
        [theme.breakpoints.down('sm')]: {
            padding: 10
        }
    },
    td: {
        padding: 20,
        textAlign: "left",
        [theme.breakpoints.down('sm')]: {
            padding: 10
        }
    }
}))

const CompanyOverview = () => {
    const classes = useStyles();
    return(
        <div className={classes.root}>
            <NotSignInHeader />
            <div className="module-spacer--medium" />
            <div className="module-spacer--medium" />
            <div className="module-spacer--medium" />
            <div className="module-spacer--medium" />
            <Paper className={classes.paper}>
                <table className={classes.table}>
                    <tr className={classes.tr}>
                        <th className={classes.th}>会社名</th>
                        <td className={classes.td}>合同会社住宅サポート</td>
                    </tr>
                    <tr className={classes.tr}>
                        <th className={classes.th}>代表者</th>
                        <td className={classes.td}>福田 基博</td>
                    </tr>
                    <tr className={classes.tr}>
                        <th className={classes.th}>許可</th>
                        <td className={classes.td}>有料職業紹介事業 (35-ユ-300072)</td>
                    </tr>
                </table>
            </Paper>
            <Footer />
        </div>
    )
}

export default CompanyOverview;