import React from "react";
import { makeStyles } from "@material-ui/core";
import { AppBar, Toolbar, Typography, Box, Paper } from '@material-ui/core';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import MenuDrower from "../components/header/MenuDrower";
import NotSignHeaderMenu from "../components/header/NotSignHeaderMenu";
import { useState, useCallback} from "react";
import Logo from '../assets/img/logo.png';
import FooterVar2 from "../components/footer/footer_ver2";

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: "#F5F5F5"
    },
    subject: {
        fontSize: 20,
        textAlign: "left",
        marginLeft: 240,
        marginTop: 75,
        fontWeight: 600,
        color: "#707070",
        [theme.breakpoints.down('sm')]: {
            marginLeft: 20
        }
    },
    logo: {
        width: 270,
        [theme.breakpoints.down('sm')]: {
          width: "60%"
        }
      },
      title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
          display: 'block',
        },
      },
      toolbar: {
        display: 'flex', 
        justifyContent: 'space-between',
        borderBottom: "solid 2px #FF9D00",
        backgroundColor: "#F5F5F5",
        height: 70
      },
      appBar: {
        zIndex: theme.zIndex.drawer + 1,
        width: "100%",
        position: "fixed"
      },
      page :{
        width: 950,
        margin: "0 auto",
        padding: "40px 50px 70px 50px",
        marginTop: 20,
        backgroundColor: "fff",
        [theme.breakpoints.down('sm')]: {
            width: 300,
        }
      },
      info: {
        display: "flex",
        flexFlow: "column",
        textAlign: "left",
        alignItems: "end",             
      },
      adress: {
        display: "flex",
        flexFlow: "column",
        textAlign: "left",
      },
      paragrah: {
          textAlign: "left",
      },
      h3: {          
          fontSize: 14,
          color: "#000000"
      },
      section: {
          lineHeight:"24px",
          color: "#707070",
          fontSize: 14
      },
      margin: {
        paddingTop: 70
      },
      marginP: {
        marginTop: 24
      },
}))
const PrivasyPolicy = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [menuOpen, setMenuOpen] = useState(false);
    const menuDrawerToggle = useCallback((event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
          }
        setMenuOpen(!menuOpen)
    }, [setMenuOpen, menuOpen]);
    return(
        <div className={classes.background}>
        <div className={classes.root}>
        <header className={classes.appBar} >
            <Toolbar className={classes.toolbar}>
                <Typography variant="h6" noWrap component="div" className={classes.logo} onClick={() => dispatch(push('/'))} >
                    <img src={Logo} alt="ロゴ" onClick={() => dispatch(push('/'))}/>
                </Typography>
                <Box display="flex" style={{marginTop: 30}}>
                    <Typography variant="h6" >
                        <NotSignHeaderMenu menuDrawerToggle={menuDrawerToggle}/>
                    </Typography>
                </Box>
            </Toolbar>
            <MenuDrower open={menuOpen} onClose={menuDrawerToggle} />
        </header>
        </div>
        <div className={classes.margin} />
        <h2 className={classes.subject}>個人情報保護方針</h2>
        <Paper className={classes.page} elevation={0}>
        <ul className={classes.info}>
          <li style={{fontSize: 14}}>制定日 2022 年 1 月 7 日</li>
          <li style={{fontSize: 14}}>最終改定日 2022 年 1 月 7 日</li>
          <li style={{fontSize: 14}}>代表者名:</li>
          <li style={{fontSize: 14}}>責任者:</li>
        </ul>
            <div className={classes.paragrah}>
                <section>
                    <h3 className={classes.h3}>はじめに</h3>
                    <p className={classes.section}> 合同会社 住宅サポート(以下「当社」とします)は、アルバイト情報事業において
                        サービスのご提供にあたり、情報セキュリティの重要性を認識し、保有する情報資産を事 故・犯罪・過失等の脅威(以下、「セキュリティ事故」といいます。)
                        から保護するとともに健全かつ安全に利用する目的で、この方針を定め、遵守します。</p>
                </section>
                <div className={classes.marginP} />
                <section>                    
                    <p className={classes.section}>
                        個人情報とは、利用者個人に関する情報であって、氏名、生年月日、住所、電話番号、メールアドレス等により
                        利用者個人を識別することができるもの、およびその情報のみでは利用者個人を識別できない場合であっても、他の情報と容易に照合することができ、
                        それにより結果的に利用者個人を識別することができるもの、ならびに利用者に関する個人識別符号が含まれるものをいうものとします。
                    </p>
                </section>
                <div className={classes.marginP} />
                <section>
                    <h3 className={classes.h3}>1. 適用範囲</h3>
                    <p className={classes.section}>
                        当社は、個人情報を以下の利用目的の達成に必要な範囲内で、利用致します。
                        以下に定めのない目的で個人情報を利用する場合、あらかじめご本人の同意を得た上で行ないます。
                        個人情報の提供は任意ですが、提供いただけない場合は本サービスをご利用いただけない場合があります。<br />
                        1. 人材紹介サービスの提供<br/>
                        2. 本サービスの個人の認証 <br/>
                        3. Web応募による応募先への情報提供<br/>
                        4. サービスに関する情報提供<br/>
                        5. 当社からのメルマガ等での配信<br/>
                        6. マッチングをより適切なものにするための個人を特定できないようにした情報の統計等<br/>
                        また、データ入力や DM 発送、イベント運営などの業務を受託する際に、委託された個人情報を取り扱う場合があります。
                    </p>
                </section>
                <div className={classes.marginP} />
                <section>
                <h3 className={classes.h3}>2. 情報セキュリティ体制および内部規程の整備</h3>   
                <p className={classes.section}> 
                (1)当社は、適切な情報セキュリティ体制を構築し、情報セキュリティの維持および向上に取り組むとともに、セキュリティ事故の予防、対応および回復のための体制も整えます。 <br/>
                (2)当社は、この方針に基づいた内部規程を整備し、適用対象者は、これに従い情報資産を適切に取扱います。
                </p>    
                </section>
                <div className={classes.marginP} />
                <section>
                    <h3 className={classes.h3}>3.情報資産の保護および事故への対応</h3>                
                    <p className={classes.section}>
                        (1)当社は、保有する全ての情報資産について、機密性、完全性、可用性のそれぞれの観点からその重要性を認識および把握し、これを適切に保護します。<br />
                        (2)当社は、セキュリティ事故の発生またはそのおそれがあることを了知した場合、速やかに対応して被害の発生や拡大を防止し、回復に努めます。
                    </p>
                </section>
                <div className={classes.marginP} />
                <section>
                    <h3 className={classes.h3}>4.情報セキュリティ教育の実施</h3>
                    <p className={classes.section}>
                        当社は、情報セキュリティについての知識の周知および向上を目的として、従業員等への教育活動を継続的に行います。
                    </p>
                </section>
                <div className={classes.marginP} />
                <section>
                    <h3 className={classes.h3}>5.法令・規範の遵守</h3>                
                    <p className={classes.section}>
                        当社は、情報セキュリティに関する法令その他の規範を遵守します。
                    </p>
                </section>                
                <div className={classes.marginP} />
                <section>
                    <h3 className={classes.h3}>6.見直しおよび改善</h3>                
                    <p className={classes.section}>
                        当社は、情報セキュリティに関する社会状況および技術の進歩等の環境変化に対応するため、この方針並びにこれに基づく体制および内部規程を定期的に見直し、改善します。
                    </p>
                </section>                
                <div className={classes.marginP} />
            </div>
        </Paper>
        <div className={classes.margin} />
        <FooterVar2 />
        </div>
    )
}
        

export default PrivasyPolicy;