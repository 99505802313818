import React, { useState, useEffect } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Badge } from '@material-ui/core';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import LaunchIcon from '@material-ui/icons/Launch';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import RoomIcon from '@material-ui/icons/Room';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import ChatIcon from '@material-ui/icons/Chat';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import WorkIcon from '@material-ui/icons/Work';
import { makeStyles } from '@material-ui/core/styles';
import { push } from 'connected-react-router';
import { signOut } from '../../redux/users/operations';
import { getIsSignedIn, getUserId } from '../../redux/users/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { db } from '../../firebase';

const useStyles = makeStyles((theme) => ({
        drawer: {
            [theme.breakpoints.up('sm')]: {
                width: 256,
                flexShrink: 0,
            }
        },
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
            width: 250,
            [theme.breakpoints.up('sm')]: {
                width: 350,
                flexShrink: 0,
            }
        },
        searchField: {
            alignItems: 'center',
            display: 'flex',
            marginLeft: 32
        },
        list: {
            height: 150
        },
        image: {
            objectFit: 'cover',
            margin: 16,
            height: 96,
            width: 96
        },
        text: {
            width: '100%'
        },
        root: {
            backgroundColor: theme.palette.background.paper,
          },
}));

const MenuDrower = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {container} = props;
    const selector = useSelector((state) => state)
    const uid = getUserId(selector)
    const isSignedIn = getIsSignedIn(selector)

    return(
        <nav className={classes.drawer}>
            <Drawer
                container={container}
                variant="temporary"
                anchor="right"
                open={props.open}
                onClose={(e) => props.onClose(e)}
                classes={{paper: classes.drawerPaper}}
                ModalProps={{keepMounted: true}}
            >
            <div
                onClose={(e) => props.onClose(e)}
                onKeyDown={(e) => props.onClose(e)}
            >
            <div className={classes.root}>
            <List component="nav" aria-label="main mailbox folders">
                {!isSignedIn && (
                    <>
                    <ListItem button>
                        <ListItemIcon>
                            <AssignmentIndIcon />
                        </ListItemIcon>
                        <ListItemText primary="会員登録" onClick={() => dispatch(push('/signup'))}/>
                    </ListItem>
                    <ListItem button>
                        <ListItemIcon>
                            <LaunchIcon />
                        </ListItemIcon>
                        <ListItemText primary="ログイン" onClick={() => dispatch(push('/signin'))}/>
                    </ListItem>
                    <ListItem button>
                        <ListItemIcon>
                            <RoomIcon />
                        </ListItemIcon>
                        <ListItemText primary="ゲストで参加" onClick={() => dispatch(push('/samplemap'))}/>
                    </ListItem>
                    <ListItem button>
                        <ListItemIcon>
                            <ChromeReaderModeIcon />
                        </ListItemIcon>
                        <ListItemText primary="求人の掲載（店舗）" onClick={() => dispatch(push('/promotion'))}/>
                    </ListItem>
                    <ListItem button>
                        <ListItemIcon>
                            <ContactSupportIcon />
                        </ListItemIcon>
                        <ListItemText primary="お問い合わせ" onClick={() => dispatch(push('/contactpage'))}/>
                    </ListItem>
                    </>
                )}                
                {isSignedIn && (
                    <>
                    <ListItem button>
                        <ListItemIcon>
                            <RoomIcon />
                        </ListItemIcon>
                        <ListItemText primary="マップページへ" onClick={() => dispatch(push('/mappage'))}/>
                    </ListItem>
                    <ListItem button>
                        <ListItemIcon>
                            <AccountCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary="プロフィール" onClick={() => dispatch(push('/basicpro'))}/>
                    </ListItem>
                    <ListItem button>
                        <ListItemIcon>
                            <ContactMailIcon />
                        </ListItemIcon>
                        <ListItemText primary="応募" onClick={() => dispatch(push('/yourapplications'))}/>
                    </ListItem>
                    <ListItem button>
                        <ListItemIcon>
                            <WorkIcon />
                        </ListItemIcon>
                        <ListItemText primary="バイト中" onClick={() => dispatch(push('/yourrecruitments'))}/>
                    </ListItem>
                    <ListItem button>
                        <ListItemIcon>
                            <ChatIcon />
                        </ListItemIcon>
                        <ListItemText primary="口コミ" onClick={() => dispatch(push('/chatlogin'))}/>
                    </ListItem>
                    <ListItem button>
                        <ListItemIcon>
                            <ContactSupportIcon />
                        </ListItemIcon>
                        <ListItemText primary="お問い合わせ" onClick={() => dispatch(push('/contactpage'))}/>
                    </ListItem>
                    <ListItem button>
                        <ListItemIcon>
                            <ExitToAppIcon />
                        </ListItemIcon>
                        <ListItemText primary="サインアウト" onClick={() => dispatch(signOut())}/>
                    </ListItem>
                    </>
                )}                
            </List>
            </div>
            </div>
            </Drawer>
        </nav>
    )
}

export default MenuDrower;