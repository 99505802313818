import { alpha, makeStyles } from '@material-ui/core/styles';

const drawerWidth = 300;
export default makeStyles((theme) => ({
  logo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "20.8%",
    [theme.breakpoints.down('sm')]: {
      width: "60%"
    }
  },
  search: {
    display: "none",
    [theme.breakpoints.down('sm')]: {
      display: "block",
    }
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  toolbar: {
    display: 'flex', 
    justifyContent: 'space-between',
    borderBottom: "solid 3px orange"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    position: "fixed",
    width: "100%",
    backgroundColor: "#fff",
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  searchLg: {
    display: "none",
    [theme.breakpoints.up('sm')]: {
      display: "block",
    }
  },
  searchSm: {
    display: "block",
    [theme.breakpoints.up('sm')]: {
      display: "none",
    }
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },

  img: {
    width: 300
  },
  menu: {
        paddingLeft: 20,
        fontSize: 14,
        cursor: "pointer",
    },
  menuLg: {
      display: "none",
      [theme.breakpoints.up('sm')]: {
        display: "block",
      }
    },
  menuSm: {
      display: "block",
      [theme.breakpoints.up('sm')]: {
        display: "none",
      }
  },
  layout: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
  },
  checkPaper: {
    [theme.breakpoints.up('sm')]: {
        width: 256,
        flexShrink: 0,
    }
  },
}));