import React, { useState, useCallback, useEffect } from "react";
import { Paper, TextField, List } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import NotSignInHeader from "../components/header/NotSignInHeader";
import { addChat, fetchPosts } from "../redux/posts/operations";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getPosts } from "../redux/posts/selectors";
import PostsItem from "../components/companys/PostsItem";
import SelectBox from "../UIkit/SelectBox";
import { getUserId } from "../redux/users/selectors";
import Footer from "../components/footer/footer";
import { db } from "../firebase";

const useStyle = makeStyles((theme) => ({
    root: {
        height: "100vh"
    },
    form: {
        width: 900,
        margin: "0 auto",
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        padding: 30,
        [theme.breakpoints.down('sm')]: {
            width: 350,
            flexShrink: 0,
        }
    },
    field: {
        width: 600,    
        [theme.breakpoints.down('sm')]: {
            width: 300,
            flexShrink: 0,
        }    
    },
    fieldHarf: {
        width: 100,
    },
    select: {
        display: "none",
    },
    button: {
        padding: "5px 50px",
        fontSize: 14,
        color: "#707070",
        backgroundColor: "#fff",
        border: "1px solid #707070",
        borderRadius: 5,
        width: 200,
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
            width: 150,
        },
        '&:hover': {
            background: "#EEEEEE",
         },
    },
    flex: {
        display: "flex",
        alignItems: "flex-start",
        flexFlow: "column"
    },
    caution: {
        fontSize: 14,
        color: "#707070"
    },
}))

const departments = [
    {id: "engineering", name: "工学部"},
    {id: "pharmacy", name: "薬学部"}
]
const grades = [
    {id: "first", name: "1年生"},
    {id: "second", name: "2年生"},
    {id: "third", name: "3年生"},
    {id: "fourth", name: "4年生"},
    {id: "fifth", name: "5年生"},
    {id: "sixth", name: "6年生"},
]

const ChatLogin = () => {
    const classes = useStyle();
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);
    const posts = getPosts(selector);
    const [content, setContent] = useState(""),
          [department, setDepartment] = useState([]),
          [grade, setGrade] = useState([]);

    const inputContent = useCallback((e) => {
        setContent(e.target.value)
    }, [setContent]);

    useEffect(() => {
        dispatch(fetchPosts());
    }, [])

    useEffect(() => {
        const uid = getUserId(selector)
        db.collection('users').doc(uid).get()
            .then(snapshot => {
                const data =  snapshot.data()
                setDepartment(data.department)
                setGrade(data.grade)
            })
    }, [])

    return (
        <div className={classes.root}>
            <NotSignInHeader />
            <div className="module-spacer--medium" />
            <div className="module-spacer--small" />
                <div className={classes.form}>
                    <div className={classes.flex}>
                    <div className={classes.select}>
                    <SelectBox
                        options={departments} required={false} value={department} 
                        select={setDepartment}
                    />         
                    <SelectBox
                        options={grades} required={false} value={grade} 
                        select={setGrade}
                    />          
                    </div>
                    <div className="module-spacer--small" />
                    <TextField 
                        value={content} onChange={inputContent} required={true} multiline={true}
                        type="text" placeholder="コメント..." className={classes.field}
                    />
                    <p className={classes.caution}>※誹謗中傷など不適切なコメントはこちらで修正または削除させていただく場合がございます</p>
                    </div>
                <div className="module-spacer--medium" />
                    <button className={classes.button} onClick={() => dispatch(addChat(department, grade, content))}>投稿</button>
                </div>  
             <section className="c-section-wrapin">
                <List>
                    {posts.length > 0 && (
                        posts.map((post) => <PostsItem post={post} key={post.postId}/>)
                    )}
                </List>
            </section>       
            <div className="module-spacer--medium" />   
            <div className="module-spacer--medium" />   
            <div className="module-spacer--medium" />   
            <Footer /> 
        </div>
    )
}

export default ChatLogin;