import React, { useEffect } from "react";
import { Divider, IconButton } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { db } from "../../firebase";
import { getPosts } from "../../redux/posts/selectors";
import { fetchPosts } from "../../redux/posts/operations";
import { getUserId } from "../../redux/users/selectors";
import HTMLReactParser from 'html-react-parser';

const useStyles = makeStyles((theme) => ({
   message: {
       textAlign: "left",
       padding: 10,
   },
   title: {
       fontWeight: "bold",
       fontSize: 14
   },
   date: {
       fontWeight: 400,
       color: "gray",
       fontSize: 14
   },
   contents: {
       fontSize: 14
   },
   flex: {
       display: "flex",
       justifyContent: "space-between",
       alignItems: "center"
   }
}))
const PostsItem = (props) => {
    const classes = useStyles();
    const selector = useSelector((state) => state);
    let posts = getPosts(selector);
    const userId = getUserId(selector);
    const dispatch = useDispatch();

    useEffect(() => {
        const unsubscribe = db.collection('posts')
            .onSnapshot(snapshots => {

                snapshots.docChanges().forEach(change => {
                    const post = change.doc.data();
                    const changeType = change.type

                    switch (changeType) {
                        case 'added':
                            posts.push(post);
                            break;
                        case 'modified':
                            const index = posts.findIndex(post => post.postId === change.doc.id)
                            posts[index] = post;
                            break;
                        case 'removed':
                            posts = posts.filter(post => post.postId !== change.doc.id);
                            break;
                        default:
                            break;
                    }
                });
                dispatch(fetchPosts(posts))
            });

         return () => unsubscribe()
    }, [])
    const datetimeToString = (dt) => {
        return dt.getFullYear() + '/'
            + ('00' + (dt.getMonth()+1)).slice(-2) + '/'
            + ('00' + dt.getDate()).slice(-2) + ' '
    };
    const removeApplicationUser = (id) => {  
        return db.collection('posts').doc(id)
                 .delete()
                 .then(() => {
                    console.log('Deleted')
                 })
    }

    const returnCodeToBr = (text) => {
        if(text === "") {
            return text
        } else {
            return HTMLReactParser(text.replace(/\r?\n/g, '<br>'))
        }
    }
    const post = props.post
    const postDateTime = datetimeToString(post.created_at.toDate());
    return(
        <div className={classes.message}>
            <p className={classes.date}>{postDateTime}</p>
            {post.department && post.grade && (
                <h2 className={classes.title}>{post.department + post.grade}</h2>
            )}            
            {post.category && (
                <h2 className={classes.title}>{post.category}</h2>
            )}            
            <div className={classes.flex}>
                <p className={classes.contents}>{returnCodeToBr(post.content)}</p>
                <div>
                    <IconButton>
                        {userId === post.created_id && (
                            <DeleteIcon onClick={() => removeApplicationUser(post.postId)} />  
                        )}                    
                    </IconButton>
                </div>
            </div>
            <div className="module-spacer--extra-small" />
            <Divider />
        </div>
    )
}

export default PostsItem;
