import { db, FirebaseTimeStamp } from "../../firebase";
import { fetchPostsAction } from "./actions";
import { push } from "connected-react-router";

export const fetchPosts = () => {
    return async(dispatch, getState) => {
        const uid = getState().users.uid;
        const list = [];

        db.collection('posts')
            .orderBy('created_at', 'desc')
            .get()
            .then((snaphosts) => {
                snaphosts.forEach(snaphost => {
                    const data = snaphost.data();
                    list.push(data)
            })
            dispatch(fetchPostsAction(list))
        })
    }
}

export const addChat = (department, grade, content) => {
    return async(dispatch, getState) => {
        const uid = getState().users.uid;
        const companyRef = db.collection('posts').doc();
        const timestamp = FirebaseTimeStamp.now();
        const chatContent = {
            department: department,
            grade: grade,
            content: content,
            created_at: timestamp,
            updated_at: timestamp,
            created_id: uid
        }
        chatContent['postId'] = companyRef.id;
        await companyRef.set(chatContent, {merge: true});
        dispatch(push('/chatlogin'))
    }
}