import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@material-ui/styles';
import clsx from 'clsx';
import { IconButton, Drawer } from '@material-ui/core';
import useStyle from '../header/styles'
import SearchDrower from './SerchDrower';
import MenuDrower from './MenuDrower';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CompanyCheck from '../../templates/ComapnyCheck';
import HeaderMenu from './HeaderMenu';

const Header = () => {
    const classes = useStyle();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const handleDrawerToggle = useCallback((event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
          }
        setOpen(!open)
    }, [setOpen, open]);
    const searchDrawerToggle = useCallback((event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
          }
        setSearchOpen(!searchOpen)
    }, [setSearchOpen, searchOpen]);
    const menuDrawerToggle = useCallback((event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
          }
        setMenuOpen(!menuOpen)
    }, [setMenuOpen, menuOpen]);
    return(
        <div className={classes.root}>
        <header className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })} >
            <HeaderMenu searchDrawerToggle={searchDrawerToggle} handleDrawerToggle={handleDrawerToggle} menuDrawerToggle={menuDrawerToggle} className={clsx(open && classes.hide)} />
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={open}
                classes={{
                paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerToggle}>
                        {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>
                <CompanyCheck />
            </Drawer>
            <SearchDrower open={searchOpen} onClose={searchDrawerToggle} />
            <MenuDrower open={menuOpen} onClose={menuDrawerToggle} />
        </header>
        </div>
    )
}

export default Header;
