import React, { useEffect, useState } from 'react';
import { auth } from '../firebase';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import NotSignInHeader from '../components/header/NotSignInHeader';
import Footer from '../components/footer/footer';
import { makeStyles, IconButton, OutlinedInput, InputAdornment, Paper } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyle = makeStyles((theme) => ({
    root: {
        height: "100vh",
        backgroundColor: "#FCFCFC"
    },
    card: {
        margin: "0 auto",
        width: "100%",
        maxWidth: 600,
        padding: "50px 100px",
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        border: "solid 1px #333",
    },
    button: {
        padding: "5px 50px",
        fontSize: 14,
        color: "#707070",
        backgroundColor: "#fff",
        border: "1px solid #707070",
        borderRadius: 5,
        [theme.breakpoints.down('sm')]: {
            fontSize: 10
        },
        '&:hover': {
            background: "#EEEEEE",
         },
    },
    space_big:{
        height: 50,
    },
    space_small: {
        height: 30,
    },
    flexFlow: {
        display: "flex",
        flexFlow: "column",
        alignItems: "flex-start"
    },
    field: {
        minWidth: 300,
        marginTop: 0
    }
}))
const ResetPage = () => {
    const classes = useStyle();
    const dispatch = useDispatch();
    const [actionCode, setActionCode] = useState("");
    const [password, setPassword] = useState("")
    const [isRevealPassword, setIsRevealPassword] = useState(false);
    const queryParams = new URLSearchParams(window.location.search);
    const oobCode = queryParams.get('oobCode') || ''

    useEffect(() => {
        setActionCode(oobCode);
    }, [])

    const togglePassword = () => {
        setIsRevealPassword((prevState) => !prevState);
    }

    const handleChange = (e) => {
        setPassword(e.target.value)
    }
    const handlesubmit = (e) => {
        e.preventDefault();     

        auth
        .verifyPasswordResetCode(actionCode)
        .then(() => {
            auth
            .confirmPasswordReset(actionCode, password)
            .then(async (resp) => {
                alert("パスワードをリセットしました。")
                dispatch(push('/signin'))
            })
            .catch((error) => {
                alert("パスワードのリセットに失敗しました。")
            })
        })
    }
    return(
        <div className={classes.root}>
            <NotSignInHeader />
            <div className={classes.space_big} />
            <div className={classes.space_big} />
            <div className={classes.space_big} />
            <Paper elevation={0} className={classes.card}>
            <form onSubmit={handlesubmit}>              
              <div className={classes.flexFlow}>
                <p style={{fontSize: 14, color: "#707070"}}>パスワード再登録</p>            
            <OutlinedInput 
                id="outlined-adornment-password"
                margin='dense'                
                className={classes.field}             
                type={isRevealPassword ? "text" : "password"}
                value={password}
                onChange={handleChange}
                endAdornment={
                    <InputAdornment position='end'>
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={togglePassword}
                            edge="end"
                        >
                            {isRevealPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
                labelWidth={70}
            />       
                </div>                                   
            <div className={classes.space_small} />
            <button className={classes.button}>パスワードリセット</button>
            </form>
            </Paper>
            <Footer />
        </div>
    )
}

export default ResetPage;