import React from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import NotSignInHeader from "../../components/header/NotSignInHeader";
import Footer from "../../components/footer/footer";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        height: "100vh"
    },
    paper: {
        position: "absolute",
        top: "50%",
        left: "50%",
        WebkitTransform: "translateY(-50%) translateX(-50%)",   
        padding: 20,
        border: "3px solid #FF9D00",
        borderRadius: 5,
        [theme.breakpoints.down('sm')]: {
            width: "90%"
        }
    },
    subject: {
        fontWeight: "bold",
        fontSize: 30,
        [theme.breakpoints.down('sm')]: {
            fontSize: 20
        }
    },
    caution: {
        lineHeight: 2,
        textAlign: "left",
        [theme.breakpoints.down('sm')]: {
            fontSize: 14
        }
    },
    button: {
        padding: "5px 50px",
        fontSize: 14,
        color: "#707070",
        backgroundColor: "#fff",
        border: "1px solid #707070",
        borderRadius: 5,
        width: 200,
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
            width: 180,
        },
        '&:hover': {
            background: "#EEEEEE",
         },
    },
}))

const MailComplete = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    return(
        <div className={classes.root}>
            <NotSignInHeader />
            <div className={classes.paper}>
                <h2 className={classes.subject}>応募完了しました！</h2>
                <div className='module-spacer--medium' />   
                <p className={classes.caution}>返信メールが届かない場合は、ご自身のメールアドレスを確認してください。<br />
                メールアドレスにお間違えがない場合はお手数ですがお問合せにてご連絡お願いします。</p>
                <div className='module-spacer--medium' />   
                <button className={classes.button} onClick={() => dispatch(push('/mappage'))}>マップページへ</button>
            </div>
            <Footer />
        </div>
    )
}

export default MailComplete;