import React from 'react';
import Swiper from 'react-id-swiper';
import NoImage from '../../assets/img/no_image.png'
import 'swiper/css/swiper.css'
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    image: {
        width: 490,
        [theme.breakpoints.down('sm')]: {        
            width: "95%",
            height: 200,
        },
    },
}))

const ImageSwiper = (props) => {
    const classes = useStyles();
    const [params] = React.useState({
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: true
        },
        loop: true,
        // spaceBetween: 30
    })

    const images = props.images;
    return(
        <Swiper {...params}>
            {images.length === 0 ? (
                <div>
                    <img src={NoImage} alt="No Image"/>
                </div>
            ) : (
                images.map(image => (
                    <div key={image.id} >
                        <img src={image.path} alt="商品画像" className={classes.image}/>
                    </div>
                ))
            )}
        </Swiper>
    )
}

export default ImageSwiper;