import { createSelector } from "reselect";

const companysSelector = (state) => state.companys;

export const getCompanys = createSelector(
    [companysSelector],
    state => state.companyinfo //initialState(company)のlistを展開している
)
export const getSanpark = createSelector(
    [companysSelector],
    state => state.sanpark //initialState(company)のlistを展開している
)
export const getYumetown = createSelector(
    [companysSelector],
    state => state.yumetown //initialState(company)のlistを展開している
)
