import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { listenAuthState } from './redux/users/operations';
import { getIsSignedIn } from './redux/users/selectors';

const Auth = ({children}) => {
   const selecter = useSelector((state) => state);
   const isSignedIn = getIsSignedIn(selecter);
   const dispatch = useDispatch();

   useEffect(() => {
       if (!isSignedIn) {
          dispatch(listenAuthState())
       }
   }, []);

      if (!isSignedIn) {
          return <></>
      } else {
          return children
      }
}

export default Auth