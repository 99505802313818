import React, { useEffect, useState } from "react";
import useStyle from "../header/styles";
import { Toolbar, Typography, Box, IconButton, Badge, Drawer } from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import { getUserId } from "../../redux/users/selectors";
import { db } from "../../firebase";
import { push } from 'connected-react-router';
import { signOut } from "../../redux/users/operations";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from '@material-ui/icons/Search';
import Logo from "../../assets/img/logo.png"

const HeaderMenu = (props) => {
    const classes = useStyle();
    const selector = useSelector((state) => state);
    const dispatch = useDispatch();
    const uid = getUserId(selector);
    const [favorite, setFavorite] = useState([])
    const [applications, setApplications] = useState([]);
    const [recruitments, setRecruitments] = useState([]);

    useEffect(() => {
        let posts = []
        db.collection('users').doc(uid).collection('companylist').get()
            .then(snapshots => {
                snapshots.docs.forEach(doc => {
                    const data = doc.data()
                    posts.push(data)
                })
                setFavorite(posts)
            })
    }, [favorite])
    useEffect(() => {
        let posts = []
        db.collection('users').doc(uid).collection('applications').get()
            .then(snapshots => {
                snapshots.docs.forEach(doc => {
                    const data = doc.data()
                    posts.push(data)
                })
                setApplications(posts)
            })
    }, [applications])
    useEffect(() => {
        let posts = []
        db.collection('users').doc(uid).collection('recruitments').get()
            .then(snapshots => {
                snapshots.docs.forEach(doc => {
                    const data = doc.data()
                    posts.push(data)
                })
                setRecruitments(posts)
            })
    }, [recruitments])

    return(
        <>
        <Toolbar className={classes.toolbar}>
           <Typography variant="h6" noWrap component="div" className={classes.logo} >
                <IconButton
                        aria-label="open drawer"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={(e) => props.searchDrawerToggle(e)}
                        className={classes.search}
                >
                        <SearchIcon/>
                </IconButton>
                    <div>
                        <img src={Logo} alt="ロゴ"/>
                    </div>
            </Typography>
            <Box display="flex">
                <Typography variant="h6" style={{paddingTop : 10}}>
                    <div className={classes.layout}>
                        <div className={classes.menuLg}>            
                            <Typography variant="p" className={classes.menu} onClick={() => dispatch(push('/chatlogin'))}>口コミ</Typography>            
                            <Typography variant="p" className={classes.menu} onClick={() => dispatch(push('/basicpro'))}>プロフィール</Typography>  
                            <Badge badgeContent={applications.length} color="secondary">
                                <Typography variant="p" className={classes.menu} onClick={() => dispatch(push('/yourapplications'))}>応募</Typography>     
                            </Badge>       
                            <Badge badgeContent={recruitments.length} color="secondary">
                                <Typography variant="p" className={classes.menu} onClick={() => dispatch(push('/yourrecruitments'))}>バイト中</Typography>   
                            </Badge>        
                            <Typography variant="p" className={classes.menu} onClick={() => dispatch(push('/contactpage/login'))}>お問い合わせ</Typography>            
                            <Typography variant="p" className={classes.menu} style={{paddingRight: 20}} onClick={() => dispatch(signOut())}>サインアウト</Typography>
                        </div>
                        <div className={classes.layout}>
                            <IconButton
                                aria-label="Menu Items"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={(e) => props.handleDrawerToggle(e, true)}
                                className={props.className}
                            >
                                <Badge badgeContent={favorite.length} color="secondary">
                                    <FavoriteBorderIcon />
                                </Badge>
                            </IconButton>
                            <IconButton
                                aria-label="Menu Items"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                color="inherit"
                                onClick={(e) => props.menuDrawerToggle(e)}
                                className={classes.menuSm}
                            >
                                <MenuIcon />
                            </IconButton>
                        </div>
                    </div>
                </Typography>
            </Box>
        </Toolbar>
        </>
    )
}

export default HeaderMenu;