import React from "react";
import { Card, CardActionArea, CardActions, CardContent, CardMedia, Button, Typography, makeStyles } from "@material-ui/core";
import FavoriteIcon from '@material-ui/icons/Favorite';
import NoImage from '../../assets/img/no_image.png'
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { useCallback } from "react";
import { FirebaseTimeStamp } from "../../firebase";
import { addCompanyCard } from "../../redux/users/operations";
import { useState } from "react";
import { getIsSignedIn } from "../../redux/users/selectors";

const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: 360,
      margin: 10
    },
    image: {
        objectFit: "cover",
        width: "100%",
        height: 200
    },
    bookMark: {
        marginRight: 5,
        cursor: "pointer",
        color: "red",
        '&:hover': {
            opacity: 0.6,
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 5
        }
    },
    detailsbutton: {
        color: "#fff",
        padding: "3px 10px",
        borderRadius: 15,
        backgroundColor: "#FF9D00",
        width: "fit-content",
        fontSize: 14,
        cursor: "pointer",
        '&:hover': {
            opacity: 0.7,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 12
        }
    },
  }));
const SanparkListItem = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);
    const isSignedIn = getIsSignedIn(selector)
    const [selectedCompany, setSelectedCompany] = useState(null)
    const sanpark = props.sanpark
    const images = props.sanpark.images.length === 0 ? (
                        NoImage
                    ) : (
                            props.sanpark.images[0].path
                    )

    const addedCompany = useCallback(() => {
        const timestamp = FirebaseTimeStamp.now();

        dispatch(addCompanyCard({
            added_at: timestamp,
            companyName: sanpark.companyName,
            message: sanpark.message,
            images: sanpark.images,
            partTime: sanpark.partTime,
            introduce: sanpark.introduce,
            saraly: sanpark.saraly,
            place: sanpark.place,
            category: sanpark.category,
            companyid: sanpark.companyid,
        }))
    }, [selectedCompany])
    return(
        <Card className={classes.root}>
            <CardActionArea>
                <CardMedia
                component="img"
                alt="店舗写真"
                image={images}
                className={classes.image}
                />
                <CardContent>
                <Typography gutterBottom variant="h6" component="h3" align="left">
                    {sanpark.companyName}
                </Typography>
                <Typography variant="body2" color="textSecondary" align="left" component="p">
                    {sanpark.introduce}
                </Typography>
                <Typography variant="body2" color="textSecondary" align="left" component="p">
                    {"時給 " + sanpark.saraly + "円~"}
                </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                {isSignedIn && (
                        <>
                        <p size="small" className={classes.detailsbutton} onClick={() => dispatch(push('/companydetaillogin/' + sanpark.companyid))}>
                            詳細を見る
                        </p>
                        <Button size="small" onClick={() => addedCompany()} className={classes.bookMark} >
                            <FavoriteIcon />
                        </Button>  
                    </>
                )}           
                {!isSignedIn && (
                    <p size="small" className={classes.detailsbutton} onClick={() => dispatch(push('/companydetail/' + sanpark.companyid))}>
                      詳細を見る
                    </p>
                )}     
            </CardActions>
        </Card>
    )
}

export default SanparkListItem;