import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NotSignInHeader from "../components/header/NotSignInHeader";
import { db } from "../firebase";
import { getUserId } from "../redux/users/selectors";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    flex: {
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            flexFlow: "column"
        }
    },
}))

const RetirementCompany = () => {
    const classes = useStyles();
    const [recruitment, setRecruitment] = useState(null)
    const selector = useSelector((state) => state);
    const uid = getUserId(selector)
    const path = selector.router.location.pathname
    const id = path.split('/retirecompany/')[1]

    useEffect(() => {
        db.collection('users').doc(uid).collection('recruitments').doc(id)
            .get()
            .then(snapshot => {
                const data = snapshot.data();
                setRecruitment(data)
            })
    }, [])

    const daysMonth = 365 /12;
    const daysYear = 365;
    const currentData = Date.now()
    const addedData = recruitment && recruitment.added_at.toDate()
    const timestamp = currentData - addedData
    let elapsedDay  = Math.floor( timestamp / 86400000 );
    const elapsedYear =  Math.floor( elapsedDay / daysYear );
    elapsedDay -= elapsedYear * 365;
    const elapsedMonth = Math.floor( elapsedDay / daysMonth );
    elapsedDay -= Math.floor(elapsedMonth * daysMonth);
    return(
        <div>       
            <NotSignInHeader />
            <div className="module-spacer--medium" />
            <div className="module-spacer--medium" />
            <div className="module-spacer--medium" />
            {recruitment && (
                <div>
                <h2>現在勤務している店舗 : {recruitment.companyName}</h2>
                    <ul className={classes.flex}>
                        <li>採用期間 : </li>
                        <li>{elapsedYear !== 0 && elapsedYear + "年"}</li>
                        <li>{elapsedMonth !== 0 && elapsedMonth + "ヶ月"}</li>
                        <li>{elapsedDay !== 0 && elapsedDay + "日"}</li>
                    </ul>  
                </div>
            )}      
        </div>
    )
}

export default RetirementCompany;