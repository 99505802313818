import { db } from "../../firebase"
import { fetchCompanysAction, fetchSanparkAction, fetchYumetownAction } from "./actions";

const companysRef = db.collection('companys')

export const fetchCompanys = () => {
    return async(dispatch) => {
        companysRef.get()
         .then(snaphots => {
             const companyList = [];
             snaphots.forEach(snaphot => {
                 const company = snaphot.data()
                 companyList.push(company)
             })
             dispatch(fetchCompanysAction(companyList))
         })
    }
}

export const fetchSanpark = () => {
    return async(dispatch) => {
        const list = []
        companysRef
        .where('complex', '==', '小野田サンパーク')
        .get()
        .then((docs) => {
            docs.forEach(doc => {
                const data = doc.data();
                list.push(data)
            })
            dispatch(fetchSanparkAction(list));
        })
    }
}
export const fetchYumetown = () => {
    return async(dispatch) => {
        const list = []
        companysRef
        .where('complex', '==', '宇部ゆめタウン')
        .get() 
        .then((snapshots) => {
            snapshots.forEach(snaphot => {
                const data = snaphot.data();
                list.push(data)
                })
                dispatch(fetchYumetownAction(list))
            })
    }
}
                
