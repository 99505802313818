// 第一引数にstate、第２引数にactionsがreturnした値
import * as Actions from './actions';
import { initialState } from '../stores/initialState';

export const CompanyReducer = (state = initialState.companys, action) => {
        switch (action.type) {
           case Actions.FETCH_COMPANYS:
               return {
                   ...state,
                   companyinfo: [...action.payload]
               }
           case Actions.FETCH_SANPARK:
               return {
                   ...state,
                   sanpark: [...action.payload]
               }
           case Actions.FETCH_YUMETOWN:
               return {
                   ...state,
                   yumetown: [...action.payload]
               }
            default:
                return state
        }
}
