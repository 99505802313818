import React from 'react';
import {Route, Switch} from 'react-router'
import SignUp from './templates/SignUp';
import Home from './templates/Home'
import SignIn from './templates/SignIn';
import Auth from './Auth';
import Reset from './templates/Reset';
import BasicPro from './templates/BasicPro';
import Map from './components/maps/Map/Map';
import SampleMap from "./components/sampleMap/sampleMap/SampleMap";
import CompanyDetails from './templates/companydetails/CompanyDetails';
import CompanyDetailsLogin from './templates/companydetails/CompanyDetailsLogin';
import ResetPage from './templates/RestPage';
import MailInit from './templates/mails/MailInit';
import Mail from './templates/mails/Mail';
import MailConfirm from './templates/mailConfirm/mailConfirm';
import TermOfService from './templates/TermOfService';
import PrivasyPolicy from './templates/PrivasyPolicy';
import Promotion from './templates/Promotion';
import Contact from './templates/Contact';
import MailComplete from './templates/mailConfirm/MailComplete';
import ChatLogin from './templates/ChatLogin';
import ApplicationsHistory from './templates/ApplicationsHistory';
import ContactCompany from './templates/ContactToCompany';
import Sanpark from './templates/complex/Sanpark';
import Yumetown from './templates/complex/Yumetown';
import PrivacyStatements from './templates/PrivacyStatements';
import PoricyTermPage from './templates/PoricyTermPage';
import CompanyOverview from './templates/CompanyOverview'
import ContactCompanyInteview from './templates/ContactCompanyInteview';
import Recruitment from './templates/Recruitment';
import WorkCompany from './templates/WorkCompany';
import RetirementCompany from './templates/RetirementCompany';

const Router = () => {
    return(
       <Switch>
            <Route exact path={"(/)?"} component={Home} />
            <Route exact path={"/signup"} component={SignUp} />
            <Route exact path={"/signin"} component={SignIn} />
            <Route exact path={"/signin/reset"} component={Reset} />
            <Route exact path={"/signin/resetpage"} component={ResetPage} />
            <Route exact path={"/samplemap"} component={SampleMap} />
            <Route exact path={"/termofservice"} component={TermOfService} />
            <Route exact path={"/privasypolicy"} component={PrivasyPolicy} />
            <Route exact path={"/privasystatements"} component={PrivacyStatements} />
            <Route exact path={"/poricyandtermpage"} component={PoricyTermPage} />
            <Route exact path={"/promotion"} component={Promotion} />
            <Route exact path={"/contactpage"} component={Contact} />
            <Route exact path={"/companyOverview"} component={CompanyOverview} />
            <Route exact path={"/mailinit/mailform/:id"} component={MailInit} />
            <Route path={"/companydetail/:id"} component={CompanyDetails} />
            <Route exact path={"/sanparkshopsguest"} component={Sanpark} />
            <Route exact path={"/yumetownshopsguest"} component={Yumetown} />
            <Auth>                
                <Route exact path={"/retirecompany/:id"} component={RetirementCompany} />
                <Route exact path={"/workcompany/:id"} component={WorkCompany} />
                <Route exact path={"/yourrecruitments"} component={Recruitment} />
                <Route exact path={"/yourapplications"} component={ApplicationsHistory} />
                <Route exact path={"/chatlogin"} component={ChatLogin} />
                <Route exact path={"/contactpage/login"} component={Contact} />
                <Route exact path={"/mailcomplete"} component={MailComplete} />
                <Route exact path={"/contactcompany/:id"} component={ContactCompany} />
                <Route exact path={"/contactcompanyinteview/:id"} component={ContactCompanyInteview} />
                <Route exact path={"/mailconfirm/:id"} component={MailConfirm} />
                <Route exact path={"/companydetails/mailform/:id"} component={Mail} />
                <Route exact path={"/mappage"} component={Map} />
                <Route exact path={"/sanparkshops"} component={Sanpark} />
                <Route exact path={"/yumetownshops"} component={Yumetown} />
                <Route path={"/companydetaillogin/:id"} component={CompanyDetailsLogin} />
                <Route path={"/basicpro"} component={BasicPro} />
            </Auth>
       </Switch>
   )
}

export default Router;