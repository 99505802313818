import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NotSignInHeader from "../components/header/NotSignInHeader";
import { db } from "../firebase";
import { getUserId } from "../redux/users/selectors";
import RecruitmentItem from "../components/companys/RecruitmentItem";
import { List, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    orderList: {
        background: theme.palette.grey["100"],
        margin: '0 auto',
        padding: 20,
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        [theme.breakpoints.up('md')]: {
            width: 768
        }
    },
}))

const Recruitment = () => {
    const classes = useStyles();
    const selector = useSelector((state) => state);
    const uid = getUserId(selector)
    const [recruitments, setRecruitments] = useState([]);

    useEffect(() => {
        let list = []
        db.collection('users').doc(uid).collection('recruitments').get()
            .then(snapshots => {
                snapshots.docs.forEach(doc => {
                    const data = doc.data()
                    list.push(data)
                })
                setRecruitments(list)
            })
    }, [recruitments])

    return(
        <div>
            <NotSignInHeader />
            <div className="module-spacer--medium" />
            <div className="module-spacer--medium" />
            <div className="module-spacer--medium" />
            <List className={classes.orderList}>
                {recruitments.length > 0 && (
                    recruitments.map(recruitment => <RecruitmentItem key={recruitment.id} recruitmentsId={recruitment.recruitmentsId} recruitment={recruitment} />)
                )}
            </List>
        </div>
    )
}

export default Recruitment;