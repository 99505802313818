import { Button, makeStyles } from '@material-ui/core';
import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addBasicPro } from '../redux/users/operations';
import SelectBox from '../UIkit/SelectBox';
import { db, auth } from '../firebase';
import { getUserId } from '../redux/users/selectors';
import { TextField } from '@material-ui/core';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, RadialLinearScale } from "chart.js";
import { Radar } from "react-chartjs-2";
import NotSignInHeader from '../components/header/NotSignInHeader';
import FooterVar2 from '../components/footer/footer_ver2';
import { useContext } from 'react';
import { multiStateContext } from '../StateContext';
Chart.register(CategoryScale, LinearScale, PointElement, LineElement, RadialLinearScale, Title)

const usestyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down('sm')]: {
        flexFlow: "column"
      }
    },
    form: {
        width: 400,
        marginRight: 100,
        [theme.breakpoints.down('sm')]: {
          width: "100%"
        }
      },
    link: {
      color: "blue",
      cursor: "pointer"
    },
    companyName: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: 20
    },
    flex: {
      display: "flex",
      justifyContent: "center",
    },
    nessesary: {
      color: "red",
      paddingLeft: 10
    },
    field: {
      display: "flex",
      flexFlow: "column",
      alignItems: "center",
      margin: "0 auto",
      alignItems: "flex-start",
      width: 400,    
      [theme.breakpoints.down('sm')]: {
        width: "90%",
    }  
    },
    gender: {
      width: 200,
      display: "flex",
      flexFlow: "column",
      alignItems: "flex-start"
    },
    button: {
      padding: "5px 50px",
      fontSize: 14,
      color: "#707070",
      backgroundColor: "#fff",
      border: "1px solid #707070",
      borderRadius: 5,
      [theme.breakpoints.down('sm')]: {
          fontSize: 10
      },
      '&:hover': {
          background: "#EEEEEE",
      },
    },
    space_big:{
        height: 50,
    },
    space_small: {
        height: 30,
    },
    birthday: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    skilroot: {
      width: 400,
      [theme.breakpoints.down('sm')]: {
        width: "90%",
        margin: "0 auto"
      }  
    },
    skils: {
      width: 80,
      [theme.breakpoints.down('sm')]: {
        width: 50
      }
    },
    skilfield: {
      display: "flex",
    },
    skiltable: {
      textAlign: "left"
    },
    th: {
      width: 200,
      [theme.breakpoints.down('sm')]: {
        width: 150
      }
    },
    td: {
      width: 80,
      [theme.breakpoints.down('sm')]: {
        width: 50
      }
    },
    skilexe: {
      textAlign: "left",
      fontSize: 14,
      [theme.breakpoints.down('sm')]: {
        fontSize: 12
      }
    }
}))

const BasicPro = () => {
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);
    const classes = usestyles();
    const {
      firstReading, setFirstReading, lastReading, setLastReading, firstName, setFirstName, lastName, setLastName, email, setEmail, tel, setTel,
      addressNum, setAddressNum, address, setAddress, housenumber, setHousenumber, age, setAge, department, setDepartment, grade, setGrade,
      one, setOne, two, setTwo, three, setThree, four, setFour, five, setFive, itemone, setItemone, itemtwo, setItemtwo, itemthree, setItemThree, itemfour, setItemfour, itemfive, setItemfive,
      inputFirstReading, inputLastReading, inputFirstName, inputLastName, inputEmail, inputTel, inputAddress, inputHouseNumber, inputAddressNum, inputAge,
      inputItemone, inputItemtwo, inputItemthree, inputItemfour, inputItemfive,
    } = useContext(multiStateContext)
    const departments = [
        {id: "engineering", name: "工学部"},
        {id: "pharmacy", name: "薬学部"}
    ]
    const grades = [
        {id: "first", name: "1年生"},
        {id: "second", name: "2年生"},
        {id: "third", name: "3年生"},
        {id: "fourth", name: "4年生"},
        {id: "fifth", name: "5年生"},
        {id: "sixth", name: "6年生"},
    ]
    const numbers = [
        {id: "first", name: "1"},
        {id: "second", name: "2"},
        {id: "third", name: "3"},
        {id: "fourth", name: "4"},
        {id: "fifth", name: "5"},
    ]
    useEffect(() => {
        auth.onAuthStateChanged(() => {
                const uid = getUserId(selector)
                db.collection('users').doc(uid).get()
                 .then(snapshot => {
                    const data = snapshot.data();
                    setFirstName(data.firstName)
                    setLastName(data.lastName)
                    setFirstReading(data.firstReading)
                    setLastReading(data.lastReading)
                    setEmail(data.email);
                    setTel(data.tel);
                    setAddressNum(data.addressNum);
                    setAddress(data.address);        
                    setHousenumber(data.housenumber)    
                    setAge(data.age)
                    setGrade(data.grade);
                    setDepartment(data.department);
                    setOne(data.one);
                    setTwo(data.two);
                    setThree(data.three);
                    setFour(data.four);
                    setFive(data.five);
                    setItemone(data.itemone);
                    setItemtwo(data.itemtwo);
                    setItemThree(data.itemthree);
                    setItemfour(data.itemfour);
                    setItemfive(data.itemfive)
            }) 
        })
    }, []);
      const data = {
        labels: [itemone, itemtwo, itemthree, itemfour, itemfive],
        datasets: [{
            data: [
              parseInt(one), parseInt(two), parseInt(three), parseInt(four), parseInt(five), 
            ],
            backgroundColor: [
                "red",
                "blue",
                "yellow",
                "green",
                "purple",
                "orange"
            ]
        }]
      }
    const options = {
      responsive: true,
      animation: {
        duration: 3000,
      },
      scales: {
        r: {
          min: 0,
          max: 5,
          backgroundColor: 'lightyellow',
          // グリッドライン
          grid: {
            color: 'lightseagreen',
          },
          // アングルライン
          angleLines: {
            color: 'brown',
          },
          // ポイントラベル
          pointLabels: {
            color: 'blue',
            width: "max-content",
            backdropColor: '#ddf',
            backdropPadding: 5,
          },
        },
      },
    }
    return(
        <div>
        <NotSignInHeader />
        <div className="module-spacer--medium" />         
        <div className="module-spacer--medium" />    
        <div className={classes.root}>
        <div className={classes.form}>
            <div className="module-spacer--extra-small" />        
            <div className={classes.field}>
              <p style={{float: "left"}}>お名前</p>
              <div className={classes.flex}>
                <TextField
                variant="outlined" type="text" name="firstName" margin="dense" placeholder="山田" 
                multiline={true} value={firstName} onChange={inputFirstName} required={true}
                />
                <div style={{paddingRight: 10}} />
                <TextField
                variant="outlined" type="text" name="lastName" margin="dense" placeholder="太郎" 
                multiline={true} value={lastName} onChange={inputLastName} required={true}
                />
              </div>
            </div>   
            <div className={classes.field}>
              <p style={{float: "left"}}>お名前(ふりがな)</p>
              <div className={classes.flex}>
              <TextField
                variant="outlined" type="text" name="firstName" margin="dense" placeholder="やまだ"
                multiline={true} fullWidth={true} value={firstReading} onChange={inputFirstReading}
                />
                <div style={{paddingRight: 10}} />
                <TextField
                variant="outlined" type="text" name="lastName" margin="dense" placeholder="太郎" 
                multiline={true} fullWidth={true} value={lastReading} onChange={inputLastReading}
                />
              </div>
              <div className={classes.gender}>
                <p>学年</p>               
                <SelectBox
                  options={grades} required={false} value={grade} 
                  select={setGrade}
                />                       
                <p>学科</p>
                <SelectBox
                  options={departments} required={false} value={department} 
                  select={setDepartment}
                />    
                <p>年齢</p>       
                <div style={{display: "flex", alignItems: "center"}}>
                <TextField
                  variant="outlined" type="text" name="tel" margin="dense" value={age} 
                  multiline={true} onChange={inputAge} placeholder="19" required={true}
                />歳
                </div> 
              </div>
              <p>メールアドレス</p>
              <TextField
                variant="outlined" type="email" name="email" margin="dense" placeholder="example@test.com"
                multiline={true} fullWidth={true} value={email} onChange={inputEmail} required={true}
              />
               <p>電話番号</p>
              <TextField
                variant="outlined" type="text" name="tel" margin="dense" value={tel} 
                multiline={true} onChange={inputTel} placeholder="000-0000-0000" required={true}
              />    
              <p>住所</p>
              <div style={{display: "flex", alignItems: "center"}}>
              〒<TextField
                variant="outlined" type="text" name="adressNum" margin="dense" value={addressNum}
                multiline={true} onChange={inputAddressNum} placeholder="000-0000" required={true}
              />  
              </div>                                                                               
              <TextField
                variant="outlined" type="text" name="adress" margin="dense" value={address} placeholder="山陽小野田市"
                multiline={true} fullWidth={true} onChange={inputAddress} required={true}
              />                       
              <TextField
                variant="outlined" type="text" name="adress" margin="dense" value={housenumber} placeholder="大学通1丁目1-1"
                multiline={true} fullWidth={true} onChange={inputHouseNumber} required={true}
              />                       
            </div>                                                                                                                                                                    
          </div>
          <div className={classes.skilroot}>
          <div className="module-spacer--extra-small" />  
            <Radar data={data} options={options} style={{width: 150, height: "auto"}}/>
            <div className={classes.skilfield}>
            <table style={{textAlign: "left"}} className={classes.skiltable}>
              <tr>
                <th className={classes.th}><TextField variant='outlined' margin='dense' value={itemone} onChange={inputItemone} placeholder="長所1" /></th>
                <td className={classes.td}><SelectBox options={numbers} value={one} select={setOne} className={classes.skils} /></td>
              </tr>
              <tr>
                <th className={classes.th}><TextField variant='outlined' margin='dense' value={itemtwo} onChange={inputItemtwo} placeholder="長所2" /></th>
                <td className={classes.td}><SelectBox options={numbers} value={two} select={setTwo} className={classes.skils} /></td>
              </tr>
              <tr>
                <th className={classes.th}><TextField variant='outlined' margin='dense' value={itemthree} onChange={inputItemthree} placeholder="長所3" /></th>
                <td className={classes.td}><SelectBox options={numbers} value={three} select={setThree} className={classes.skils} /></td>
              </tr>
              <tr>
                <th className={classes.th}><TextField variant='outlined' margin='dense' value={itemfour} onChange={inputItemfour} placeholder="長所4" /></th>
                <td className={classes.td}><SelectBox options={numbers} value={four} select={setFour} className={classes.skils} /></td>
              </tr>
              <tr>
                <th className={classes.th}><TextField variant='outlined' margin='dense' value={itemfive} onChange={inputItemfive} placeholder="長所5" /></th>
                <td className={classes.td}><SelectBox options={numbers} value={five} select={setFive} className={classes.skils} /></td>
              </tr>
            </table>
            </div>
            <p>※1~5段階評価</p>
          </div>
          </div>
            <div className="module-spacer--medium" /> 
            <button className={classes.button} onClick={() => dispatch(addBasicPro(firstName, lastName, firstReading, lastReading, email, tel, addressNum, address, housenumber, grade, department, age, one, two, three, four, five, itemone, itemtwo, itemthree, itemfour, itemfive ))} variant="primary">変更</button>
            <div className="module-spacer--medium" />      
            <FooterVar2 />
        </div>
    )
}

export default BasicPro;
