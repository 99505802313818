export const firebaseApp = {
  apiKey: "AIzaSyBhoy-57PrkK4Tlrgs1oqn0CeicwfVhRDw",
  authDomain: "socubaito.firebaseapp.com",
  projectId: "socubaito",
  storageBucket: "socubaito.appspot.com",
  messagingSenderId: "536027863998",
  appId: "1:536027863998:web:d15edc0978ba286ec737ef",
  measurementId: "G-TLJ1G4228J"
};
// export const firebaseApp = {
//     apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//     authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//     databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
//     projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
//     appId: process.env.REACT_APP_FIREBASE_APP_ID,
//     measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
// }
