import React, { useState, useCallback } from "react";
import { Card, CardActionArea, CardActions, CardContent, CardMedia, Button, Typography, makeStyles } from "@material-ui/core";
import FavoriteIcon from '@material-ui/icons/Favorite';
import NoImage from '../../assets/img/no_image.png'
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { FirebaseTimeStamp } from "../../firebase";
import { addCompanyCard } from "../../redux/users/operations";
import { getIsSignedIn } from "../../redux/users/selectors";

const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: 360,
      margin: 10
    },
    image: {
        objectFit: "cover",
        width: "100%",
        height: 200
    },
    bookMark: {
        marginRight: 5,
        cursor: "pointer",
        color: "red",
        '&:hover': {
            opacity: 0.6,
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 5
        }
    },
    detailsbutton: {
        color: "#fff",
        padding: "3px 10px",
        borderRadius: 15,
        backgroundColor: "#FF9D00",
        width: "fit-content",
        fontSize: 14,
        cursor: "pointer",
        '&:hover': {
            opacity: 0.7,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 12
        }
    },
  }));

const YumetownListItem = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);
    const isSignedIn = getIsSignedIn(selector);
    const [selectedCompany, setSelectedCompany] = useState(null)
    const yumetown = props.yumetown
    const images = props.yumetown.images.length === 0 ? (
                        NoImage
                    ) : (
                            props.yumetown.images[0].path
                    )

    const addedCompany = useCallback(() => {
        const timestamp = FirebaseTimeStamp.now();

        dispatch(addCompanyCard({
            added_at: timestamp,
            companyName: yumetown.companyName,
            message: yumetown.message,
            images: yumetown.images,
            partTime: yumetown.partTime,
            introduce: yumetown.introduce,
            saraly: yumetown.saraly,
            place: yumetown.place,
            category: yumetown.category,
            companyid: yumetown.companyid,
        }))
    }, [selectedCompany])
    return(
        <Card className={classes.root}>
        <CardActionArea>
            <CardMedia
            component="img"
            alt="店舗写真"
            image={images}
            className={classes.image}
            />
            <CardContent>
            <Typography gutterBottom variant="h6" component="h3" align="left">
                {yumetown.companyName}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" align="left">
                {yumetown.introduce}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" align="left">
                {"時給 " + yumetown.saraly + "円~"}
            </Typography>
            </CardContent>
        </CardActionArea>
        <CardActions>
            {isSignedIn && (
                <>
                    <p size="small" className={classes.detailsbutton} onClick={() => dispatch(push('/companydetaillogin/' + yumetown.companyid))}>
                        詳細を見る
                    </p>
                    <Button size="small" onClick={() => addedCompany()} className={classes.bookMark} >
                        <FavoriteIcon />
                    </Button>  
                </>
                )}           
            {!isSignedIn && (
                <p size="small" className={classes.detailsbutton} onClick={() => dispatch(push('/companydetail/' + yumetown.companyid))}>
                    詳細を見る
                </p>
            )}     
        </CardActions>
    </Card>
    )
}

export default YumetownListItem;