import React from "react";
import { makeStyles } from "@material-ui/core";
import { AppBar, Toolbar, Typography, Box, Paper } from '@material-ui/core';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import MenuDrower from "../components/header/MenuDrower";
import NotSignHeaderMenu from "../components/header/NotSignHeaderMenu";
import { useState, useCallback} from "react";
import Logo from '../assets/img/logo.png';
import FooterVar2 from '../components/footer/footer_ver2'

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: "#F5F5F5"
    },
    subject: {
        fontSize: 20,
        textAlign: "left",
        marginLeft: 240,
        marginTop: 75,
        fontWeight: 600,
        color: "#707070",
        [theme.breakpoints.down('sm')]: {
            marginLeft: 20
        }
    },
    subject2: {
        fontSize: 20,
        textAlign: "left",
        marginLeft: 240,
        fontWeight: 400,
        color: "#707070",
        [theme.breakpoints.down('sm')]: {
            marginLeft: 20
        }
    },
    logo: {
        width: 270,
        [theme.breakpoints.down('sm')]: {
          width: "60%"
        }
      },
      title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
          display: 'block',
        },
      },
      toolbar: {
        display: 'flex', 
        justifyContent: 'space-between',
        borderBottom: "solid 2px #FF9D00",
        backgroundColor: "#F5F5F5",
        height: 70
      },
      appBar: {
        zIndex: theme.zIndex.drawer + 1,
        width: "100%",
        position: "fixed"
      },
      page :{
        width: 950,
        margin: "0 auto",
        padding: "40px 50px 70px 50px",
        marginTop: 20,
        backgroundColor: "fff",
        [theme.breakpoints.down('sm')]: {
            width: 300,
        }
      },
      paragrah: {
          textAlign: "left",
      },
      h3: {          
          fontSize: 14,
          color: "#000000"
      },
      section: {
          lineHeight:"24px",
          color: "#707070",
          fontSize: 14
      },
      margin: {
        paddingTop: 70
      },
      marginP: {
        marginTop: 24
      },
}))
const PrivacyStatements = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [menuOpen, setMenuOpen] = useState(false);
    const menuDrawerToggle = useCallback((event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
          }
        setMenuOpen(!menuOpen)
    }, [setMenuOpen, menuOpen]);
    return(
        <div className={classes.background}>
        <div className={classes.root}>
        <header className={classes.appBar} >
            <Toolbar className={classes.toolbar}>
                <Typography variant="h6" noWrap component="div" className={classes.logo} onClick={() => dispatch(push('/'))} >
                    <img src={Logo} alt="ロゴ" onClick={() => dispatch(push('/'))}/>
                </Typography>
                <Box display="flex" style={{marginTop: 30}}>
                    <Typography variant="h6" >
                        <NotSignHeaderMenu menuDrawerToggle={menuDrawerToggle}/>
                    </Typography>
                </Box>
            </Toolbar>
            <MenuDrower open={menuOpen} onClose={menuDrawerToggle} />
        </header>
        </div>
        <div className={classes.margin} />
        <h2 className={classes.subject}>プライバシーステートメント</h2>
        <Paper className={classes.page} elevation={0}>
            <div className={classes.paragrah}>
                <section>
                    <p className={classes.section}> 
                        このプライバシーステートメント(以下ステートメントという)は、学生アパート情報センター(日本市場)、
                        及びこのステートメントに閲覧者や利用者を誘導する合同会社住宅サポートによって所有及び運営されているその他全てのウェブサイトに適用されます。このプライバシーステートメントにおいて、
                        “学生アパート情報センター”、“当社は”、“当社の”は、合同会社住宅サポート、山口東京理科大学学生アパート情報センター、それぞれの子会社、関連会社を指すものとします。
                        当社によって所有及び運営されているウェブサイトは他の企業によって所有及び運営されているウェブサイトへのリンクを含むことができます。
                        このプライバシーステートメントは第三者によって所有及び運営されているウェブサイトやサービスには適用されません。
                    </p>
                </section>
                <div className={classes.marginP} />
                <section>
                    <h3 className={classes.h3}>1.当社が収集する情報</h3>
                    <p className={classes.section}>
                    当社のサービスを利用する際に、当社は貴方及び貴方が利用するサービスに関する特定の情報を収集します。当社が収集するその情報は3つの異なるカテゴリに分類されます。<br/>
                    (1)貴方が当社に提供する情報、(2)貴方が当社のサービスを利用するときに自動的に貴方から収集する情報、(3)他の情報源から当社が収集する情報<br/>
                    <br />
                    貴方が当社に提供する情報<br />
                    貴方が当社のサービスを利用するときに、当社は貴方が当社に提供する情報を収集します。
                    当社の“サービス”を利用することの例としては、当社のウェブサイトの一つを閲覧すること、
                    学生個々のアカウントを作ること、アンケートやプロモーションに参加することが該当します。
                    貴方が当社に提供する情報には、貴方の名前、苗字、ユーザーネーム、パスワード、メールアドレス、郵便番号、電話番号のような金融口座情報、誕生日、出生地、人口統計情報、
                    その他貴方が当社に提供すると選択した情報を含めることができます。<br />
                    <br />
                    貴方が当社のサービスを利用するときに、当社が収集する情報<br />
                    貴方が当社のサービスを利用するときに、当社は貴方が利用するサービス及びそれらの利用方法に関する情報を収集することができます。この情報には次の情報を含みます。
                    購買に関する情報−当社は、アパートの場所、アパートの条件、及び入居選択によって貴方が獲得した利益に関する情報を含む、貴方が選択したアパートに関する情報を収集することができます。<br />
                    この情報はデバイス、貴方のオペレーションシステム、貴方のブラウザ(例えば、貴方がインターネットエクスプローラー、ファイヤーフォックス、サファリ、クローム、その他ブラウザを利用していたかどうか)、
                    貴方のインターネットサービスプロバイダー、貴方のドメインネーム、貴方のインターネットプロトコル(IP)アドレス、貴方のデバイス識別子(又はUDID)、
                    当社のサービスにアクセスした日時、当社のウェブサイトにアクセスするために貴方が参照したウェブサイト、貴方が要求したウェブページ、それら要求の日時、
                    貴方がクリック又はスクロールした広告対象の種類を含むことができます。この情報を収集するため、当社はクッキー、ウェブビーコン、及びこれらに類似する技術を利用します。
                    下記の “クッキー、ウェブビーコン、類似する技術”と題するセクションをご確認ください。。<br />
                    位置情報−貴方が当社のサービスにアクセスするためにスマートフォン又は他のモバイルデバイスを利用するとき、当社のウェブサイトにアクセスするためにコンピューターを利用するとき、
                    又は当社のモバイルアプリケーション内にある物件検索サービスを利用するときは、当社は貴方の物理的位置情報を収集することができます。<br />
                    当社は、貴方の探すアパートに関する情報を提供したり、貴方のモバイルデバイス上で他のサービスを提供するために、
                    他の位置(貴方のIPアドレスと請求書情報又は郵便番号のような情報に基づく情報)にこの情報を組み合わせることができます。
                    当社は、貴方の位置情報について、貴方にサービスを提供することを支援する企業やどのようにサービスが利用されているか把握することを支援する企業とのみ共有します。
                    貴方がこの位置情報の収集についてオプトアウトを希望する場合には、下記の“貴方の選択”と題するセクションをご確認ください。<br />
                    店内カメラの情報−当社は、店内において従業員と貴方の安全を維持し、当社のカスタマーエクスペリエンスを向上する方法を見つけ出すために、
                    店内カメラを利用します。当社はセキュリティ目的の必要性があるときを除きこれらのカメラを個人を特定するために利用しません。<br />
                    <br />
                    貴方が当社のサービスを利用するときに、当社が収集する情報<br />
                    <br />
                    当社は、公的に入手可能な情報についても収集します。例えば、当社は貴方がブログ、チャットルーム、フェイスブック、ツイッター、グーグルプラスのようなソーシャルネットワークに投稿した情報を収集することができます。
                    当社は他の会社や組織から貴方に関する情報を収集することもできます。貴方に関する追加情報を蓄積させることにより、当社は不正確な情報を訂正し、
                    貴方に対して貴方がより興味があると思われる商品提案や特別なオファーを提供することができます。<br />   
                    </p>
                </section>                 
                <div className={classes.marginP} />                
                <section>
                    <h3 className={classes.h3}>2.当社が収集する情報を当社が利用する方法</h3>   
                    <p className={classes.section}>
                    当社は、貴方について当社が収集する情報を次に掲げる方法を含む様々な方法で利用することができます。<br />
                    商品やサービスのご購入を処理するため商品やサービスのご購入を処理するため貴方のカスタマーサービスに関する質問や要求に返信するため<br />
                    当社のウェブサイト上に貴方のコメントや意見を掲載するため<br />
                    個別のプロモーションや特別なオファーを貴方に送付するため<br />
                    当社のブランド、商品、イベント、その他プロモーションに関して貴方にお知らせするため<br />
                    当社が提供するサイト、商品、サービスを維持し、向上させるため<br />
                    セキュリティ違反や不法行為を検出し、防止し、又は調査するため<br />
                    社内事務上の目的にて適切な記録を維持するため<br />
                    </p>
                </section>  
                <div className={classes.marginP} />
                <section>
                    <h3 className={classes.h3}>3.当社が収集する情報を当社が共有する方法</h3>   
                    <p className={classes.section}>
                    当社は、下記状況において貴方の情報を共有することができます。<br />
                    連携して業務を行なうとき−当社は、提供している当社の商品とサービスの拡張及び販促、
                    注文や顧客要求の実現に関する判断を含む、適切な管理及び分析並びに意思決定をするため、
                    並びにこのステートメントで説明する他の目的で合同会社住宅サポート、山口東京理科大学学生アパート情報センター、
                    それぞれの子会社、関連会社が利用するために、これら企業との間で収集した情報を共有することができます。<br />
                    サービスプロバイダーと連携して業務を行なうとき−当社は、クレジットカード処理、ウェブサイトの保守、メール配信、ロケーションマッピング、
                    郵便処理及び配送、並びに分析サービスのようなサポート業務を当社に提供するサービスプロバイダーとの間で貴方の情報を共有することができます。
                    当社は、これらの企業に対して、当社のために業務遂行するとき、又は法令により貴方の情報を開示することを要求されたときを除き、貴方の情報を収集し、利用し、
                    開示することを控えるように要求します。<br />
                    それぞれの子会社、関連会社が利用するために、これら企業との間で収集した情報を共有することができます。<br />
                    事業譲渡等に取り組むとき−合併又は事業資産の全部若しくは一部の譲渡を伴う別の状況に関わることになった場合には、
                    当社は貴方の情報をその交渉又は譲渡に関係する事業者又は個人に貴方の情報を共有することができます。<br />
                    共有することが法的利益の保護に役立つとき−開示することが法の要請であると判断する場合、開示することが当社の契約やポリシーを実行するために必要であると判断する場合、
                    又は開示することが当社、貴方、パートナー(従業員)の権利や財産、安全の保護に役立つと判断する場合には、当社は貴方の情報を開示することができます。
                    マーケティング目的で他の企業と共有するとき−当社は販促や特別なオファーを貴方に提供するために貴方の情報を利用するサービスプロバイダーと貴方の情報を共有することができます。
                    当社は貴方の当社のウェブサイトやサービス利用に関する匿名の集約された統計を他の企業と共有することもできます。<br />
                    貴方が同意するとき−貴方が貴方に関する情報を共有することについて当社に許可又は指示を与える場合には、当社はその情報を他の企業に対して共有することができます。
                    情報が貴方を識別しないとき−当社は貴方を直接的に識別しない方法によって貴方の情報を共有することができます。例えば、当社は貴方に関する情報を他の個人に関する情報と組み合わせ、
                    貴方の情報を貴方と結び付けない方法にて当該組み合わせた情報を共有することができます。<br />
                    貴方が当社のウェブサイトに投稿するとき−貴方がブログや当社のウェブサイトの他の部分に情報を投稿する場合には、
                    貴方が投稿した情報及び貴方のユーザーネームを含む貴方が投稿した情報は当社のウェブサイトの他の閲覧者に見られる場合があります。<br />
                    </p>
                </section>                      
                <div className={classes.marginP} />
                <section>
                    <h3 className={classes.h3}>4.第三者が貴方の情報を収集することを許可する方法</h3>
                    <p className={classes.section}>
                    貴方が当社のウェブサイトや他のサービスを利用するとき、当社は第三者が有するクッキー、ウェブビーコン、及びこれらに類似する技術を設置することにより、
                    当該第三者に貴方の情報を収集することを許可することができます。下記の"クッキー、ウェブビーコン、類似する技術"と題するセクションをご確認ください。
                    第三者によって収集された情報はデバイス、貴方のインターネットプロトコル(IP)アドレス、当社のサービスにアクセスした日時、
                    当社のウェブサイトにアクセスするために貴方が参照したウェブサイト、貴方が要求したウェブページ、それら要求の日時、貴方がクリック又はスクロールした広告の種類を含むことができます。<br/>
                    当社は、第三者に、下記目的において当社のウェブサイト上で貴方の情報を収集することを許可します。<br/>
                    商品又はサービスの広告を表示するため−当社は、当社のウェブサイト上に貴方に最も適切な広告を表示する目的で、
                    広告会社に対して、情報を収集することを許可します。各市場セグメントに適切と考えられるほかのウェブサイトに広告を表示するために市場セグメントに分類することを目的として、
                    これらの第三者は当社のウェブサイトへの閲覧者に関する匿名情報を収集することもできます。これは、"オンライン行動広告"として知られています。これらターゲットディスプレイ広告のオプトアウト、
                    ならびにクッキー、ウェブビーコン、及びこれらに類似する技術の制御に関するより詳細な情報については、下記の"貴方の選択"と題するセクションをご確認ください。<br/>
                    当社のウェブサイトやサービスの利用方法に関する情報を収集するため−当社は、当社の顧客に関することや人々が当社のウェブサイトを利用する方法について把握する目的で、
                    当社を支援する特定のサービスプロバイダーに対して、当社のウェブサイトやサービスで収集した情報を利用することを許可します。
                    この目的でこの情報を利用する会社は、この情報から個々のユーザーを特定しません。つまり、ウェブサイトの利用や行動について第三者により収集された統計情報は、
                    貴方を特定したり、貴方に結び付くことはありません。当社のウェブサイト上で他の会社のサービスを作動させるため−当社は、当社のウェブサイトの特定の部分を作動させる目的で、
                    企業に対して、特別な技術を利用することを許可します。例えば、当社は、Adobe に対して、Adobe Flash Player用のビデオコンテンツを配信するために必要な特別なクッキーを配置し有効にすることを許可します。
                    これらのクッキーは Flash クッキーといいます。<br />
                    当社のウェブサイトでの貴方の行動を貴方のソーシャルネットワークと結び付けるため−当社は、フェイスブック、ツイッター、グーグルプラスなどのソーシャルネットワークに、彼らの会員の行動をトラッキングしたり、ウェブサイト閲覧者に関する特定の情報を収集
                    することを許可する機能を当社のウェブサイトに追加しました。これらの機能は、プラグインやウィジェットと呼ばれる技術を含みます。
                    プラグインは、二つのウェブサイト間を繋げるダイレクトリンクを作成し、ウィジェットは、他の会社のサービスを当社のウェブサイトに提供するインタラクティブなミニプログラムです。
                    ソーシャルネットワークが貴方の行動をトラッキングする方法について懸念がある場合には、そのソーシャルネットワークに連絡するか、
                    そのソーシャルネットワークのプライバシーポリシーを参照してください。例えば、フェイスブックのデータ利用のポリシーはコチラで参照することができます。<br />
                    </p>
                </section>
                <div className={classes.marginP} />
                <section>
                <h3 className={classes.h3}>第4条(変更の届出)</h3>   
                <p className={classes.section}> 利用者は、登録申込みの際届け出た内容に変更があった場合、すみやかにその旨を当社が指定する方法により届け出るものとします。</p>                      
                </section>
                <div className={classes.marginP} />
                <section>
                    <h3 className={classes.h3}>5.クッキー、ウェブビーコン、類似する技術</h3>                
                    <p className={classes.section}>
                    当社は、他の会社とともに、貴方のデバイス及び当社のサービスの利用に関する情報を収集するために様々な技術を利用することができます。これらの技術には、クッキー及びウェブビーコンを含みます。<br/>
                    クッキー−クッキーは、貴方が閲覧している間だけ(セッション・クッキー)、又は、一定の期間(パーシステント・クッキー)、
                    ウェブサイトのサーバーから送付され、貴方のデバイスのハードドライブに蓄積される小さなデータファイルです。
                    クッキーは、後でウェブサーバーで読み取ることができる情報を含みます。ほとんどのウェブブラウザは、自動的にクッキーを受け入れますが、
                    貴方は、クッキーを受け入れる又はクッキーを完全に拒否するかの選択肢が与えられているため、ブラウザの設定を変更することができます。
                    貴方のブラウザの設定変更に関するより詳細な情報は、下記の"貴方の選択"と題するセクションをご確認ください。<br/>
                    当社は、主に下記目的においてクッキーを利用します。ウェブサイトの機能−一部のクッキー(ストリクトリィ・ネセサリ・クッキー)は、貴方が当社のウェブサイトにアクセスし利用するために必要です。
                    これらのクッキーがなければ、当社のウェブサイトは適切に作動しません。<br/>
                    パフォーマンスの監視−一部のクッキー(パフォーマンス・クッキー)は、当社のウェブサイトの混雑状況を分析し、評価することに役立ちます。
                    このクッキーは、当社に対して、どれくらいの閲覧者が当社のウェブサイトと交信したのか、エラーが発生しているかどうか、
                    どのページがしばしば動かないのか、どのページが読み込みに時間がかかるのか、どのページを利用者が閲覧する傾向にあるのか、
                    どのような順序なのかを示します。これらのクッキーは貴方を特定することができるどのような情報も収集せず、
                    当社のウェブサイトの動作方法を向上させ利用者の興味を理解することを支援するためにのみ利用されます。
                    利用者の利便性−一部のクッキー(ファンクショナリティ・クッキー)は、特定のウェブサイトを閲覧し、又は利用するたびに情報を入力する手間を省略するために、
                    情報を記録します。例えば、クッキーは、貴方のアカウントにログインする時間を省略するために、貴方のユーザーネームを記録します。<br/>
                    マーケティング−一部のクッキー(ターゲティング・クッキー又はアドバタイジング・クッ キー)は、貴方が当社のウェブサイトを閲覧し又は利用するときに現れるプロモーション、
                    広告、その他マーケティングメッセージを操作することにより貴方のエクスペリエンスを調整するため、
                    並びに貴方がどのサービスを利用しているのか、及びどのように当社の情報にアクセスしているかを当社が把握することに役立てるために利用されます。
                    当社は、ウェブサイトへの閲覧をカスタマイズしたり、関連するプロモーションを送付するためにこの情報を利用することができます。<br/>
                    ウェブビーコン−ウェブビーコンは、クリア gif、シングルピクセル gif、ページタグ、ウェブバグと時々呼ばれ、
                    ウェブページ、アプリケーション、メールに埋め込まれている小さな透明の画像です。当社は、貴方が閲覧したウェブページをトラッキングし、
                    当社のマーケティングの有効性をテストし、メールが開封され、実行されたかを調査するために、ウェブビーコンを利用します。<br/>
                    </p>
                </section>
                <div className={classes.marginP} />
                <section>
                    <h3 className={classes.h3}>6.貴方の選択</h3>
                    <p className={classes.section}>
                    プロモーション情報に関する選択<br/>
                    貴方は、スターバックスアカウントにサインインするときに貴方の設定を当社にお知らせする、貴方のアカウントのプロフィール管理画面でオンラインプロモーションの設定を修正する、
                    若しくは貴方の携帯端末の設定でテキストと通知設定を調整することにより、又は当社が送付するプロモーションメールのオプトアウトの指示に従うことにより、
                    プロモーションのメール、テキストメッセージ、電話、郵便物を受信することについて、オプトアウトすることができます。<br/>
                    又は、これらプロモーション情報の一部又は全部を受信することを希望しないことを、下記のお問合せ先に電話することで、お知らせすることもできます。<br />
                    <br />
                    【お問い合わせ先】<br />
                    個人情報の問い合わせ窓口<br />
                    電話番号:0836-84-7112<br />
                    受付時間:10:00〜18:00(土日祝祭日及び年末年始は除く)<br />
                    当社からのプロモーション通知の受信をオプトアウトしても、当社は、貴方のアカウント、購入、プロモーション以外の情報を送ることができることについてはご留意ください。<br />
                    <br />
                    情報収集に関する選択<br />
                    貴方は、当社が貴方に関して収集する情報を選択することもできます。<br />
                    位置情報−貴方が当社のサービスにアクセスするためにスマートフォン又は他のモバイルデバイスを利用するとき、
                    モバイルアプリケーション用の位置サービスが利用可能なとき、又は、モバイルデバイスの権限がこの情報の通信を許可するときに限り、
                    当社は貴方の物理的位置情報を収集することができます。貴方が当社に位置情報を収集してほしくない場合には、貴方は、関連する設定及び貴方のモバイルデバイスの権限を変更することで、
                    この情報を共有することについてオプトアウトすることができます。<br />
                    クッキー−クッキーを拒否したい場合には、貴方はブラウザの適切な設定を選択するための措置をとらなければなりません。
                    各ブラウザのクッキー制御は少し異なりますが、最も一般的なブラウザ(インターネットエクスプローラー、クローム、ファイヤーフォックス、サファリ)では、
                    ブラウザが貴方に通知することを選択できる設定やオプションがあり、典型的にはあるサイトがクッキーを設定することを希望し、又はクッキーが設定される前にクッキーを受け入れるか拒否するかの選択を常に提供します。
                    貴方が、クッキーを取り除く又は拒否することを選択する場合には、当社のウェブサイト上の多くの機能やサービスに影響を与えます。
                    貴方がクッキーの設定を変更するための正しい方法を知りたい場合には、貴方のブラウザのヘルプメニューを利用してください。
                    クッキーの断り方を含むクッキーに関する追加情報については、コチラを閲覧してください。<br />
                    Flash クッキー−当社は、Adobe に対して、Adobe Flash Player 用のビデオコンテンツを配信するために必要な特別なクッキーを配置し有効にすることを許可します。
                    貴方は、貴方のブラウザの設定を単に変更するだけでは Flash クッキーを取り除くことはできません。貴方が貴方のデバイス上の Flash クッキーに情報を蓄積することができるウェブサイトを制限したい場合には、
                    貴方は、Adobe のウェブサイトを閲覧する必要があります。トラッキング拒否の技術−最新のウェブブラウザの一部は、
                    行動をトラッキングされたくないことを示す情報を持って閲覧したウェブサイトのヘッダーにトラッキング拒否を発信するトラッキング拒否設定があります。
                    当社は、現在、トラッキング拒否の信号を発信するブラウザに対応していません。<br />
                    </p>
                </section>
                <div className={classes.marginP} />
                <section>
                    <h3 className={classes.h3}>7.貴方の情報へのアクセス又は変更、貴方のアカウントの削除</h3>                
                    <p className={classes.section}>
                    貴方の情報へのアクセス又は変更、貴方のアカウントの削除については、別途定める「個人情報の保護に関する法律」による公表事項についてをご確認ください。
                    </p>
                </section>
                <div className={classes.marginP} />
                <section>
                    <h3 className={classes.h3}>8.貴方の情報の保護方法</h3>                
                    <p className={classes.section}>
                    1 利用者が登録の抹消を希望するときは、当社が指定する方法により届け出るものとします。<br />
                    2 当社は、利用者について以下のいずれかの事由が発生した場合、利用者の承諾なくして利用登録を抹消できるものとします。<br/>
                    〈1〉 利用者が、当社の指定する保険契約の契約者ではなくなった場合して利用登録を抹消できるものとします。<br/>
                    〈2〉 利用者が、登録申込み時に虚偽の事項を通知したことが判明した場合<br/>
                    〈3〉 利用者が本規定に違反した場合<br/>
                    〈4〉 その他、当社が不適当と判断する行為を利用者が行った場合</p>
                </section>
                <div className={classes.marginP} />
                <section>
                    <h3 className={classes.h3}>第9条(当サービスの変更、一時中断、中止)</h3>                
                    <p className={classes.section}>
                    合同会社住宅サポートは、個人情報の保護に関する法律に従った、貴方の情報の紛失、悪用、不正アクセス、開示、変更するリスクを減少させるための技術的、
                    物理的、管理的なセキュリティ基準を用いて、貴方の情報を保護します。当社の安全措置の一部は、ファイアーウォール、データ暗号化、物理的なアクセス制御、
                    管理的情報統制を含みます。貴方が高度な機微情報(例えばクレジットカード番号など)を、当社のウェブサイトを通じ、又は当社のモバイルアプリケーションの一つに送信するときに、
                    当社はその情報送信を SSL プロトコルを利用して暗号化します。当社は、貴方の個人情報保護に役立つセキュリティ技術及び手続きを採用してはいるものの、
                    100%の安全を保証することができるシステムやネットワークはありません。
                    </p>
                </section>
                <div className={classes.marginP} />
                <section>
                    <h3 className={classes.h3}>9.貴方の情報の保存及び処理</h3>                
                    <p className={classes.section}>
                    貴方が当社に提供する情報は、合同会社住宅サポート及び山口東京理科大学と日本の他の会社が所有するサーバー上で保管され処理されます。
                    貴方の情報を処理する国又は人、処理の実施場所に関わらず、当社は、適用される情報保護法令及びこのステートメントに従って貴方の情報を保護する対策を講じます。
                    </p>
                </section>
                <div className={classes.marginP} />
                <section>
                    <h3 className={classes.h3}>10.このステートメントの改定</h3>                
                    <p className={classes.section}>
                    このステートメントは、このウェブページ上部に記された日付をもって発行されています。
                    当社は、時々このステートメントを改定することができます。当社が重大な変更をする場合には、当社はこのページ上に改定したステートメントを公表し、
                    このウェブサイト上部の日付を変更します。当社は、貴方がこのウェブページを閲覧する度にこの日付を確認し、このステートメントへの改定及び変更を確認することを勧めます。
                    当社は、当社が貴方の個人情報利用又は開示する方法に著しく影響を与える可能性のあるこのステートメントの変更に関しては、変更の効力発生日に先立ち、このサイト上で貴方にお知らせします。
                    </p>
                </section>
                <section>
                    <h3 className={classes.h3}>11.連絡先</h3>                
                    <p className={classes.section}>
                    当社は、プライバシーに関する貴方の質問、意見、及び懸念を受け付けます。貴方は、下記のお問合せ先に連絡することができます。<br />
                    <br />
                    【保有個人データに関するお問合せ先】<br />
                    (1)お電話による場合<br />
                    合同会社 住宅サポート<br />
                    個人情報お問い合わせ窓口<br />
                    0836-84-7112<br />
                    (受付時間:10:00〜18:00 土日祝祭日及び年末年始は除く)<br />
                    <br />
                    (2)お手紙による場合<br />
                    〒 756-0091 山口県山陽小野田市日の出1丁目3−9<br />
                    合同会社 住宅サポート<br />
                    個人情報お問い合わせ窓口<br />
                    なお、直接のご来社、電子メール等、上記以外の方法によるお申し出はお受け致しかねますので、ご了承下さい。<br />
                    </p>
                </section>
            </div>
        </Paper>
        <div className={classes.margin} />
        <FooterVar2 />
        </div>
    )
}
        

export default PrivacyStatements;