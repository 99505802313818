import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Checkbox, FormControlLabel, makeStyles, TextField } from "@material-ui/core";
import Footer from "../components/footer/footer";
import NotSignInHeader from "../components/header/NotSignInHeader";
import { auth, db, FirebaseTimeStamp } from "../firebase";
import { useCallback } from "react";
import { init, send } from "emailjs-com";
import { push } from "connected-react-router";
import { hideLoadingAction, showLoadingAction } from "../redux/loading/actions";
import { getUserId } from "../redux/users/selectors";

const useStyles = makeStyles((theme) => ({
    root: {
        width: 700,
        margin: "0 auto",
        padding: 20,
        [theme.breakpoints.down('sm')]: {
            width: 300,
            padding: "20px 0",                        
        },     
    },
    contact: {
        display: "flex",
        flexFlow: "column",
        alignItems: "flex-start",        
    },
    subject: {
        color: "#e4002b",
        fontSize: "23px",        
        [theme.breakpoints.down('sm')]: {
            fontSize: 16
        }
    },
    table: {
        border: "1px solid #ddd",   
        width: 700,
        [theme.breakpoints.down('sm')]: {
            width: 300
        },            
    },
    tr: {
        borderBottom: "1px solid #ddd",   
        [theme.breakpoints.down('sm')]: {
            display: "flex",
            flexFlow: "column",
            width: 300
        },                     
    },
    th: {
        fontSize: 15,
        backgroundColor: "#f2f2f2",
        borderRight: "1px solid #ddd",        
        padding: "10px 20px",
        textAlign: "left",               
        verticalAlign: "top",
        minWidth: 200,
        [theme.breakpoints.down('sm')]: {
            width: 300
        },                          
    },  
    td: {                                                    
        padding: "10px 20px",
        display: "flex",
        [theme.breakpoints.down('sm')]: {
            width: 300,
            flexFlow: "column",
            alignItems: "flex-start",
            marginLeft: 0
        },                               
    },
    nessesay: {
        backgroundColor: "#e4002b",
        color: "#fff",
        width: "max-content",
        padding: "3px 15px",
        marginTop: 20,         
    },
    field: {
        width: 500, 
        [theme.breakpoints.down('sm')]: {
            width: 250
        },           
    },
    fieldName: {
        width: 160,
    },      
    flex: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            width: 200
        },     
    },  
    margin: {
        marginLeft: 20,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0
        }
    },
    button: {
        width: 250,
        backgroundColor: "#e4002b",
        color: "#fff",
        padding: "10px 50px",
        borderRadius: 50,        
        '&:hover': {
            opacity: 0.5
        },
    },
    buttonDisabled: {
        width: 250,
        color: "#707070",
        backgroundColor: "#ccc",
        borderRadius: 50,
        paddingTop: 10,
        paddingBottom: 10,
    }
}))

const ContactCompany = () => {
    const classes = useStyles();
    const selector = useSelector((state) => state)
    const dispatch = useDispatch();
    const uid = getUserId(selector)
    const path = selector.router.location.pathname
    const id = path.split('/contactcompany/')[1]   

    const [user, setUser] = useState(null);
    const [company, setCompany] = useState(null);
    const [contents, setContents] = useState("");
    const [agrees, setAgrees] = useState(null)
    const [checked, setChecked] = useState(false);
    const handleClick = () => {
        setChecked(checked?false:true)
    }

    const inputContents = useCallback((e) => {
        setContents(e.target.value)
    }, [setContents])

    useEffect(() => {
        db.collection('companys').doc(id).get()
            .then(snapshot => {
                const data = snapshot.data();
                setCompany(data)
            })
    }, [])
    useEffect(() => {     
        db.collection('users').doc(uid).get()
            .then(doc => {
                const data = doc.data()
                setUser(data)
            })
    }, [])

    useEffect(() => {
        db.collection('users').doc(uid).collection('agree').doc(id).get()
            .then(doc => {           
                const data = doc.data()
                setAgrees(data)
            })
    }, [])

    const sendMail = () => {
        dispatch(showLoadingAction("店舗へ問い合わせ中..."))
        const userID = process.env.REACT_APP_USER_ID;
        const serviceID = process.env.REACT_APP_SERVICE_ID;
        const templateID = process.env.REACT_APP_CONTACT_TO_COMPANY_TEMPLATE_ID;   
        const timestamp = FirebaseTimeStamp.now();

        if(userID !== undefined && serviceID !== undefined && templateID !== undefined) {
            init(userID);
            const template_param = {
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                companyEmail: company.email,
                companyName: company.companyName,
                contents: contents,
                sended_at: timestamp
            };

            const toMailDetailbox = {
                send_at: timestamp,
                companyName: company.companyName,
                from_firstName: user.firstName,
                from_lastName: user.lastName,
                from_email: user.email,
                age: user.age,
                tel: user.tel,    
                addressNum: user.addressNum,
                address: user.address,
                housenumber: user.housenumber,
                department: user.department,
                grade: user.grade,
            };

            const agree = {
                send_at: timestamp,
                agreement: "情報開示に同意する",
                companyName: company.companyName,                
            }

            send(serviceID, templateID, template_param)
            .then(() => {
                const companyRef = db.collection('companys').doc(id).collection('maildetailbox').doc()
                toMailDetailbox['maildetailboxId'] = companyRef.id;
                companyRef.set(toMailDetailbox)
                .then(() => {
                    const userRef = db.collection('users').doc(uid).collection('agree').doc(id)
                    agree['agreeId'] = id;
                    userRef.set(agree)
                    dispatch(push('/mappage'))
                    dispatch(hideLoadingAction())
                })            
            }).catch(() => {
                dispatch(hideLoadingAction())
                alert('送信に失敗しました')
            })        
        }
    }

    return(
        <div>
        <NotSignInHeader />
        <div className={classes.root}>
            <div className="module-spacer--medium" />
            <div className="module-spacer--medium" />
            <div className="module-spacer--medium" />
            <div className={classes.contact}>
            <h2 className={classes.subject}>店舗への連絡</h2>
            <div className="module-spacer--small" />
            <table className={classes.table}>
                <tr className={classes.tr}>
                    <th className={classes.th}>店舗名</th>
                    <td className={classes.td}>
                        <div className={classes.flex}>
                            {company && (
                                <p>{company.companyName}</p>
                            )}                            
                        </div>                     
                    </td>
                </tr>        
                <tr className={classes.tr}>
                    <th className={classes.th}>氏名</th>
                    <td className={classes.td}>
                        <div className={classes.flex}>
                            {user && (
                                <p>{user.firstName + user.lastName}</p>
                            )}                            
                        </div>                     
                    </td>
                </tr>        
                <tr className={classes.tr}> 
                    <th className={classes.th}>連絡内容<br /><span className={classes.nessesay}>必須</span></th>
                    <td className={classes.td}>
                        <TextField variant="outlined" type="text" rows={8} multiline={true} placeholder="連絡内容を入力してください" className={classes.field} value={contents} onChange={inputContents} />
                    </td>
                </tr>
            </table>
            </div>
            <div className="module-spacer--medium" />
            {agrees && agrees.agreeId === id ? (
                <p>初期連絡は完了しています。これからの連絡は
                    <span style={{color: "blue", cursor: "pointer"}} onClick={() => dispatch(push('/contactcompanyinteview/' + id))}>こちら</span>
                からお願いします。</p>
            ) : (
            <>
            <FormControlLabel 
                value="end"
                control={<Checkbox color="primary" />}
                label="応募した店舗への詳細な個人情報の開示に同意する"
                labelPlacement="agree"
                onClick={handleClick}
            />
            <div className="module-spacer--small" />         
            <button disabled={checked !== true} className={checked === true && classes.button || checked !== true && classes.buttonDisabled} onClick={() => sendMail()}>送信</button>  
            </>
            )}                      
        </div>
        <Footer />        
        </div>
    )
}

export default ContactCompany;