import React, { useCallback, useEffect, useState } from "react";
import { TextField, List, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import SelectBox from '../../UIkit/SelectBox';
import { db, FirebaseTimeStamp, auth } from "../../firebase";
import { isValidEmailFormat, isValidRequiredInput } from "../../function/common";
import NotSignInHeader from "../../components/header/NotSignInHeader";
import FormButton from "../../assets/img/formbutton.png";
import FooterVar2 from "../../components/footer/footer_ver2";
import NoImage from "../../assets/img/no_image.png";
import { useContext } from "react";
import { multiStateContext } from "../../StateContext";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: "#FCFCFC",
    [theme.breakpoints.down('sm')]: {
      width: "100%"
    }
  },
  company: {
    width: "50%",
    margin: "0 auto",
    [theme.breakpoints.down('sm')]: {
      width: "100%"
  }
  },
  root: {
    width: 800,
    margin: "0 auto",            
    [theme.breakpoints.down('sm')]: {
      width: 300
    }
  },
  link: {
    color: "blue",
    cursor: "pointer"
  },
  companyName: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 20
  },
  flex: {
    display: "flex",
  },
  input: {
    width: 180,
    height: 30,
    marginTop: 0,
    backgroundColor: "#fff",
    [theme.breakpoints.down('sm')]: {
      width: 120
    }
  },
  nessesary: {
    color: "red",
    paddingLeft: 5,
    fontSize: 12
  },
  field: {
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    margin: "0 auto",
    alignItems: "flex-start",
    width: 400,   
    [theme.breakpoints.down('sm')]: {
      width: 300
    }   
  },
  tag: {
    display: "flex",
    flexFlow: "column",
    alignItems: "flex-start"
  },
  grade: {
    display: "flex",  
    flexFlow: "column",
    alignItems: "flex-start",      
    [theme.breakpoints.down('sm')]: {
      width: 150
    }    
  },
  button: {
    width: 250,      
    borderRadius: 10,            
    border: 0,                   
    '&:hover': {
        opacity: 0.6
    }     
  },
  space_big:{
      height: 50,
  },
  space_small: {
      height: 30,
  },
  mail: {
    width: 300,
    marginTop: 0,
    backgroundColor: "#fff",
    [theme.breakpoints.down('sm')]: {
      width: 250
    }    
    
  },
  tel: {
    marginTop: 0,
    width: 150,
    backgroundColor: "#fff"
  },
  adoressNum: {
    marginTop: 0,
    width: 100,
    backgroundColor: "#fff"
  },
  adoress: {
    width: 200,
    backgroundColor: "#fff",
    [theme.breakpoints.down('sm')]: {
      width: 250
    }    
  },
  select: {
    width: 100,
  },
  list: {
    background: '#F5F5F5',
    height: 'auto'
  },
  image: {
      objectFit: 'cover',
      margin: '8px 16px 8px 0',
      height: 96,
      width: 96
  },
  text: {
      width: '100%',
      fontWeight: "bold"
  }
}));

const MailInit = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);
    const path = selector.router.location.pathname
    const id = path.split('/mailinit/mailform/')[1]
    const S = "abcdefghijkmnlopqrstuvwxyzABCDEFGHIJKMNLOPQRSTYVWXYZ0123456789"
    const N = 16;
    const pass = Array.from(crypto.getRandomValues(new Uint32Array(N))).map((n) => S[n%S.length]).join('');
    const [password, setPassword] = useState(pass),
          [company, setCompany] = useState(null);

    const {
      firstReading,
      lastReading,
      firstName,
      lastName,
      email,
      tel, 
      addressNum, 
      address, 
      housenumber, 
      age, 
      department, setDepartment,
      grade, setGrade,
      inputFirstReading,
      inputLastReading,
      inputFirstName,
      inputLastName,
      inputEmail,
      inputTel,
      inputAddress,
      inputHouseNumber,
      inputAddressNum,
      inputAge,
    } = useContext(multiStateContext)

    const departments = [
        {id: "engineering", name: "工学部"},
        {id: "pharmacy", name: "薬学部"}
    ]
    const grades = [
        {id: "first", name: "1年生"},
        {id: "second", name: "2年生"},
        {id: "third", name: "3年生"},
        {id: "fourth", name: "4年生"},
        {id: "fifth", name: "5年生"},
        {id: "sixth", name: "6年生"},
    ]

    useEffect(() => {
        db.collection('companys').doc(id).get()
         .then(snapshot => {
             const data = snapshot.data()
             setCompany(data)
         })
    }, []);
    const MailtoSignUp = (firstName, lastName, firstReading, lastReading, email, age, tel, addressNum, address, housenumber, grade, department, password) => {
      return async () => {
          // Validations
          if(!isValidRequiredInput(email, password)) {
              alert('必須項目が未入力です。');
              return false
          }  
          if(!isValidEmailFormat(email)) {
              alert('メールアドレスの形式が不正です。もう1度お試しください。')
              return false
          }
          if (password.length < 6) {
              alert('パスワードは6文字以上で入力してください。')
              return false
          }
  
          return auth.createUserWithEmailAndPassword(email, password)
              .then(result => {
                  const user = result.user;
                  user.sendEmailVerification();
                  if (user) {
                      const uid = user.uid;
                      const timestamp = FirebaseTimeStamp.now();
  
                      const template_param = {
                        created_at: timestamp,
                        firstName: firstName,
                        lastName: lastName,
                        firstReading: firstReading,
                        lastReading: lastReading,                   
                        email: email,
                        age: age,
                        tel: tel,
                        addressNum: addressNum,
                        address: address,      
                        housenumber: housenumber,               
                        department: department,
                        grade: grade,
                        role: "user",
                        uid: uid,
                        updated_at: timestamp,

                    };
  
                      db.collection('users').doc(uid).set(template_param)
                      .then(async () => {
                        dispatch(push('/mailconfirm/' + company.companyid))
                      })
                  }
                  }).catch((error) => {
                      alert('アカウント登録に失敗しました。もう1度お試しください。')
                      throw new Error(error)
                  })
      }
  }
    


    return(
        <div className={classes.background}>
          <NotSignInHeader />
          <div className="module-spacer--medium" />
          <div className="module-spacer--medium" />
          <div className="module-spacer--medium" />
          <div className={classes.root}>
          <h2 style={{fontSize: 18}}>応募フォーム</h2>
          <div className="module-spacer--extra-small" />
          <p style={{fontSize: 12}}>会員の方は<span onClick={() => dispatch(push('/signin'))} className={classes.link}>ログイン</span>すると、登録情報が自動で表示されスムーズに応募することができます。</p>
          <div className="module-spacer--extra-small" />
          <div className={classes.root}>
          {company && (
            <div className={classes.company}>
              <h2 style={{textAlign: "left", fontWeight: "bold"}}>応募する店舗</h2>
              <List>
                  <>
                      <ListItem className={classes.list} key={company.id}>
                          <ListItemAvatar>
                              <img 
                                  className={classes.image}
                                  src={
                                    (company.images.length > 0) ? company.images[0].path : [NoImage]
                                  }
                              />
                          </ListItemAvatar>
                          <div className={classes.text}>
                              <ListItemText 
                                  primary={company.companyName}
                              />
                          </div>                  
                      </ListItem>
                  </>
              </List>
            </div>
          )}
          <div className="module-spacer--extra-small" />
          <div className={classes.field}>
              <p style={{fontSize: 14}}>お名前<span className={classes.nessesary}>必須</span></p>
              <div style={{height: 2}} />
              <div className={classes.flex}>
                <div className={classes.tag}>
                <p style={{fontSize: 14, paddingLeft: 14}}>姓</p>                                   
                <TextField
                variant="outlined" type="text" name="firstName" margin="dense" placeholder="山田" className={classes.input} 
                multiline={true} value={firstName} onChange={inputFirstName} required={true} style={{marginLeft: 14}}
                />
                </div>
                <div style={{paddingRight: 15}} />
                <div className={classes.tag}>                
                <p style={{fontSize: 14}}>名</p>                
                <TextField
                variant="outlined" type="text" name="lastName" margin="dense" placeholder="太郎" 
                multiline={true} value={lastName} onChange={inputLastName} required={true} className={classes.input}
                />
                </div>
              </div>
            </div>   
            <div className="module-spacer--extra-small" />
            <div className={classes.field}>
              <p style={{fontSize: 14}}>お名前(ふりがな)<span className={classes.nessesary}>必須</span></p>
              <div style={{height: 2}} />
              <div className={classes.flex}>
              <div className={classes.tag}>
                <p style={{fontSize: 14, paddingLeft: 14}}>せい</p>
                <TextField
                variant="outlined" type="text" name="firstName" margin="dense" placeholder="やまだ" style={{marginLeft: 14}}
                multiline={true} fullWidth={true} value={firstReading} onChange={inputFirstReading} className={classes.input} 
                />
                </div>
                <div style={{paddingRight: 10}} />
                <div className={classes.tag}>
                <p style={{fontSize: 14}}>めい</p>
                <TextField
                variant="outlined" type="text" name="lastName" margin="dense" placeholder="たろう" className={classes.input}  
                multiline={true} fullWidth={true} value={lastReading} onChange={inputLastReading}
                />
                </div>
              </div>
              <div className="module-spacer--extra-small" />
              <div className={classes.gender}>                                                                                          
                <div className={classes.flex}>  
                <div className={classes.grade}>
                <p style={{fontSize: 14}}>学部</p>
                <SelectBox
                  options={departments} required={false} value={department} 
                  select={setDepartment} className={classes.select}
                />   
                </div>
                <div className={classes.grade}>
                <p style={{fontSize: 14}}>学年</p>  
                 <SelectBox
                  options={grades} required={false} value={grade} 
                  select={setGrade} className={classes.select}
                />        
                </div>                              
                </div>               
              </div>
              <div className="module-spacer--extra-small" />  
              <p style={{fontSize: 14}}>年齢<span className={classes.nessesary}>必須</span></p>
              <div style={{display: "flex", alignItems: "center"}}>
              <TextField
                variant="outlined" type="email" name="text" margin="dense" placeholder="20" style={{marginLeft: 14}}
                multiline={true} fullWidth={true} value={age} onChange={inputAge} required={true} className={classes.input}
              />歳
              </div>
              <div className="module-spacer--extra-small" />  
              <p style={{fontSize: 14}}>ご連絡先</p>
              <p style={{fontSize: 14, paddingLeft: 14, marginTop: 3}}>メールアドレス<span className={classes.nessesary}>必須</span></p>
              <TextField
                variant="outlined" type="email" name="email" margin="dense" placeholder="example@test.com" style={{marginLeft: 14}}
                multiline={true} fullWidth={true} value={email} onChange={inputEmail} required={true} className={classes.mail}
              />
              <div style={{height: 5}} />
               <p style={{fontSize: 14, paddingLeft: 14}}>電話番号<span className={classes.nessesary}>必須(ハイフンあり)</span></p>
              <TextField
                variant="outlined" type="text" name="tel" margin="dense" value={tel} style={{marginLeft: 14}}
                multiline={true} onChange={inputTel} placeholder="000-0000-0000" required={true} className={classes.tel}
              />    
              <div style={{height: 5}} />
              <p style={{fontSize: 14, paddingLeft: 14}}>住所<span className={classes.nessesary}>必須(ハイフンあり、アパート名まで入力)</span></p>
              <div style={{display: "flex", alignItems: "center"}}>
              <p style={{marginLeft: 14}}>〒</p>
              <TextField
                variant="outlined" type="text" name="adressNum" margin="dense" value={addressNum} className={classes.adoressNum}
                multiline={true} onChange={inputAddressNum} placeholder="000-0000" required={true}
              />  
              </div>              
              <div style={{display: "flex", alignItems: "center"}}>
              <TextField
                variant="outlined" type="text" name="adress" margin="dense" value={address} placeholder="山陽小野田市"
                multiline={true} fullWidth={true} onChange={inputAddress} required={true} style={{marginLeft: 14}} className={classes.adoress}
              />                       
              <TextField
                variant="outlined" type="text" name="adress" margin="dense" value={housenumber} placeholder="大学通り1丁目1-1"
                multiline={true} fullWidth={true} onChange={inputHouseNumber} required={true} style={{marginLeft: 14}} className={classes.adoress}
              />       
              </div>              
              </div>                                                                                                                     
            </div>
            <div className={classes.space_small} />
            <p style={{fontSize: 12}}><span className={classes.link}>利用規約</span>、<span className={classes.link}>個人情報保護規約</span>を確認してください。</p>
            <div className="module-spacer--extra-extra-small" />
            <button className={classes.button} onClick={MailtoSignUp(firstName, lastName, firstReading, lastReading, email, age, tel, addressNum, address, housenumber, grade, department, password)}><img src={FormButton} alt="" /></button>        
            <div className={classes.space_small} />
        </div>
        <FooterVar2 />
        </div>
    )
}

export default MailInit;