export const FETCH_COMPANYS = "FETCH_COMPANYS";
export const fetchCompanysAction = (companys) => {
    return {
        type: "FETCH_COMPANYS",
        payload: companys
    }
};
export const FETCH_SANPARK = "FETCH_SANPARK";
export const fetchSanparkAction = (sanpark) => {
    return {
        type: "FETCH_SANPARK",
        payload: sanpark
    }
};
export const FETCH_YUMETOWN = "FETCH_YUMETOWN";
export const fetchYumetownAction = (yumetown) => {
    return {
        type: "FETCH_YUMETOWN",
        payload: yumetown
    }
};
