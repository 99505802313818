import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUserId } from "../redux/users/selectors";
import { List } from "@material-ui/core";
import CompanyListItem from "../components/companys/CompanyListItem";
import { db } from "../firebase";

const CompanyCheck = () => {
    const selector = useSelector(state => state)
    const uid = getUserId(selector)
    const [favorite, setFavorite] = useState([])

    useEffect(() => {
        let posts = []
        db.collection('users').doc(uid).collection('companylist').get()
            .then(snapshots => {
                snapshots.docs.forEach(doc => {
                    const data = doc.data()
                    posts.push(data)
                })
                setFavorite(posts)
            })
    }, [favorite])

    return(
        <div>
            <h2 style={{fontWeight: "bold"}}>
                あなたが保存した情報
            </h2>
            <List>
                {favorite.length > 0 && (
                    favorite.map(company => 
                        <CompanyListItem key={company.companylistId} company={company} />)
                )}
            </List>
        </div>
    )
}

export default CompanyCheck;