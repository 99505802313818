import * as Actions from './actions';
import { initialState } from '../stores/initialState';

export const PostsReducer = (state = initialState.posts, action) => {
    switch(action.type) {
        case Actions.FETCH_POSTS: 
            return{
                ...state,
                posts: [...action.payload]
            }
        default:
            return state
    }
}