import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import createStore from './redux/stores/store';
import { ConnectedRouter } from 'connected-react-router';
import { MuiThemeProvider } from '@material-ui/core';
import ErrorBoundary from './UIkit/ErrorBoundary';
import * as History from 'history';
import App from './App';
import { theme } from './assets/theme';
import './assets/reset.css'
import './assets/style.css'
import StateContext from './StateContext';
import * as serviceWorker from './serviceWorkerRegistration'

const history = History.createBrowserHistory();
export const store = createStore(history);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <MuiThemeProvider theme={theme}> 
      <StateContext>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </StateContext>
    </MuiThemeProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.register();
