import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { getUserId } from "../redux/users/selectors";
import { List } from "@material-ui/core";
import ApplicationHistoryItem from "../components/companys/ApplicationHistoryItem";
import NotSignInHeader from "../components/header/NotSignInHeader";
import Footer from "../components/footer/footer";
import { db } from "../firebase";

const useStyles = makeStyles((theme) => ({
    orderList: {
        background: theme.palette.grey["100"],
        margin: '0 auto',
        padding: 20,
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        [theme.breakpoints.up('md')]: {
            width: 768
        }
    },
}))

const ApplicationsHistory = () => {
    const selector = useSelector((state) => state);
    const uid = getUserId(selector)
    const [applications, setApplications] = useState([])    
    const classes = useStyles();
    
    useEffect(() => {
        let list = []
        db.collection('users').doc(uid).collection('applications').get()
            .then(snapshots => {
                snapshots.docs.forEach(doc => {
                    const data = doc.data()
                    list.push(data)
                })
                setApplications(list)
            })
    }, [applications])  

    return(
        <div>
        <NotSignInHeader />
        <div className="module-spacer--medium" />
        <div className="module-spacer--medium" />
        <div className="module-spacer--medium" />
            <List className={classes.orderList}>
                {applications.length > 0 && (
                    applications.map(application => <ApplicationHistoryItem application={application} key={application.id}/>)
                )}
            </List>
        <Footer />
        <div className="module-spacer--medium" />
        <div className="module-spacer--medium" />
        <div className="module-spacer--medium" />
        </div>
    )
}

export default ApplicationsHistory;