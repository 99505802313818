import { signInAction, signOutAction } from "./actions";
import { push } from "connected-react-router";
import { auth, db, FirebaseTimeStamp } from "../../firebase";
import { hideLoadingAction, showLoadingAction } from "../loading/actions";
import { isValidEmailFormat, isValidRequiredInput } from "../../function/common";
import { init, send } from "emailjs-com";

export const addCompanyCard = (addedCompany) => {
    return async(dispatch, getState) => {
        const uid = getState().users.uid;
        const companyRef = db.collection('users').doc(uid).collection('companylist').doc();
        addedCompany['companylistId'] = companyRef.id;
        await companyRef.set(addedCompany);
    }
}

export const addApplication = (addApplicationCompany) => {
    return async(dispatch, getState) => {
        const uid = getState().users.uid;
        const companyRef = db.collection('users').doc(uid).collection('applications').doc();
        addApplicationCompany['applicationsId'] = companyRef.id;
        await companyRef.set(addApplicationCompany);        
    }
}

export const addRecruitment = (addUserRecruitment) => {
    return async(dispatch, getState) => {
        const uid = getState().users.uid;
        const companyRef = db.collection('users').doc(uid).collection('recruitments').doc();
        addUserRecruitment['recruitmentsId'] = companyRef.id;
        await companyRef.set(addUserRecruitment);        
    }
}

export const listenAuthState = () => {
    return async (dispatch) => {
        return auth.onAuthStateChanged(user => {
            if(user) {
              const uid = user.uid

                db.collection('users').doc(uid).get()
                  .then(snapshot => {
                      const data = snapshot.data();

                      dispatch(signInAction({
                          isSignedIn: true,
                          role: data.role,
                          uid: data.uid,
                          firstName: data.firstName,
                          lastName: data.lastName,
                      }))
                  }) 
            } else {
                dispatch(push('/signin'))
            }
        })
    }
}

export const deleteAcount = () => {
    return async(dispatch) => {
        const user = auth.currentUser;
        const uid = user.uid
        dispatch(showLoadingAction("退会手続き中..."))
        db.collection('users').doc(uid).delete().then(() => {
            user.delete().then(() => {
                dispatch(hideLoadingAction());
                dispatch(push('/signin'))
           })
        }).catch(() => {
            dispatch(hideLoadingAction());
        })
    }
} 

export const signIn = (email, password) => {
    return async (dispatch) => {
        dispatch(showLoadingAction("サインイン..."))
        if(!isValidRequiredInput(email, password)) {
            return false
        }
        if (!isValidEmailFormat(email)) {
            dispatch(hideLoadingAction());
            return false
        }
        auth.signInWithEmailAndPassword(email,password)
          .then(result => {
            const userState = result.user;

            if(!userState) {
                dispatch(hideLoadingAction());                
                throw new Error('ユーザーIDを取得できません')
            }

            const userId = userState.uid
            
            return db.collection('users').doc(userId).get().then(snapshot => {
                const data = snapshot.data();

                if (!data) {
                    dispatch(hideLoadingAction());                    
                    throw new Error('ユーザーデータが存在しません');
                }

                dispatch(signInAction({
                    isSignedIn: true,
                    role: data.role,
                    uid: data.uid,
                    firstName: data.firstName,
                    lastName: data.lastName,
                }))
                dispatch(push('/mappage'))
                dispatch(hideLoadingAction());
            })
        }).catch(() => {
            dispatch(hideLoadingAction());           
        });
    }
}

export const signUp = (firstName, lastName, firstReading, lastReading, email, tel, addressNum, address, housenumber, password, confirmPassword) => {
    const userID = process.env.REACT_APP_USER_ID;
    const serviceID = process.env.REACT_APP_SERVICE_ID;
    const templateID = process.env.REACT_APP_SIGN_UP_TEMPLATE_ID;   
    return async (dispatch) => {
        // Validations
        if(!isValidRequiredInput(firstName, lastName, email, tel, password, confirmPassword)) {
            alert('必須項目が未入力です。');
            return false
        }

        if(!isValidEmailFormat(email)) {
            alert('メールアドレスの形式が不正です。もう1度お試しください。')
            return false
        }
        if (password !== confirmPassword) {
            alert('パスワードが一致しません。もう1度お試しください。')
            return false
        }
        if (password.length < 8) {
            alert('パスワードは8文字以上で入力してください。')
            return false
        }

        return auth.createUserWithEmailAndPassword(email, password)
            .then(result => {
                dispatch(showLoadingAction("Sign up..."))
                const user = result.user;

                if(userID !== undefined && serviceID !== undefined && templateID !== undefined) {
                    init(userID);
                    const template_param = {
                        firstName: firstName,
                        lastName: lastName,
                        email: email
                    }
                send(serviceID, templateID, template_param)
                    .then(() => {
                        if (user) {
                            const uid = user.uid;
                            const timestamp = FirebaseTimeStamp.now();
        
                            const userInitialData = {
                                created_at: timestamp,
                                firstName: firstName,
                                lastName: lastName,
                                firstReading: firstReading,
                                lastReading: lastReading,  
                                email: email,
                                addressNum: addressNum,
                                address: address,
                                housenumber: housenumber,
                                tel: tel,
                                role: "user",
                                uid: uid,
                                updated_at: timestamp,                                          
                            }
        
                            db.collection('users').doc(uid).set(userInitialData)
                                .then(async () => {
                                    dispatch(push('/mappage'))
                                    dispatch(hideLoadingAction())           
                                })
                        }
                    })}          
            }).catch((error) => {
                dispatch(hideLoadingAction())
                alert('アカウント登録に失敗しました。もう1度お試しください。')
                throw new Error(error)
            })
    }
}

export const signOut = () => {
    return async (dispatch) => {
        auth.signOut() 
           .then(() => {
               dispatch(signOutAction());
               dispatch(push('/signin'));
           })
    }
}

export const resetPassword = (email) => {
    return async (dispatch) => {
        const actionCodeSettings = {
            // パスワード再設定後のリダイレクト URL
            url: 'http://localhost:3000/mappage',
            handleCodeInApp: false,
          }
        if (email === "") {
            alert ("必須項目が未入力です")
            return false
        } else {
            auth.sendPasswordResetEmail(email)
             .then(() => {
                 dispatch(push('/signin'))
             }) .catch (() => {
                 alert ("パスワードリセットに失敗しました。通信環境を確認してください。")
             })
        }
    }
}
export const resetEmail = (email) => {
    return async (dispatch) => {
        if (email === "") {
            alert ("必須項目が未入力です")
            return false
        } else {
            auth.currentUser.updateEmail(email)
             .then(() => {
                 dispatch(push('/signin'))
             }) .catch (() => {
                 alert ("メールアドレスリセットに失敗しました。通信環境を確認してください。")
             })
        }
    }
}

export const addBasicPro = ( firstName, lastName, firstReading, lastReading, email, tel, addressNum, address, housenumber, grade, department, age, one, two, three, four, five, itemone, itemtwo, itemthree, itemfour, itemfive ) => {
    return async(dispatch) => {
        const user = auth.currentUser;
        auth.onAuthStateChanged((user) => {
            if(!user) {
                auth.signInAnonymously();
            }

            if(user) {
                const uid = user.uid
                const timestamp = FirebaseTimeStamp.now()
                const addData = {
                    firstName: firstName,
                    lastName: lastName,
                    firstReading: firstReading,
                    lastReading: lastReading,                   
                    email: email,
                    tel: tel,
                    addressNum: addressNum,
                    address: address,   
                    housenumber: housenumber,
                    grade: grade,
                    department: department,
                    age: age,
                    one: parseInt(one),
                    two: parseInt(two),
                    three: parseInt(three),
                    four: parseInt(four),
                    five: parseInt(five),
                    itemone: itemone,
                    itemtwo: itemtwo,
                    itemthree: itemthree,
                    itemfour: itemfour,
                    itemfive: itemfive,
                    updated_at: timestamp,
                }

                db.collection('users').doc(uid).set(addData, {merge: true})
                  .then(() => {
                      dispatch(push('/mappage'))
                  }).catch((error) => {
                      throw new Error(error)
                  })
            }
            
        })
    }
}
export const addMail = (gender, birthday, adoressNum, adoress) => {
    return async(dispatch) => {
        auth.onAuthStateChanged((user) => {
            if(!user) {
                auth.signInAnonymously();
            }

            if(user) {
                const uid = user.uid
                const timestamp = FirebaseTimeStamp.now()
                const addData = {
                    gender: gender,
                    birthday: birthday,
                    adoressNum: adoressNum,
                    adoress: adoress,
                    updated_at: timestamp,
                }

                db.collection('users').doc(uid).set(addData, {merge: true})
                  .then(() => {
                      dispatch(push('/jobhome'))
                  }).catch((error) => {
                      throw new Error(error)
                  })
            }
            
        })
    }
}

