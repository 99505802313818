import { makeStyles } from '@material-ui/core/styles';
export default makeStyles((theme) => ({
    root: {
        borderRight: "3px solid #ccc",
        borderLeft: "3px solid #ccc",
        width: "80%",
        margin: "0 auto",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            border: "none"
        },
    },
    companyHome: {
        listStyle: "none",
        width: "95%",
        margin: "0 auto",
        fontFamily: "Hiragino Kaku Gothic ProN",
    },
    card: {
        width: "90%",
        margin: "0 auto",
        border: "3px solid orange",
        borderRadius: 15,
    },
    card_eat: {
        width: "90%",
        margin: "0 auto",
        border: "3px solid #FF0000",
        borderRadius: 15,
    },
    card_Leisure: {
        width: "90%",
        margin: "0 auto",
        border: "3px solid #00FFFF",
        borderRadius: 15,
    },
    card_customer: {
        width: "90%",
        margin: "0 auto",
        border: "3px solid #FFD415",
        borderRadius: 15,
    },
    card_sale: {
        width: "90%",
        margin: "0 auto",
        border: "3px solid #FF9D8A",
        borderRadius: 15,
    },
    card_education: {
        width: "90%",
        margin: "0 auto",
        border: "3px solid #3E4D9E",
        borderRadius: 15,
    },
    card_delivery: {
        width: "90%",
        margin: "0 auto",
        border: "3px solid #B3D472",
        borderRadius: 15,
    },
    card_easy_work: {
        width: "90%",
        margin: "0 auto",
        border: "3px solid #8558A1",
        borderRadius: 15,
    },
    card_medical: {
        width: "90%",
        margin: "0 auto",
        border: "3px solid #C9CACA",
        borderRadius: 15,
    },
    card_it: {
        width: "90%",
        margin: "0 auto",
        border: "3px solid #000000",
        borderRadius: 15,
    },
    card_specialty: {
        width: "90%",
        margin: "0 auto",
        border: "3px solid #FFAB55",
        borderRadius: 15,
    },
    card_office: {
        width: "90%",
        margin: "0 auto",
        border: "3px solid #009E00",
        borderRadius: 15,
    },
    card_ahter: {
        width: "90%",
        margin: "0 auto",
        border: "3px solid #727171",
        borderRadius: 15,
    },
    companyName: {
        width: "100%",
        height: 48,
        backgroundColor: "#FFEEDD",        
        fontSize: 20,
        textAlign: "left",
        paddingLeft: 25,
        paddingTop: 12,
        paddingBottom: 11,
    },
    companyName_eat: {
        width: "100%",
        height: 48,
        backgroundColor: "#FFCCCC",        
        fontSize: 20,
        textAlign: "left",
        paddingLeft: 25,
        paddingTop: 12,
        paddingBottom: 11,
    },
    companyName_Leisure: {
        width: "100%",
        height: 48,
        backgroundColor: "#CCFFFF",        
        fontSize: 20,
        textAlign: "left",
        paddingLeft: 25,
        paddingTop: 12,
        paddingBottom: 11,
    },
    companyName_customer: {
        width: "100%",
        height: 48,
        backgroundColor: "#FFF7D0",        
        fontSize: 20,
        textAlign: "left",
        paddingLeft: 25,
        paddingTop: 12,
        paddingBottom: 11,
    },
    companyName_sale: {
        width: "100%",
        height: 48,
        backgroundColor: "#FFE8E8",        
        fontSize: 20,
        textAlign: "left",
        paddingLeft: 25,
        paddingTop: 12,
        paddingBottom: 11,
    },
    companyName_education: {
        width: "100%",
        height: 48,
        backgroundColor: "#D8D8EC",        
        fontSize: 20,
        textAlign: "left",
        paddingLeft: 25,
        paddingTop: 12,
        paddingBottom: 11,
    },
    companyName_delivery: {
        width: "100%",
        height: 48,
        backgroundColor: "#F0F7E3",        
        fontSize: 20,
        textAlign: "left",
        paddingLeft: 25,
        paddingTop: 12,
        paddingBottom: 11,
    },
    companyName_easy_work: {
        width: "100%",
        height: 48,
        backgroundColor: "#E7DDEC",        
        fontSize: 20,
        textAlign: "left",
        paddingLeft: 25,
        paddingTop: 12,
        paddingBottom: 11,
    },
    companyName_it: {
        width: "100%",
        height: 48,
        backgroundColor: "#CCCCCC",        
        fontSize: 20,
        textAlign: "left",
        paddingLeft: 25,
        paddingTop: 12,
        paddingBottom: 11,
    },
    companyName_medical: {
        width: "100%",
        height: 48,
        backgroundColor: "#F4F5F5",        
        fontSize: 20,
        textAlign: "left",
        paddingLeft: 25,
        paddingTop: 12,
        paddingBottom: 11,
    },
    companyName_specialty: {
        width: "100%",
        height: 48,
        backgroundColor: "#FFEEDD",        
        fontSize: 20,
        textAlign: "left",
        paddingLeft: 25,
        paddingTop: 12,
        paddingBottom: 11,
    },
    companyName_office: {
        width: "100%",
        height: 48,
        backgroundColor: "#CCECCC",        
        fontSize: 20,
        textAlign: "left",
        paddingLeft: 25,
        paddingTop: 12,
        paddingBottom: 11,
    },
    companyName_ahter: {
        width: "100%",
        height: 48,
        backgroundColor: "#E3E2E2",        
        fontSize: 20,
        textAlign: "left",
        paddingLeft: 25,
        paddingTop: 12,
        paddingBottom: 11,
    },
    borderBottom: {
        borderBottom: "solid 2px #707070"
    },
    introduce: {
        width: "100%",
        marginLeft: 20,
        fontSize: 18,        
        textAlign: "left",
        margin: "0 auto",
        marginTop: 15.5,
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
        },
    },
    sliderBox: {
        [theme.breakpoints.down('sm')]: {
            margin: '10px auto 10px auto',
            width: "95%",
            height: 200,
        },
        [theme.breakpoints.up('sm')]: {
            margin: '10px auto 0px auto',
            width: 490,
        },
    },
     column: {
        display: "flex",
        flexFlow: "column",
        alignItems: "center",        
    },
    flex: {
        marginTop: 0,
        width: 700,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "start",        
        [theme.breakpoints.down('sm')]: {
            width: 300,
            justifyContent: "start",                   
        },
    },
    filter: {
        marginTop: 10,
        maxWidth: "max-content",
        fontSize: 14,
        backgroundColor: "#FDC04F",        
        borderRadius: 15,
        padding: "3px 9px 3px 9px",
        color: "#fff",
        marginRight: 5,
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
        },
    },
    table: {
        display: "flex",
        width: "90%",        
        justifyContent: "center",
        marginTop: 30,
        margin: "0 auto",
        [theme.breakpoints.down('sm')]: {
            flexFlow: "column",
            justifyContent: "center", 
            marginLeft: 20                    
        },
    },
    detailLeft: {
        borderRight: "solid 1px #707070",
        paddingRight: 21,
        width: "50%",
        [theme.breakpoints.down('sm')]: {
            borderRight: "none"       
        },
    },
    detail: {        
        paddingLeft: 15.5,
        width: "50%",
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            marginTop: 20
        },
    },
    tr: {
        [theme.breakpoints.down('sm')]: {
            display: "flex",
            flexFlow: "column",            
        },
    },
    th: {
        textDecoration: "underline",
        width: 85,        
        textAlign: "left",
        [theme.breakpoints.down('sm')]: {
            fontSize: 14          
        },
    },
    td: {
        width: 350,
        textAlign: "left",
        [theme.breakpoints.down('sm')]: {
            fontSize: 14 
        },    
    },
    partTimefont: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 14 
        }, 
    },
    saraly: {
        maxWidth: 400,
        textAlign: "left"
    },
    message: {
        maxWidth: 540,
        marginTop: 17,
        marginLeft: 10,
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,               
        },
    },
    grid: {
        display: "flex"
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    point: {
        width: "90%",
        margin: "0 auto",
        border: "solid 3px #FDC04F",
        textAlign: "left",        
        borderRadius: 15,
        padding: "20px 30px 40px 25px",
        [theme.breakpoints.down('sm')]: {
            width: "90%",                  
        },
    },
    point_sub: {
        fontSize: 20,
        fontStyle: "italic",
        fontFamily: "Arial",
        textDecoration: "3px underline #FDC04F",
        fontWeight: "bold",
        display: "inline-block",
        [theme.breakpoints.down('sm')]: {
            fontSize: 16             
        },
    },
    button: {
        fontSize: 18,
        border: "solid 1px #707070",
        backgroundColor: "#FDC04F",  
        width: "max-content",      
        borderRadius: 5,
        color: "#fff",
        padding: "8px 39px",
        fontWeight: "bold",
        marginRight: 33,
        '&:hover': {
            opacity: 0.5
        },
        [theme.breakpoints.down('sm')]: {
            marginRight: 10,   
            fontSize: 12    
        },
    },
    buttonTop: {
        fontSize: 18,
        border: "solid 1px #707070",
        backgroundColor: "#fff",     
        width: "max-content",   
        borderRadius: 5,
        color: "#333",
        padding: "8px 30px",
        fontWeight: "600px",        
        marginLeft: 33,
        '&:hover': {
            opacity: 0.5
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 10,
            fontSize: 12         
        },
    },
    formbuttons: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 32,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 10,
            fontSize: 12         
        },
    },   
    pointDetail: {  
        width: "90%",
        margin: "0 auto"
    },
    pointDetailSubject: {
        textAlign: "left",
        fontWeight: "bold",
        fontSize: 20,
        borderBottom: "3px solid #FDC04F",    
        width: "100%",
    },
    pointDetailFlex: {
        display: "flex",
        justifyContent: "space-between",
        margin: "0 auto",
        width: "90%",
        [theme.breakpoints.down("sm")]: {
            flexFlow: "column"
        }
    },
    pointDetailTitle: {
        textAlign: "left",
        fontWeight: "bold",
        backgroundColor: "#ccc",
        padding: "3px 10px"
    },
    pointDetailSmall: {
        width: "48%",
        [theme.breakpoints.down("sm")]: {
            width: "100%"
        }
    },
    pointFilter: {
        display: "flex",
        flexWrap: "wrap",
    },
    flexDetails: {
        marginTop: 0,
        width: 700,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "start",        
        [theme.breakpoints.down('sm')]: {
            justifyContent: "start",    
            width: 150               
        },
    },
    saralyCalculation: {
        position: "fixed",
        zIndex: 20,
        bottom: 2,
        left: 2,        
        border: "3px solid #FDC04F",
        [theme.breakpoints.down('sm')]: {
            position: "relative",
            width: 250,
            margin: "20px auto"
        }
    },
    saralyTitle: {
        backgroundColor: "#FDC04F",
        color: "#fff",
        fontWeight: "bold",
        fontSize: 18
    },
    saralyField: {
        width: 80
    },
    saralyTh: {
        textAlign: "left"
    },
    saralyTd: {
        display: "flex",
        alignItems: "center"
    }
}));