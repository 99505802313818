// Storesの初期状態を管理、アプリに必要なStateを記述
export const initialState = {
    loading: {
        state: false,
        text: ""
    },
    companys: {
        companyinfo: [],
        sanpark: [],
        yumetown: [],
        mails: [],
    },
    
    users: {
        isSignedIn: false,
        applications: [],
        role: "",
        uid: "",
        companylist: [],   
        agree: []  
    },
    
    posts: {
        posts: [],
    }
}
