
export const SIGN_IN = "SIGN_IN";
export const signInAction = (userState) => {
    return{
        type: "SIGN_IN",
        payload: {
            isSignedIn: true,
            role: userState.role,
            uid: userState.uid,
            firstName: userState.firstName,
            lastName: userState.lastName,
        }
    }
};

export const SIGN_OUT = "SIGN_OUT";
export const signOutAction = () => {
    return{
        type: "SIGN_OUT",
        payload: {
            isSignedIn: false,
            role: "",
            uid: "",
            firstName: "",
            lastName: ""
        }
    }
};





